import styled from 'styled-components';

import * as color from '../../../constants/colors';

export const BigCalendarWrapper = styled.div`
  font-family: 'Roboto';
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  .rbc-time-view,
  .rbc-time-gutter > .rbc-timeslot-group,
  .rbc-time-content {
    border: 0;
  }

  .rbc-time-view {
    margin-top: 12px;
  }

  .rbc-timeslot-group {
    min-height: 60px;
  }

  .rbc-time-header {
    border-right: 0 !important;
    min-height: 60px;
    display: none;
  }

  .rbc-time-gutter,
  .rbc-time-header-gutter {
    box-shadow: 2.5px 0 5px 0 rgba(115, 118, 140, 0.16);
  }

  .rbc-time-content > * + * > * {
    border-left: 0;
  }

  .rbc-time-header-content {
    border-left: 1px solid ${color.hoverGray};
  }

  .rbc-now,
  .rbc-today {
    border-top: 1px solid #ddd;
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: 0;
  }

  .rbc-label {
    font-size: 12px;
    color: ${color.grayThin3};
  }

  .rbc-time-view-resources,
  .rbc-time-gutter,
  .rbc-time-view-resources,
  .rbc-time-header-gutter {
    background-color: ${color.hoverGray} !important;
  }

  .rbc-time-header-content {
    margin-right: 2px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px 10px 0 0;
  }

  .rbc-time-header-content > .rbc-row-resource {
    display: flex;
    align-items: center;
    min-height: 80px;
    color: ${color.grayThin3};
    font-size: 16px;
    width: 100%;
  }

  .rbc-allday-cell {
    display: none;
  }

  .rbc-header {
    color: ${color.darkBlue};
    padding: 0;
    height: 100%;
  }

  .rbc-time-header-content > .rbc-row.rbc-row-resource,
  .rbc-header {
    border-bottom: 0;
  }

  .rbc-event-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rbc-current-time-indicator {
    background-color: ${color.currentTimeIndicator};
    height: 2px;
    min-width: calc(100% + 2px);
  }

  .rbc-today {
    background-color: white;
  }

  .rbc-time-content > .rbc-day-slot {
    margin-right: 2px;
  }

  .rbc-time-gutter {
    min-width: 60px;
    max-width: 60px;
    width: 60px;
  }

  .rbc-label {
    min-width: 60px !important;
    max-width: 60px !important;
    width: 60px !important;
  }

  .rbc-event {
    left: 5px !important;
  }

  .rbc-slot-selection {
    width: 100%;
    z-index: 10;
    position: absolute;
    cursor: default;
    background-color: ${color.primaryGreen};
    opacity: 0.8;
    border-radius: 3px;
    color: ${color.white};
    padding: 3px;
    font-size: 13px;
  }
`;
