import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col } from '@d-lighted/design-system';
import { FormattedMessage } from 'react-intl';

import messages from '../messages';

export default function EventContent({ event: { start, end, resources } }) {
  const startFormatted = moment(start).format('HH:mm');
  const endFormatted = moment(end).format('HH:mm');
  const resourceCount = resources.length;
  return (
    <>
      <Row>
        <Col>{`${startFormatted} - ${endFormatted}`}</Col>
      </Row>
      <Row>
        <Col>
          {resourceCount}
          <FormattedMessage {...messages.rooms} />
        </Col>
      </Row>
    </>
  );
}

EventContent.propTypes = {
  event: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
