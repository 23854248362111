import { createReducer } from '@reduxjs/toolkit';
import { setPromotionLink, setPromotionTitle } from './actions';

const INITIAL_STATUS = {
  promotionLink: '',
  promotionTitle: '',
};

const signInReducer = createReducer(INITIAL_STATUS, builder => {
  builder.addCase(setPromotionLink, (state, action) => {
    state.promotionLink = action.payload;
  });
  builder.addCase(setPromotionTitle, (state, action) => {
    state.promotionTitle = action.payload;
  });
});

export default signInReducer;
