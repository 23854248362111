import styled from 'styled-components';
import { color, border } from 'styled-system';

import * as colors from 'constants/colors';

export const Card = styled.div`
  margin-top: 16px;
  background-color: ${colors.white};
  border-radius: 16px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TagText = styled.p`
  font-family: Noto Sans JP;
  font-size: 11px;
  color: ${colors.grayThin3};
`;

export const TagIndicator = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 3px;
  margin-right: 7px;
  box-sizing: border-box;
  ${color}
  ${border}
`;

export const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: calc(10px + 2vmin);
  color: ${color.deepGreen};
  padding: 0 30px;
  padding-top: 60px;
`;
