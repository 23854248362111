import { defineMessages } from 'react-intl';

export default defineMessages({
  suggestedProductSurtitle: {
    id: 'containers.suggestedProduct.surtitle',
    defaultMessage: 'あなたへのおすすめ',
  },
  suggestedProductTitle: {
    id: 'containers.suggestedProduct.title',
    defaultMessage: '会議室の利用マナーを向上',
  },
  activeButton: {
    id: 'containers.productinfo.activebutton',
    defaultMessage: '無料で試す',
  },
  inactiveButton: {
    id: 'containers.productinfo.inactivebutton',
    defaultMessage: 'ご利用中',
  },
  receptionistTopproductSurtitle: {
    id: 'containers.productinfo.topproduct.receptionist.surtitle',
    defaultMessage: '日程調整ツール「調整アポ」をご利用中のあなたへのおすすめ',
  },
  receptionistTopproductTitle: {
    id: 'containers.productinfo.topproduct.receptionist.title',
    defaultMessage: '来訪者の受付・ご案内までワンストップ',
  },
  receptionistTopproductSubtitle: {
    id: 'containers.productinfo.topproduct.receptionist.subtitle',
    defaultMessage:
      '受付システム「RECEPTIONIST」はiPad1台で、受付を無人化できるクラウドサービスです。',
  },
  receptionistSurtitle: {
    id: 'containers.productinfo.receptionist.surtitle',
    defaultMessage: 'クラウド受付システム',
  },
  receptionistTitle: {
    id: 'containers.productinfo.receptionist.title',
    defaultMessage: 'RECEPTIONIST',
  },
  roomsTopproductSurtitle: {
    id: 'containers.productinfo.topproduct.rooms.surtitle',
    defaultMessage:
      '受付システム「RECEPTIONIST」をご利用中のあなたへのおすすめ',
  },
  roomsTopproductTitle: {
    id: 'containers.productinfo.topproduct.rooms.title',
    defaultMessage: 'お客様をお待たせせず会議室へご案内',
  },
  roomsTopproductSubtitle: {
    id: 'containers.productinfo.topproduct.rooms.subtitle',
    defaultMessage:
      '来客や社内会議で使う会議室の予約・管理をデジタル化。「空予約」「無断延長」を防止します。',
  },
  roomsSurtitle: {
    id: 'containers.productinfo.rooms.surtitle',
    defaultMessage: 'スペース予約管理システム',
  },
  roomsTitle: {
    id: 'containers.productinfo.rooms.title',
    defaultMessage: '予約ルームズ',
  },
  schedulingTopproductSurtitle: {
    id: 'containers.productinfo.topproduct.scheduling.surtitle',
    defaultMessage: 'あなたへのおすすめ',
  },
  schedulingTopproductTitle: {
    id: 'containers.productinfo.topproduct.scheduling.title',
    defaultMessage: '日程調整から当日のご案内まで一気通貫',
  },
  schedulingTopproductSubtitle: {
    id: 'containers.productinfo.topproduct.scheduling.subtitle',
    defaultMessage:
      'ゲストと日程調整を行う際に発生する作業を、コピペで終わらすことができます。同時に受付コード発行・会議室予約もすべて自動化します。',
  },
  schedulingSurtitle: {
    id: 'containers.productinfo.scheduling.surtitle',
    defaultMessage: '日程調整ツール',
  },
  schedulingTitle: {
    id: 'containers.productinfo.scheduling.title',
    defaultMessage: '調整アポ',
  },
});
