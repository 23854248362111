import styled from 'styled-components';
import { slideFade } from 'components/Animation';

export const Wrapper = styled.div`
  position: fixed;
  width: 450px;
  background-color: #ffffff;
  z-index: 1002;
  animation: ${slideFade} 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.4s linear;
  transition-property: height, max-height;
  box-shadow: 0 5px 15px 0 rgba(63, 78, 90, 0.3);
  border-radius: 6px;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  max-height: ${props => props.maxHeight}px;
  height: ${props => (props.height === 'auto' ? 'auto' : `${props.height}px`)};
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: 0.5;
`;
