import styled, { css, keyframes } from 'styled-components';
import QrReader from 'react-qr-reader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Row,
  Col,
  Modal,
  Label,
  Input,
  DefaultSelect,
} from '@d-lighted/design-system';
import * as colors from 'constants/colors';
import check from 'images/check.svg';

export const DragBar = styled.div`
  background-color: ${colors.grayShade3};
  width: 48px;
  height: 6px;
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const GrayIcon = styled(FontAwesomeIcon)`
  color: ${colors.grayThin3};
`;

export const SettingsIcon = styled(FontAwesomeIcon)`
  color: ${colors.darkGrayText};
  width: 14px;
  height: 14px;
`;

export const TitleRow = styled(Row)`
  width: 100vw;
  padding: 0 15px 5px 15px;
  position: sticky;
  top: 62px;
  z-index: 10;
  background-color: ${colors.hoverGray};
  margin: -16px !important;
  border-bottom: 1px solid ${colors.grayShade};
`;

export const SortingTitle = styled.p`
  color: ${colors.darkGrayText};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding-left: 10px;
`;

export const CenteredCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexEndCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const MainContentsWrapper = styled.div`
  width: 100%;
  margin-bottom: 88px;
  padding-top: 35px;
`;

export const ResourceRow = styled(Row)`
  box-sizing: border-box;
  background-color: white;
  margin: 5px 0 0 !important;
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  height: 56px;
  overflow: hidden;
`;

export const Pill = styled.div`
  padding: 5px 10px;
  width: fit-content;
  border-radius: 5px;
  font-size: 10px;
  color: ${colors.white};
`;

export const InUsePill = styled(Pill)`
  background-color: ${colors.inUse};
`;

export const AvailablePill = styled(Pill)`
  background-color: ${colors.available};
`;

export const ResourceTitle = styled.p`
  color: ${colors.darkBlue};
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const EntryButtonCol = styled(Col)`
  text-align: right;
`;

export const EntryButton = styled(Button)`
  border-radius: 100px !important;
  border: 2px solid ${colors.brandBlue} !important;
  color: ${colors.brandBlue} !important;
  font-weight: 600;
  width: 88px;
  height: 40px;
`;

export const RoomCol = styled(Col)`
  background-color: transparent !important;
  border-radius: 10px !important;
  padding: 13px 20px !important;
  min-height: 55px;
  border: 0px;
  display: flex;
  justify-content: center;
`;

export const RoomTitleLabel = styled(Label)`
  font-size: 18px;
  color: ${colors.deepBlue} !important;
  font-weight: 500;
  line-height: 29px;
`;

export const CustomForm = styled.form`
  box-sizing: border-box;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
`;

export const InputField = styled(Input)`
  height: 55px;
  width: 77vw;
  background: ${colors.lightGray} !important;
  border-radius: 10px !important;
  border: 0px solid ${colors.lightGray} !important;
  padding: 12px 20px;
  font-size: 18px;
  color: ${colors.grayThin3} !important;
  font-weight: 500;
  line-height: 29px;
`;

export const StyledTimeSelect = styled.select`
  height: 55px;
  width: 91px;
  background: ${colors.lightGray} !important;
  border-radius: 10px !important;
  border: 0 solid ${colors.lightGray} !important;
  padding: 12px 20px;
  font-size: 18px;
  color: ${colors.deepBlue} !important;
  font-weight: 500;
  line-height: 29px;
  margin-right: 19px;
`;

export const CardFirstRow = styled(Row)`
  padding-bottom: 7px;
`;

export const CardSecondaryText = styled.p`
  color: ${colors.grayThin3};
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  min-height: 14px;
`;

export const DayCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  margin-bottom: 10px;
  background: ${colors.white};
  border: 1px solid ${colors.lightGray};
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(20, 44, 43, 0.06);
  border-radius: 10px;
`;

export const DayCircle = styled.div`
  width: 50px;
  height: 50px;
  background: ${colors.grayInput};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 590;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.darkBlue};
`;

export const DayText = styled.p`
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: ${colors.darkBlue};
`;

export const TimeListWrapper = styled.div`
  margin: 4px 0 6px;
  width: 100%;
`;

export const TimeRangeText = styled.p`
  font-weight: 590;
  font-size: 26px;
  line-height: 31px;
  color: ${colors.darkBlue};
`;

export const RoomDetailLabel = styled(Label)`
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: ${colors.grayThin3};
`;

export const ReserveButton = styled(Button)`
  height: 42px;
  min-width: 100px !important;
  width: fit-content !important;
  background-color: ${colors.brandBlue} !important;
  border: 2px solid ${colors.brandBlue} !important;
  border-radius: 100px !important;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
`;

export const EmptySearchResultWrapper = styled.div`
  text-align: center;
  color: ${colors.grayThin3};
  height: calc(100vh - (65px + 35px + 90px));
  display: flex;
  align-items: center;
`;

export const CustomSearchModal = styled(Modal)`
  z-index: 43 !important;
  border-radius: 0 !important;
  .pad_bot {
    padding-bottom: calc(77px + 88px);
    height: auto;
  }
`;

export const CustomConfirmationModal = styled(Modal)`
  border-radius: 20px !important;
  overflow-y: ${props => (props.overFlow ? 'auto' : null)};
  .space_evenly {
    justify-content: space-evenly;
  }
`;

export const ConfirmationContainer = styled.div`
  height: 100%;
  width: 100% !important;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  overflow: auto;
  position: relative;
  .confirm_button {
    background-color: ${colors.brandBlue} !important;
  }
  .cancel_button {
    color: ${colors.brandBlue} !important;
    border: 2px solid ${colors.brandBlue} !important;
  }
  .space_top {
    padding: 24px 0 !important;
  }
`;

export const ConfirmationTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: ${props => props.fontSize || '22px'} !important;
  line-height: ${props => props.lineHeight || '36px'} !important;
  color: ${props => props.color || colors.deepBlue} !important;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 12px !important;
  padding: ${props => props.padding} !important;
  text-align: center;
`;

export const ConfirmationDialogButton = styled(Button)`
  width: 72vw !important;
  height: 47px !important;
  border-radius: 24px !important;
  font-weight: 600;
  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const CancelDialogButton = styled(Button)`
  width: 72vw !important;
  height: 47px !important;
  border-radius: 24px !important;
  font-weight: 600 !important;
  color: ${colors.brandBlue} !important;
  border: 2px solid ${colors.brandBlue} !important;
`;

export const ChoiceRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  width: 90% !important;
  height: 20px !important;
  cursor: pointer;
  padding-top: ${props => props.pt};

  // Filter calculated with https://codepen.io/sosuke/pen/Pjoqqp from color #15B9CB
  ${props =>
    props.checked &&
    `
  &:after {
     content: url(${check});
     transform: scale(0.7);
     margin-top: -6px;
     filter: invert(85%) sepia(47%) saturate(4665%) hue-rotate(135deg)
       brightness(85%) contrast(85%);
  }
`}
`;

export const SearchLabel = styled(Label)`
  color: ${colors.grayShade3} !important;
  display: block;
  padding: 4px 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.01em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: ${colors.white};
  border: 1px solid ${colors.grayShade3};
  border-radius: 4px;
`;

export const SortingForm = styled.form`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 26px 24px;
  width: 100%;
`;

export const SortingFooterContainer = styled.div`
  background: ${colors.white};
  box-shadow: 0px -5px 14px rgba(0, 0, 0, 0.09);
  border-radius: 20px 20px 0px 0px;
  position: fixed;
  width: 100vw;
  height: 77px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 40;
`;

export const TimeSelector = styled.div`
  ${'' /* width: 48px; */}
  height: 36px;
  background: ${props => (props.isSelected ? colors.brandBlue : colors.white)};
  border-radius: 36px;
  border: 1px solid
    ${props =>
      props.isSelected ? colors.todayIndicator : colors.tagInactiveBorder};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MeetingTime = styled.p`
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: ${props => (props.isSelected ? colors.white : colors.doneEventGray)};
`;

export const MinuteSelect = styled(DefaultSelect)`
  height: 44px;
  border-color: ${colors.tagActiveBorder};
  margin: 0;
  font-weight: normal;
`;

export const DatePickerContainer = styled.div`
  padding-top: 20px;
`;

export const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 96%;
  width: 100%;
`;

export const QRClose = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 30px;
  z-index: 42;
`;

export const QRCameraSwitch = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 9;
`;

export const QRHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 3%;
  z-index: 1;
`;

export const QRHeader = styled(Label)`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  padding-top: 20px;
`;

export const QRScanLabel = styled(Label)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: ${colors.lightGray};
  width: calc(100% - 92px);
  padding-top: 8px;
`;

export const StyledQrReader = styled(QrReader)`
  width: 100%;
  height: 96%;
  display: flex;
  section {
    border-radius: 0px;
  }
  section > div {
    border: none !important;
    box-shadow: none !important;
  }
`;

export const QRDialogContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CircleIcon = styled.div`
  width: 82px;
  height: 82px;
  border-radius: 56px;
  background: ${props => (props.success ? colors.brandBlue : colors.darkRed)};
  box-shadow: 0px 10px 30px
    ${props => (props.success ? colors.transparentBlue : colors.transparentRed)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainStatusText = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: ${colors.darkText};
  text-align: center;
`;

export const SubText = styled(Label)`
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: ${colors.grayThin3};
  text-align: center;
  display: inline-flex;
`;

export const FetchIndicatorWrapper = styled.div`
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);
  position: absolute;
  margin-bottom: 88px;
  &:before {
    background: #000;
  }
`;

const sharedStyle = css`
  margin: 3px;
  width: 17px;
  height: 17px;
  background-color: ${colors.brandBlue};
  border-radius: 50%;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
`;

export const DivOne = styled.div`
  ${sharedStyle}
  animation-name: load-one;

  @keyframes load-one {
    30% {
      transform: translateY(-50%);
    }
  }
`;

export const DivTwo = styled.div`
  ${sharedStyle}
  animation-name: load-two;

  @keyframes load-two {
    50% {
      transform: translateY(-50%);
    }
  }
`;

export const DivThree = styled.div`
  ${sharedStyle}
  animation-name: load-three;

  @keyframes load-three {
    70% {
      transform: translateY(-50%);
    }
  }
`;

export const FetchingIcon = styled.img`
  width: 111px;
  height: 111px;
  margin-top: 39px;
`;
export const LogoUploadModalContainer = styled.div`
  background-color: white;
  position: fixed;
  box-shadow: 0 10px 26px 0 rgb(63 78 90 / 14%);
  z-index: 4;
  height: 500px;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 32px;
`;
export const LogoUploadTopTitle = styled.p`
  font-size: 16px;
  line-height: 0.82;
  letter-spacing: 0.22px;
  text-align: center;
  color: #484e62;
  margin-bottom: 4px;
`;
export const LogoUploadTopSubtitle = styled.p`
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
  color: #484f63;
  margin-bottom: 12px;
`;
export const UploadButton = styled.button`
  cursor: pointer;
  width: 100%;
  height: 400px;
  padding: 100px 50px;
  border-radius: 2.5px;
  border: dashed 2px #d3d9e5;
  background-color: #f6f8fa;
`;
export const AspectRatioImage = styled.img``;
export const LogoUploadButtonSubtitle = styled.p`
  font-size: 13px;
  text-align: center;
  margin-bottom: 20px;
  color: #73768c;
`;
export const LogoUploadButtonBottomText = styled.p`
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  color: #73768c;
`;
export const BlueLogoUpload = styled.button`
  cursor: pointer;
  width: 100%;
  height: 400px;
  max-height: 500px;
  padding: 100px 50px;
  border-radius: 2.5px;
  border: dashed 2px #57c2e9;
  background-color: #cceff5;
`;
export const InvisibleFileUpload = styled.input`
  display: none;
`;
export const LabelFileUpload = styled.label`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
`;
export const LogoUploadErrorMessage = styled.p`
  font-size: 13px;
  color: #f23f52;
  background-color: #ffeaef;
  width: 100%;
  text-align: center;
  margin: 24px 0px 24px 0;
  padding: 13px 14px 12px 16px;
  border-radius: 2.5px;
  border: solid 0.5px #f23f52;
`;
export const CloseSuccessButton = styled.button`
  width: auto;
  cursor: pointer;
  align-items: center;
  padding: 8.5px 16px;
  width: 100%;
  border-radius: 3px;
  background-color: ${colors.primaryLightGreen};
  color: white;
  border: none;
  font-size: 16px;
`;
export const LogoUploadSuccessModalContainer = styled.div`
  background-color: white;
  position: fixed;
  z-index: 4;
  height: 280px;
  width: 500px;
  top: 50%;
  left: 50%;
  border: 1px solid black;
  transform: translate(-50%, -50%);
  padding: 20px 32px;
`;

export const SuccessCircle = styled.div`
  margin: auto;
  margin-bottom: 24px;
  margin-top: 24px;
  position: relative;
  background: white;
  border: 2px solid #00b1ce;
  border-radius: 50%;
  width: 48px;
  height: 48px;
`;
export const SuccessCheckmark = styled.div`
  position: absolute;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 35%;
  top: 50%;
  height: 15px;
  width: 12px;
  border-bottom: 3px solid #00b1ce;
  border-right: 3px solid #00b1ce;
`;

export const loadingCircleAnimation = keyframes`
  0% { stroke-dashoffset: 0 }
  100% { stroke-dashoffset: -600; }
`;

export const SignageModeLogoLoaderWrapper = styled.svg`
    display: block;
    margin: auto;
    width: 90px;
    height:100px;
    fill: green;
    & .loading-inner {
      animation: ${loadingCircleAnimation} 2s linear infinite;
      stroke: #73768c;
      fill: transparent;
      stroke-dashoffset: 0;
      stroke-dasharray: 300;
      stroke-width: 5;
      stroke-miterlimit: 10;
      stroke-linecap: round;
    }
    & .not-loaded-inner {
      stroke: #d3d9e5;
      fill: transparent;
      stroke-dashoffset: 0;
      stroke-dasharray: 300;
      stroke-width: 5;
      stroke-miterlimit: 10;
      stroke-linecap: round;
    }
  }
`;

export const MoreLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

export const ListMeetingRoomThumbnail = styled.img`
  height: 56px;
  width: 76px;
  margin-top: -16px;
  margin-bottom: -18px;
  margin-right: -18px;
  object-fit: cover;
`;
