import { getCurrentUser } from 'utils/userUtils';

export function pushEvent(event) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...event,
  });
}

export function pushUserIdToPageViewGA() {
  const currentUser = getCurrentUser();
  window.dataLayer.push('set', process.env.REACT_APP_GA_PROPERTY_ID, {
    user_id: currentUser.id,
    send_page_view: true,
  });
}

export const events = {
  onClickSchedulingAppLink: href => ({
    event: 'click_link_lp',
    href,
  }),
  onClickLinkHelp: href => ({
    event: 'click_link_help',
    href,
  }),
  onClickLinkApp: href => ({
    event: 'click_link_app',
    href,
  }),
};
