import React from 'react';
import { PromotionImage } from 'containers/SignIn/components/styled';

function getProductPromotionImage(productType, link) {
  if (!productType || !link) return null;

  const image = `${window.location.origin}/promotion/image.jpg`;

  return (
    <a href={link} target="_blank" rel="noreferrer">
      <PromotionImage src={image} alt="promotion_image" />
    </a>
  );
}

export { getProductPromotionImage };
