import { defineMessages } from 'react-intl';

export default defineMessages({
  activeButton: {
    id: 'containers.productinfo.activebutton',
    defaultMessage: '無料で試す',
  },
  inactiveButton: {
    id: 'containers.productinfo.inactivebutton',
    defaultMessage: 'ご利用中',
  },
  inquiryButton: {
    id: 'containers.productinfo.inquiry',
    defaultMessage: 'お問い合わせ',
  },
  option: {
    id: 'containers.productinfo.option',
    defaultMessage: 'オプション',
  },
});
