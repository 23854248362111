import React from 'react';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { useLocale, LOCALES } from 'hooks/useLocale';
import localeJA from 'locales/ja.json';
import localeEN from 'locales/en.json';
import { getCurrentUser } from 'utils/userUtils';

const messages = {};
messages[LOCALES.JA] = localeJA;
messages[LOCALES.EN] = localeEN;

function LocaleProvider({ children }) {
  const [locale, setLocale] = useLocale();

  const currentUser = getCurrentUser();

  if (locale == null && currentUser != null && !isEmpty(currentUser)) {
    setLocale(currentUser.company.locale_code || 'ja');
  }

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
}

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocaleProvider;
