import React from 'react';
import { PropTypes } from 'prop-types';

import * as colors from 'constants/colors';

function IconDoorOpen({ width, height, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 640 512"
    >
      <title id="title">Icon Door Open</title>
      <path
        // eslint-disable-next-line max-len
        d="M624 448h-80V113.45C544 86.19 522.47 64 496 64H384v64h96v384h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM312.24 1.01l-192 49.74C105.99 54.44 96 67.7 96 82.92V448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h336V33.18c0-21.58-19.56-37.41-39.76-32.17zM264 288c-13.25 0-24-14.33-24-32s10.75-32 24-32 24 14.33 24 32-10.75 32-24 32z"
        fill={fill}
      />
    </svg>
  );
}

IconDoorOpen.defaultProps = {
  width: 20,
  height: null,
  fill: colors.primaryGreen,
};

IconDoorOpen.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default IconDoorOpen;
