import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const MiddleContentText = styled.p`
  color: #484f63;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 16px;
  text-align: left;
`;

const FeatureListItem = styled.li`
  color: #484e62;
  font-size: 16px;
  font-weight: 500;
  height: 25.6px;
  margin-bottom: 9px;
  margin-left: 16px;
`;

const FeatureListContainer = styled.ul`
  list-style-type: disc;
`;

const ActiveButton = styled.button`
  appearance: button;
  background-color: #00bbb5;
  border-radius: 3px;
  border: solid 1px #00bbb5;
  color: #fff;
  font-size: 16px;
  height: 40px;
  line-height: 1;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 8px 20px 10px;
  text-decoration: none;
  -moz-appearance: button;
  -webkit-appearance: button;

  ${props =>
    !props.isActive &&
    `
    &:hover {
      background-color: #009D98;
      border: solid 1px #009D98;
      cursor: pointer;
    }
  `}
`;

const InactiveButton = styled.button`
  background-color: #e7e9ea;
  border-radius: 3px;
  border: solid 1px #e7e9ea;
  color: #9bb0b5;
  font-size: 16px;
  height: 40px;
  letter-spacing: 1px;
  line-height: 1;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 8px 20px 10px;
  text-align: center;
`;

const ServiceDetailsContainer = styled.div`
  display: flex;
`;

function FeatureContent({
  middleContentText,
  featureItem1,
  featureItem2,
  featureItem3,
  isActive,
  linkTarget,
}) {
  return (
    <div>
      <MiddleContentText>
        <FormattedMessage {...middleContentText} />
      </MiddleContentText>
      <FeatureListContainer>
        <FeatureListItem>
          <FormattedMessage {...featureItem1} />
        </FeatureListItem>
        <FeatureListItem>
          <FormattedMessage {...featureItem2} />
        </FeatureListItem>
        <FeatureListItem>
          <FormattedMessage {...featureItem3} />
        </FeatureListItem>
      </FeatureListContainer>
      <ServiceDetailsContainer>
        {isActive ? (
          <InactiveButton>
            <FormattedMessage {...messages.inactiveButton} />
          </InactiveButton>
        ) : (
          <ActiveButton href={linkTarget} target="_blank">
            <FormattedMessage {...messages.activeButton} />
          </ActiveButton>
        )}
      </ServiceDetailsContainer>
    </div>
  );
}

FeatureContent.defaultProps = {
  middleContentText: 'text',
  featureItem1: 'text',
  featureItem2: 'text',
  featureItem3: 'text',
  linkTarget: 'text',
};

FeatureContent.propTypes = {
  middleContentText: PropTypes.oneOfType([PropTypes.object]),
  featureItem1: PropTypes.oneOfType([PropTypes.object]),
  featureItem2: PropTypes.oneOfType([PropTypes.object]),
  featureItem3: PropTypes.oneOfType([PropTypes.object]),
  isActive: PropTypes.bool.isRequired,
  linkTarget: PropTypes.string,
};

export default FeatureContent;
