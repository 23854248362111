import { defineMessages } from 'react-intl';

export default defineMessages({
  login: {
    id: 'views.signIn.pages.signIn.login',
    defaultMessage: 'Login',
  },
  welcome: {
    id: 'views.signIn.pages.signIn.welcome',
    defaultMessage:
      'Email authentication is completed. Sign in to Receptionist DashBoard.',
  },
  forgotPassword: {
    id: 'views.signIn.pages.signIn.forgotPassword',
    defaultMessage: 'Forgot your password?',
  },
  SAML: {
    id: 'views.signIn.pages.signIn.SAML',
    defaultMessage: 'SAML authentication',
  },
  email: {
    id: 'views.signIn.pages.signIn.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'views.signIn.pages.signIn.password',
    defaultMessage: 'Password',
  },
  notifyError: {
    id: 'notify.signIn.error',
    defaultMessage: 'Login error',
  },
});
