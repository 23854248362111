import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DatePicker } from '@d-lighted/design-system';

import { DatePickerContainer, DatePickerErrorMessage } from './styled';

const SmallCalendar = ({ input, minDate, maxDate, meta: { error } }) => {
  const [date, setDate] = useState(input.value);

  const onDateChange = value => {
    let newValue = [];
    if (value.length === 2) {
      newValue = [
        moment(value[0]).format('YYYY-MM-DD'),
        moment(value[1]).format('YYYY-MM-DD'),
      ];
    } else if (value.length === 1) {
      newValue = [
        moment(value[0]).format('YYYY-MM-DD'),
        moment(value[0]).format('YYYY-MM-DD'),
      ];
    }
    input.onChange(newValue);
    setDate(value);
  };

  return (
    <DatePickerContainer>
      <DatePicker
        // defaultValue={date}
        value={date}
        onChange={onDateChange}
        highlightWeek={false}
        selectRange
        showActionButton={false}
        minDate={
          minDate != null ? moment(minDate).add(1, 'day').toDate() : null
        }
        maxDate={
          maxDate != null ? moment(maxDate).subtract(1, 'day').toDate() : null
        }
        allowPartialRange
        boxShadow="none"
        width="100%"
        padding="0"
        id={input.name}
      />

      {error && <DatePickerErrorMessage>{error}</DatePickerErrorMessage>}
    </DatePickerContainer>
  );
};

export default SmallCalendar;

SmallCalendar.propTypes = {
  input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

SmallCalendar.defaultProps = {
  input: { value: new Date(), onChange: () => {}, name: undefined },
  minDate: undefined,
  maxDate: undefined,
  meta: { error: undefined, touched: undefined },
};
