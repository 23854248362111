/* eslint-disable */
// SVGiconのためdisable

import React from 'react';

function IllustrationSetting() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="160"
      height="160"
      viewBox="0 0 160 160"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_28"
            width="160"
            height="160"
            transform="translate(631 241)"
            fill="#fff"
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient"
          x1="0.496"
          y1="-8.657"
          x2="0.507"
          y2="-14.451"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0.01" />
          <stop offset="0.83" stopOpacity="0.071" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="0.083"
          y1="-1.134"
          x2="0.733"
          y2="0.42"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0.01" />
          <stop offset="0.12" stopOpacity="0.749" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="1.722"
          y1="-0.295"
          x2="0.129"
          y2="0.008"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="0.183"
          y1="2.853"
          x2="0.942"
          y2="0.115"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="0.528"
          y1="-3.161"
          x2="0.5"
          y2="-0.659"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="-0.164"
          y1="-0.758"
          x2="1.939"
          y2="-0.713"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="0.389"
          y1="-0.681"
          x2="0.637"
          y2="-2.036"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="0.49"
          y1="-2.359"
          x2="0.524"
          y2="-0.901"
          xlinkHref="#linear-gradient-2"
        />
      </defs>
      <g
        id="pic_setting"
        transform="translate(-631 -241)"
        clipPath="url(#clip-path)"
      >
        <g id="cto" transform="translate(629.27 253.558)">
          <path
            id="Path_199"
            d="M14.68,104.784S-2.46,87.247,2.7,68.639s19-18.608,41.541-35.986S72.686-2.142,107.878.2s58.561,62.132,53.047,78.36-10.276,28.487-10.276,28.487Z"
            fill="#08ccc6"
            opacity="0.1"
          />
          <rect
            id="Rectangle_69"
            width="151.879"
            height="3.531"
            transform="translate(7.816 101.054)"
            fill="#08ccc6"
          />
          <rect
            id="Rectangle_70"
            width="144.102"
            height="30.352"
            transform="translate(11.149 104.546)"
            fill="#08ccc6"
          />
          <rect
            id="Rectangle_71"
            width="144.102"
            height="7.261"
            transform="translate(11.149 104.546)"
            opacity="0.23"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_200"
            d="M48.523,89.985s.754-21.98,5.2-24.2c4.4-2.182,22.536-1.309,26.5-.238,3.968,1.111,7.142,16.783,8.134,19.243,1.865,4.523,8.927,7.023,8.927,7.023l-.674,9.244-41.779-.2C54.792,100.856,49.833,99.864,48.523,89.985Z"
            fill="#85f2ef"
          />
          <path
            id="Path_201"
            d="M61.259,64.949a1.976,1.976,0,0,0-.317,1.428,6.775,6.775,0,0,0,1.627,3.73,11.27,11.27,0,0,0,3.253,2.539c.4-.952.714-2.619,1.508-2.936q1.309-.3,2.619-.476c.04-.079-2.381-.436-2.46-.476a6.848,6.848,0,0,1-3.65-2.5,4.927,4.927,0,0,1-.674-.913c-.2-.436-.04-1.032-.635-.952A1.712,1.712,0,0,0,61.259,64.949Z"
            fill="url(#linear-gradient-2)"
          />
          <path
            id="Path_202"
            d="M73.6,70.7c.119.159.317.317.476.238a.31.31,0,0,0,.159-.159A3.01,3.01,0,0,0,74.59,68.6a6.481,6.481,0,0,0-.833-2.1,7.546,7.546,0,0,0-1.19-1.746c-.238-.238-.6-.476-.873-.238a.811.811,0,0,0-.2.357c-.2.754-2.023,3.69-1.547,4.364.159.278,2.063.119,2.42.317A6.726,6.726,0,0,1,73.6,70.7Z"
            fill="url(#linear-gradient-3)"
          />
          <path
            id="Path_203"
            d="M76.019,49.119c-.079-1.19-2.5-1.706-2.9-2.579l3.65-.159s.436,4.84-1.309,7.181l-.357-.913C75.582,50.388,76.177,51.856,76.019,49.119Z"
            fill="#3e3e54"
          />
          <path
            id="Path_204"
            d="M64.314,57.133l-1.349,8.253s3.015,4.444,6.864,3.769,2.42-2.539,2.42-2.539l-.476-6.983S68.758,60.347,64.314,57.133Z"
            fill="#f7ad7e"
          />
          <path
            id="Path_205"
            d="M63.838,60.029l.476-2.9c4.444,3.214,6.705,1.825,6.705,1.825l1.071,3.73c-.635.635.159,2.936-2.42,2.658C67.568,65.187,65.584,62.291,63.838,60.029Z"
            opacity="0.52"
            fill="url(#linear-gradient-4)"
          />
          <path
            id="Path_206"
            d="M60.863,51.142c.317,4.563,5,12.657,9.562,12.3,4.563-.317,5.872-9.76,5.555-14.363-.317-4.563-2.9-7.181-7.459-6.864a8.361,8.361,0,0,0-7.657,8.927Z"
            fill="#f7ad7e"
          />
          <path
            id="Path_207"
            d="M64.632,42.413c.278-.079.357-.278.714-.6a6.572,6.572,0,0,1,5-1.19c4.285.476,5.277,3.491,6.427,3.015s3.174,1.309,1.785,3.372c-1.389,2.023-3.69,2.579-6.467,1.23a9.814,9.814,0,0,0-6.269-1.032,1.125,1.125,0,0,0-.794.476,5.232,5.232,0,0,0-.119,2.222,31.387,31.387,0,0,0,.516,4.325c-.238.04-.4-.2-.516-.4-.238-.476-.436-1.032-.674-1.508-.278-.555-.794-1.468-1.508-.992a1.61,1.61,0,0,0-.635,1.151,3.37,3.37,0,0,0,.754,3.055,4.274,4.274,0,0,1,.992,2.381c.04.238.317.913.2,1.151-.159.4-.754.357-1.071.119a2.271,2.271,0,0,1-.635-1.309,30.792,30.792,0,0,0-2.539-6.666,7.306,7.306,0,0,1-.674-1.508,5.95,5.95,0,0,1,.238-3.531,6.47,6.47,0,0,1,1.865-3.015c.992-.833,1.865-.714,3.015-.635A.682.682,0,0,1,64.632,42.413Z"
            fill="#3e3e54"
          />
          <path
            id="Path_208"
            d="M66.457,100.419l.674-8.57-7.023-.992L57.887,78.875s8.134,7.459,15,7.261,7.7-2.857,7.7-2.857l1.309,16.942Z"
            opacity="0.23"
            fill="url(#linear-gradient-5)"
          />
          <path
            id="Path_209"
            d="M67.766,69.512l.913,1.547,1.349,1.19,2.42,26.384,4.682-.159L71.3,71.932l.714-2.579C72.012,69.393,69.076,69.036,67.766,69.512Z"
            fill="url(#linear-gradient-6)"
          />
          <path
            id="Path_210"
            d="M65.624,95.023S71.5,90.342,73.559,90.9c2.063.6,4.245,2.777,4.4,3.531s-.873,1.9-2.063,2.063-2.5,1.19-3.214.873a3.42,3.42,0,0,0-3.214.873c-.714.873-4.126,2.063-4.126,2.063S63.124,97.206,65.624,95.023Z"
            fill="#f7ad7e"
          />
          <path
            id="Path_211"
            d="M111.608,101.134H68.8a2.2,2.2,0,0,1-2.182-2.182h0A2.2,2.2,0,0,1,68.8,96.769h42.81a2.2,2.2,0,0,1,2.182,2.182h0A2.2,2.2,0,0,1,111.608,101.134Z"
            fill="#85f2ef"
          />
          <path
            id="Path_213"
            d="M85.382,96.968l.714,2.3,30.392,1.865,8.649-21.98a1.915,1.915,0,0,0-1.071-2.5,2.247,2.247,0,0,0-.714-.119H97.563a6.243,6.243,0,0,0-5.793,3.928Z"
            fill="#68e1fd"
          />
          <path
            id="Path_214"
            d="M85.382,96.968l.714,2.3,30.392,1.865,8.649-21.98a1.915,1.915,0,0,0-1.071-2.5,2.247,2.247,0,0,0-.714-.119H97.563a6.243,6.243,0,0,0-5.793,3.928Z"
            fill="url(#linear-gradient-7)"
          />
          <path
            id="Path_215"
            d="M85.3,101.134h31.185L124.7,80.264a1.83,1.83,0,0,0-1.032-2.381,1.853,1.853,0,0,0-.674-.119H98.515A5.946,5.946,0,0,0,93,81.494Z"
            fill="#85f2ef"
          />
          <path
            id="Path_216"
            d="M85.3,101.134h31.185L124.7,80.264a1.83,1.83,0,0,0-1.032-2.381,1.853,1.853,0,0,0-.674-.119H98.515A5.946,5.946,0,0,0,93,81.494Z"
            fill="url(#linear-gradient-8)"
          />
          <path
            id="Path_217"
            d="M147,60.307a12.69,12.69,0,0,0,1.428-3.372l2.5-.476.04-2.777.04-2.777-2.5-.516a12.5,12.5,0,0,0-1.349-3.372l1.428-2.1-1.944-1.984L144.7,40.945l-2.142,1.389a13,13,0,0,0-3.333-1.428l-.476-2.5-2.777-.04-2.777-.04-.516,2.5a14.616,14.616,0,0,0-3.372,1.349l-2.1-1.428-1.984,1.944-1.984,1.944,1.389,2.142a12.691,12.691,0,0,0-1.428,3.372l-2.5.476-.04,2.777-.04,2.777,2.5.516a12.5,12.5,0,0,0,1.349,3.372l-1.428,2.142,1.944,1.984,1.944,1.984,2.142-1.389a13.005,13.005,0,0,0,3.333,1.428l.476,2.5,2.777.04,2.777.04.516-2.5a13.04,13.04,0,0,0,3.372-1.349l2.1,1.428,1.984-1.944,1.984-1.944ZM130.89,58.323a6.846,6.846,0,1,1,9.681.079h0a6.837,6.837,0,0,1-9.681-.079Z"
            fill="#08ccc6"
          />
          <path
            id="Path_218"
            d="M121.844,45.468a8.349,8.349,0,0,0,.952-2.222l1.666-.317V39.2l-1.666-.357a9.318,9.318,0,0,0-.913-2.262l.952-1.428-1.309-1.309-1.309-1.309-1.428.913a7.835,7.835,0,0,0-2.222-.952l-.317-1.666h-3.73l-.357,1.666a9.318,9.318,0,0,0-2.262.913l-1.428-.952-1.309,1.309-1.309,1.309.952,1.428a8.35,8.35,0,0,0-.952,2.222l-1.666.317v3.73l1.666.357a9.318,9.318,0,0,0,.913,2.262l-.952,1.428,1.309,1.309,1.309,1.309,1.428-.952a7.381,7.381,0,0,0,2.222.952L112.4,51.1h3.73l.357-1.666a9.319,9.319,0,0,0,2.262-.913l1.389.952,1.309-1.309,1.309-1.309Zm-10.712-1.309a4.545,4.545,0,1,1,6.427.079h0a4.562,4.562,0,0,1-6.427-.079Z"
            fill="#08ccc6"
          />
        </g>
      </g>
    </svg>
  );
}

export default IllustrationSetting;
