export const MILLISECONDS = 'milliseconds';
export const SECONDS = 'seconds';
export const MINUTES = 'minutes';
export const HOURS = 'hours';
export const DAYS = 'days';
export const DATE = 'date';
export const WEEK = 'week';
export const MONTHS = 'months';
export const YEAR = 'year';
export const DECADE = 'decade';
export const CENTURY = 'century';
