import { PropTypes } from 'prop-types';
import Text from 'components/Texts/Text';

const Text16 = Text.withComponent('p');

Text16.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

Text16.defaultProps = {
  fontSize: 16,
  color: 'gray.body',
};

export default Text16;
