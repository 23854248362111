import React from 'react';
import { FormattedMessage } from 'react-intl';
import IllustrationLayout from 'components/Illustrations/IllustrationLayout';
import IllustrationSetting from 'components/Illustrations/IllustrationSetting';
import { getCurrentUser, isNissanEmployee } from 'utils/userUtils';
import messages from './messages';

const summaryLabel = () => {
  const currentUser = getCurrentUser();
  let message = messages.summary;

  if (currentUser.company.office_filtered)
    message = messages.officeFilteredSummary;
  if (isNissanEmployee()) message = messages.meetingroomGroupSummary;

  return <FormattedMessage {...message} />;
};

const descriptionLabel = () => {
  const currentUser = getCurrentUser();
  let message = messages.description;

  if (currentUser.company.office_filtered)
    message = messages.officeFilteredDescription;
  if (isNissanEmployee()) message = messages.meetingroomGroupDescription;

  return <FormattedMessage {...message} />;
};

function ResourceSettingGuideView() {
  return (
    <IllustrationLayout
      illustration={<IllustrationSetting />}
      summary={summaryLabel()}
      description={descriptionLabel()}
    />
  );
}

export default ResourceSettingGuideView;
