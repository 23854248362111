import styled from 'styled-components';
import { Label } from '@d-lighted/design-system';

import * as color from 'constants/colors';

export const Container = styled.div`
  background: ${color.white};
  box-shadow: 0px -5px 14px rgba(0, 0, 0, 0.09);
  border-radius: 20px 20px 0px 0px;
  position: fixed;
  width: 100vw;
  height: 77px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 42;
`;

export const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: auto;
`;

export const NavIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const SelfColoringNavIcon = styled.img`
  width: 20px;
  height: 20px;

  ${({ isSelected }) =>
    isSelected &&
    'filter: invert(56%) sepia(44%) saturate(3987%) hue-rotate(151deg) brightness(95%) contrast(101%);'};
`;

export const NavLabel = styled(Label)`
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: ${props => (props.isSelected ? color.brandBlue : color.grayThin3)};
  padding-top: 7px;
  width: auto;
`;
