import { defineMessages } from 'react-intl';

export default defineMessages({
  results: {
    id: 'resourceList.sorting.results',
    defaultMessage: 'Sorting search results',
  },
  changeConditions: {
    id: 'resourceList.sorting.desktop.changeConditions',
    defaultMessage: 'Change search criteria',
  },
  duration: {
    id: 'resourceList.sorting.desktop.duration',
    defaultMessage: '{duration} min. duration',
  },
  includingHolidays: {
    id: 'resourceList.sorting.desktop.includingHolidays',
    defaultMessage: 'Including weekends and holidays',
  },
  notIncludingHolidays: {
    id: 'resourceList.sorting.desktop.notIncludingHolidays',
    defaultMessage: 'Not including weekends and holidays',
  },
  rooms: {
    id: 'resourceList.sorting.rooms',
    defaultMessage: ' rooms',
  },
  meeting: {
    id: 'resourceCalendarEvent.meeting',
    defaultMessage: 'Meeting',
  },
  titleLabel: {
    id: 'eventCreateDialog.title.label',
    defaultMessage: 'Title',
  },
  roomLabel: {
    id: 'eventCreateDialog.room.label',
    defaultMessage: 'Meetingroom',
  },
  cancel: {
    id: 'resourceList.sorting.cancel',
    defaultMessage: 'Cancel',
  },
  reserve: {
    id: 'reserve.button',
    defaultMessage: 'Reserve',
  },
  success: {
    id: 'bookingSuccessDialog.title',
    defaultMessage: 'Reservation Made',
  },
  complete: {
    id: 'bookingSuccessDialog.button.completion',
    defaultMessage: 'Complete',
  },
  successMsg: {
    id: 'bookingSuccessDialog.successMsgShort',
    defaultMessage: 'An appointment has been created in the calendar.',
  },
  returnToFilter: {
    id: 'bookingSuccessDialog.button.returnToRefined',
    defaultMessage: 'Return to refined result',
  },
  noResult: {
    id: 'resourceList.emptySearchResults.msg',
    defaultMessage:
      'There are no available meeting rooms. Please change your search criteria.',
  },
});
