import { defineMessages } from 'react-intl';

export default defineMessages({
  helpCenter: {
    id: 'header.helpCenter',
    defaultMessage: 'Help Center',
  },
  schedulingApp: {
    id: 'header.schedulingApp',
    defaultMessage: 'Scheduling App',
  },
  accountInfo: {
    id: 'header.dropdown.accountInfo',
    defaultMessage: 'Account Info',
  },
  integrations: {
    id: 'header.dropdown.integrations',
    defaultMessage: 'Integrations',
  },
  logout: {
    id: 'header.dropdown.logout',
    defaultMessage: 'Logout',
  },
  receptionName: {
    id: 'container.productInfoDropDown.reception.name',
    defaultMessage: 'Receptionist',
  },
  receptionDescription: {
    id: 'container.productInfoDropDown.reception.description',
    defaultMessage: 'Cloud RECEPTIONIST service',
  },
  roomsName: {
    id: 'container.productInfoDropDown.rooms.name',
    defaultMessage: 'Rooms App',
  },
  roomsDescription: {
    id: 'container.productInfoDropDown.rooms.description',
    defaultMessage: 'Meeting rooms booking system',
  },
  schedulingName: {
    id: 'container.productInfoDropDown.scheduling.name',
    defaultMessage: 'Scheduling App',
  },
  schedulingDescription: {
    id: 'container.productInfoDropDown.scheduling.description',
    defaultMessage: 'Appointment booking tool',
  },
  otherName: {
    id: 'container.productInfoDropDown.other.name',
    defaultMessage: 'Other products',
  },
  otherProducts: {
    id: 'container.productInfoDropDown.otherProducts',
    defaultMessage: 'Other receptionist products',
  },
  ownedProducts: {
    id: 'container.productInfoDropDown.ownedProducts',
    defaultMessage: 'Owned Products',
  },
  otherDescription: {
    id: 'container.productInfoDropDown.other.description',
    defaultMessage: 'Other products from RECEPTIONIST',
  },
});
