import styled, { css } from 'styled-components';
import * as color from 'constants/colors';
import { Button, Col, Label as BaseLabel, Row } from '@d-lighted/design-system';
import LoginBG from '../../../images/login_bg.png';

const sharedFlexCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Background = styled.div`
  background-color: ${color.backgroundGray};
  padding-top: 48.5px;
  min-height: calc(100vh - 48.5px);
  width: 100%;
  position: relative;
`;

export const LeftCol = styled(Col)`
  background-color: ${color.white};
  ${sharedFlexCss};
`;

export const RightCol = styled(Col)`
  background: url(${LoginBG});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0px 20px;
  ${sharedFlexCss};
  @media (min-width: 0px) and (max-width: 767px) {
    padding: 30px 20px;
  }
`;

export const LoginFormRow = styled(Row)`
  height: 100%;
  width: 65%;
  ${sharedFlexCss};
  @media (min-width: 0px) and (max-width: 767px) {
    padding: 30px 0px;
    width: 90%;
  }
`;

export const CenteredCol = styled(Col)`
  ${sharedFlexCss};
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: start;
  @media (min-width: 0px) and (max-width: 767px) {
    ${sharedFlexCss};
  }
`;

export const Container = styled.div`
  width: 380px;
  margin: 81px auto 0 auto;
  padding: 20px 60px 30px;
  background-color: ${color.white};
  border-radius: 6px;
  box-shadow: 0 10px 26px 0 rgba(37, 38, 42, 0.1);
  opacity: 1;
  @media (max-width: 768px) {
    width: calc(100% - 120px);
  }
`;

export const WelcomeContainer = styled.div`
  margin-top: 20px;
  text-align: left;
  @media (min-width: 0px) and (max-width: 767px) {
    text-align: center;
  }
`;

export const WelcomeParagraph = styled.p`
  color: ${color.blackShade2};
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;

  @media (min-width: 0px) and (max-width: 767px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const WelcomeLogin = styled.p`
  margin-top: 23px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${color.grayShade4};
`;

export const FormBody = styled.div`
  margin-top: 14px;
  padding-bottom: 11px;
`;

export const Label = styled(BaseLabel)`
  font-weight: 700;
  color: ${color.blackShade2};
  letter-spacing: 0px;
`;

export const BlueLabel = styled(Label)`
  color: ${color.blueShade};
  font-weight: 500;
`;

export const BottomTextBody = styled.div`
  text-align: right;
  margin: 8px 0px 16px 0px;
`;

export const CreateAccountDiv = styled.div`
  margin-top: 40px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const HighlightedText = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${color.blueShade};
`;

export const ForgotPasswordBody = styled.div`
  margin-bottom: 4px;
`;

export const LoginButton = styled(Button)`
  height: 50px;
  font-size: 16px;
  background-color: ${color.blueShade};
  border-radius: 100px;
  &:hover {
    background-color: ${color.blueShade};
  }
  &:focus {
    background-color: ${color.blueShade};
  }
`;

export const PromotionHeading = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  padding-bottom: 36px;
  width: 100%;
  color: ${color.white};

  @media (min-width: 0px) and (max-width: 767px) {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px;
  }
`;

export const PromotionImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 514px;
  max-height: 270.6px;
  border-radius: 10px;
`;
