function createObjectUrlFromBlob({ data, headers }) {
  if (headers['content-type'].includes('csv')) {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, data], {
      type: 'text/csv; charset=shift_jis',
    });
    // `createObjectURL` doesn't work well in IE11 so the blob is returned instead
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      return blob;
    }
    return (window.URL || window.webkitURL).createObjectURL(blob);
  }
  return data;
}

// This allows for Chrome, Firefox & IE11
export function downloadFile(response, fileName) {
  const url = createObjectUrlFromBlob(response);
  const a = document.createElement('a');
  a.style.cssText = 'display: none';
  a.href = url;
  if ('download' in a) {
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const download = event => {
      event.preventDefault();
      window.navigator.msSaveOrOpenBlob(url, fileName);
    };
    a.addEventListener('click', download);
    document.body.appendChild(a);
    a.click();
    a.removeEventListener('click', download);
    document.body.removeChild(a);
  } else {
    throw Error('File download not supported.');
  }
}
