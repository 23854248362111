import { useDispatch, useSelector } from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import axios from 'setupAxios';
import { isEmpty } from 'lodash';
import { setSettingResourceApp } from 'containers/Home/actions';
import humps from 'lodash-humps';

function useGetSettingResourceApp() {
  const settingResourceApp = useSelector(
    state => state.resourceEvent?.settingResourceApp,
  );
  const dispatch = useDispatch();

  const fetchSettingResourceApp = async () => {
    dispatch(showLoading());
    try {
      const { data } = await axios('/company/setting_resource_apps', {
        method: 'GET',
      }).catch(e => e.response);

      if (!isEmpty(data) && !isEmpty(data?.setting_resource_app)) {
        const camelizedData = humps(data?.setting_resource_app);
        dispatch(setSettingResourceApp(camelizedData));
      }
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  return {
    fetchSettingResourceApp,
    settingResourceApp,
  };
}

export { useGetSettingResourceApp };
