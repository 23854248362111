import styled from 'styled-components';
import * as colors from 'constants/colors';
import { Button, Input, Label } from '@d-lighted/design-system';

export const DialogHeader = styled.span`
  font-weight: 600;
  font-size: 18px;
`;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px;
  font-size: 14px;
  font-weight: 400;
`;

export const CustomForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px;
  font-size: 14px;
  font-weight: 400;

  .confirm_button {
    background-color: ${colors.brandBlue};
  }
  .cancel_button,
  .cancel_button:hover {
    color: ${colors.darkCyan} !important;
    border: none;
    background-color: transparent;
  }
  .space_top {
    padding: 20px 0;
  }
`;

export const InputLabel = styled(Label)``;

export const InputField = styled(Input)`
  height: 48px;
  background: ${colors.lightGray};
  border-radius: 11px;
  border-color: ${colors.lightGray};

  & + div {
    font-size: 14px;
    position: relative !important;
  }
`;

export const ConfirmationDialogButton = styled(Button)`
  min-width: 0 !important;
  padding: 15px !important;
`;

export const ConfirmationReturnButton = styled(Button)`
  color: ${colors.darkCyan} !important;
  border: none !important;
  background-color: transparent !important;

  &:hover {
    color: ${colors.darkCyan} !important;
    border: none !important;
    background-color: transparent !important;
  }
`;

export const DatePickerContainer = styled.div`
  padding-top: 20px;
`;

export const DatePickerErrorMessage = styled.span`
  text-wrap: nowrap;
  color: ${colors.errorRed};
  margin-left: -100px;
`;
