import React from 'react';
import { Loader } from '@d-lighted/design-system';

import { LoaderContainer } from './styled';

function LoadingView() {
  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  );
}

export default LoadingView;
