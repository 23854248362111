import React from 'react';
import { Modal } from '@d-lighted/design-system';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import {
  QRContainer,
  QRHeaderContainer,
  QRHeader,
  QRClose,
  QRScanLabel,
  StyledQrReader,
} from './styled';
import messages from '../messages';

function QRScannerDialog({ handleScan, handleError, ...otherProps }) {
  return (
    <Modal
      {...otherProps}
      width="100vw"
      height="100vh"
      bg="rgb(90 90 90)"
      style={{ position: 'relative' }}
    >
      <QRContainer>
        <QRHeaderContainer>
          <QRHeader color="white">
            <FormattedMessage {...messages.scanQRTitle} />
          </QRHeader>
          <QRScanLabel color="white">
            <FormattedMessage {...messages.scanQRSubtitle} />
          </QRScanLabel>
        </QRHeaderContainer>
        <QRClose onClick={otherProps.toggleModal}>
          <FontAwesomeIcon icon={faTimes} />
        </QRClose>
        <StyledQrReader
          resolution={600}
          delay={500}
          onError={handleError}
          onScan={handleScan}
        />
      </QRContainer>
    </Modal>
  );
}

QRScannerDialog.propTypes = {
  handleScan: PropTypes.oneOfType([PropTypes.func]).isRequired,
  handleError: PropTypes.oneOfType([PropTypes.func]).isRequired,
};

export default QRScannerDialog;
