import { useState } from 'react';
import axios from '../setupAxios';

function useFloors() {
  const [floors, setFloors] = useState();

  async function fetchFloors() {
    const response = await axios.get('company/resources/signage/floors');

    if (response.status === 200) {
      setFloors(response.data.floors);
      return response.data.floors;
    }

    return [];
  }

  return {
    fetchFloors,
    floors,
  };
}

export { useFloors };
