import React from 'react';

import CalendarBody from './components/CalendarBody';
import { BigCalendarWrapper } from './components/BigCalendarWrapper';

function BigCalendar(props) {
  return (
    <BigCalendarWrapper>
      <CalendarBody {...props} />
    </BigCalendarWrapper>
  );
}

export default BigCalendar;
