import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import receptionLogo from 'images/products/icon_reception.svg';
import schedulingLogo from 'images/products/icon_scheduling.svg';
import roomsLogo from 'images/products/icon_rooms.svg';
import receptionPromoImage from 'images/products/img-reception.jpg';
import schedulingPromoImage from 'images/products/img-scheduling.jpg';
import roomsPromoImage from 'images/products/img-rooms.jpg';
import messages from './messages';

const BottomSectionContainer = styled.span`
  display: flex;
  margin-top: 30px;
  padding: 32px;
  border-radius: 10px;
  box-shadow: 0 10px 30px 0 rgba(210, 224, 233, 0.75);
  border: solid 1px #e7e9ea;
  background-color: #fff;
  margin-bottom: 49px;
`;

const ImageContainer = styled.img`
  width: 290px;
  height: 205px;
`;

const ContentContainer = styled.div`
  display: block;
  padding-left: 42px;
`;

const SuggestedSurtitle = styled.p`
  position: relative;
  font-size: 18px;
  text-align: center;
  color: #484e62;
  letter-spacing: 1px;
  font-weight: 500;
  top: 15px;
`;

const SuggestedSubtitle = styled.p`
  margin-bottom: 16px;
  height: 40px;
  font-size: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #484e62;
`;

const SuggestedTitle = styled.p`
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.14px;
  text-align: left;
  color: #464c62;
`;

const ProductContainer = styled.p`
  display: flex;
  margin-bottom: 8px;
`;

const ProductIcon = styled.img`
  width: 42.9px;
  height: 42.9px;
  margin-right: 18px;
`;

const ProductTextcontainer = styled.p`
  display: block;
  margin-bottom: 16px;
`;

const ProductSubtitle = styled.p`
  height: 16px;
  font-size: 11px;
  letter-spacing: 0.08px;
  color: #68878d;
`;

const ProductTitle = styled.p`
  height: 27px;
  letter-spacing: 0.09;
  font-size: 18px;
  font-weight: bold;
  color: #484e62;
`;

const ProductButton = styled.a`
  text-decoration: none;
  height: 40px;
  padding: 8px 20px 10px;
  border-radius: 3px;
  border: solid 1px #e7e9ea;
  background-color: #e7e9ea;
  color: ##9bb0b5;
  font-size: 16px;
  cursor: pointer;
  line-height: 1;
  letter-spacing: 1px;
  text-align: center;
  &:hover {
    background-color: #009d98;
    border: solid 1px #009d98;
  }
`;

function TopProduct({ product }) {
  const productContentList = {
    reception: {
      topProductSurtitle: messages.receptionistTopproductSurtitle,
      topProductTitle: messages.receptionistTopproductTitle,
      topProductSubtitle: messages.receptionistTopproductSubtitle,
      productPromoImage: receptionPromoImage,
      productSurtitle: messages.receptionistSurtitle,
      productIcon: receptionLogo,
      productTitle: messages.receptionistTitle,
      link: process.env.REACT_APP_WEB_PATH,
    },
    scheduling: {
      topProductSurtitle: messages.schedulingTopproductSurtitle,
      topProductTitle: messages.schedulingTopproductTitle,
      topProductSubtitle: messages.schedulingTopproductSubtitle,
      productPromoImage: schedulingPromoImage,
      productSurtitle: messages.schedulingSurtitle,
      productIcon: schedulingLogo,
      productTitle: messages.schedulingTitle,
      link: process.env.REACT_APP_SCHEDULING_APP_URL,
    },
    meetingroom: {
      topProductSurtitle: messages.roomsTopproductSurtitle,
      topProductTitle: messages.roomsTopproductTitle,
      topProductSubtitle: messages.roomsTopproductSubtitle,
      productPromoImage: roomsPromoImage,
      productSurtitle: messages.roomsSurtitle,
      productIcon: roomsLogo,
      productTitle: messages.roomsTitle,
      link: process.env.REACT_APP_ROOMS_WEB_PATH,
    },
  };
  return (
    <div>
      <SuggestedSurtitle>
        <FormattedMessage {...productContentList[product].topProductSurtitle} />
      </SuggestedSurtitle>
      <BottomSectionContainer>
        <ImageContainer src={productContentList[product].productPromoImage} />
        <ContentContainer>
          <SuggestedTitle>
            <FormattedMessage
              {...productContentList[product].topProductTitle}
            />
          </SuggestedTitle>
          <SuggestedSubtitle>
            <FormattedMessage
              {...productContentList[product].topProductSubtitle}
            />
          </SuggestedSubtitle>
          <ProductContainer>
            <ProductIcon src={productContentList[product].productIcon} />
            <ProductTextcontainer>
              <ProductSubtitle>
                <FormattedMessage
                  {...productContentList[product].productSurtitle}
                />
              </ProductSubtitle>
              <ProductTitle>
                <FormattedMessage
                  {...productContentList[product].productTitle}
                />
              </ProductTitle>
            </ProductTextcontainer>
          </ProductContainer>
          <ProductButton
            href={productContentList[product].link}
            target="_blank"
          >
            <FormattedMessage {...messages.activeButton} />
          </ProductButton>
        </ContentContainer>
      </BottomSectionContainer>
    </div>
  );
}

TopProduct.defaultProps = {
  product: 'reception',
};

TopProduct.propTypes = {
  product: PropTypes.string,
};

export default TopProduct;
