import { defineMessages } from 'react-intl';

export default defineMessages({
  vacancy: {
    id: 'bigCalendar.calendarHeader.vacancy',
    defaultMessage: 'Vacant',
  },
  inUse: {
    id: 'bigCalendar.calendarHeader.inUse',
    defaultMessage: 'In Use',
  },
  checkedOut: {
    id: 'bigCalendar.calendarHeader.checkedOut',
    defaultMessage: 'Checked Out',
  },
  inAMeeting: {
    id: 'bigCalendar.calendarHeader.inAMeeting',
    defaultMessage: 'In a Meeting',
  },
  reserved: {
    id: 'bigCalendar.calendarHeader.reserved',
    defaultMessage: 'Reserved',
  },
  downloadButtonText: {
    id: 'bigCalendar.calendarHeader.downloadCsv.buttonText',
    defaultMessage: 'Download MeetingRooms availabilty',
  },
  filterButtonText: {
    id: 'bigCalendar.calendarHeader.dropdown.buttonText',
    defaultMessage: 'Filter available MeetingRooms',
  },
  notSpecified: {
    id: 'bigCalendar.calendarHeader.dropdown.notSpecified',
    defaultMessage: 'Not Specified',
  },
  fifteenMins: {
    id: 'bigCalendar.calendarHeader.dropdown.fifteenMinutes',
    defaultMessage: 'Next 15 minutes',
  },
  thirtyMins: {
    id: 'bigCalendar.calendarHeader.dropdown.thirtyMinutes',
    defaultMessage: 'Next 30 minutes',
  },
  sixtyMins: {
    id: 'bigCalendar.calendarHeader.dropdown.sixtyMinutes',
    defaultMessage: 'Next 60 minutes',
  },
  titleLabel: {
    id: 'eventCreateDialog.title.label',
    defaultMessage: 'Title',
  },
  noteLabel: {
    id: 'eventCreateDialog.note.label',
    defaultMessage: 'Notes',
  },
  reserveButton: {
    id: 'reserve.button',
    defaultMessage: 'Reserve',
  },
  privateEvent: {
    id: 'resourceCalendarEvent.privateEvent',
    defaultMessage: 'Private event',
  },
  privateDescription: {
    id: 'resourceCalendarEvent.privateEvent.description',
    defaultMessage:
      'Sorry, the detail can not be seen due to the private event.',
  },
  meeting: {
    id: 'resourceCalendarEvent.meeting',
    defaultMessage: '打ち合わせ',
  },
  overlap: {
    id: 'bigCalendar.calendarBody.selectionOverlaps',
    defaultMessage:
      'The time you specified has already been reserved for another appointment.',
  },
  confirmDelete: {
    id: 'eventDetailsDialog.delete.confirmation',
    defaultMessage: 'Do you really want to delete this appointment?',
  },
  confirmCheckIn: {
    id: 'eventDetailsDialog.checkin.confirmation',
    defaultMessage: 'Are you sure you want to check in?',
  },
  confirmCheckOut: {
    id: 'eventDetailsDialog.checkout.confirmation',
    defaultMessage: 'Are you sure you want to check out?',
  },
  displaySettings: {
    id: 'bigCalendar.calendarHeader.dropdown.displaySettings',
    defaultMessage: 'Display Settings',
  },
  fullScreen: {
    id: 'bigCalendar.calendarHeader.dropdown.fullScreen',
    defaultMessage: 'Full screen',
  },
  endFullScreen: {
    id: 'bigCalendar.calendarHeader.dropdown.endFullScreen',
    defaultMessage: 'End full screen',
  },
  signageMode: {
    id: 'bigCalendar.calendarHeader.dropdown.signageMode',
    defaultMessage: 'Signage Mode',
  },
  conferenceRoom: {
    id: 'bigCalendar.calendarHeader.dropdown.conferenceRoom',
    defaultMessage: 'Conference room',
  },
  year: {
    id: 'bigCalendar.calendarHeader.year',
    defaultMessage: ' ',
  },
  selectTheMeetingRoomToDisplay: {
    id: 'bigCalendar.calendarHeader.dropdown.selectTheMeetingRoomToDisplay',
    defaultMessage: 'Select the meeting room to display',
  },
  saveYourChanges: {
    id: 'bigCalendar.calendarHeader.dropdown.saveYourChanges',
    defaultMessage: 'Save your changes',
  },
  logoupload: {
    id: 'bigCalendar.calendarHeader.dropdown.logoupload',
    defaultMessage: 'Logo Upload',
  },
  premiumFeature: {
    id: 'bigCalendar.calendarHeader.dropdown.premiumFeature',
    defaultMessage: 'Premium',
  },
  premiumFeatureTooltipText: {
    id: 'bigCalendar.calendarHeader.dropdown.premiumFeatureTooltipText',
    defaultMessage: 'This feature is only available for our Premium plan.',
  },
  checkin: {
    id: 'checkin.button',
    defaultMessage: 'Checkin',
  },
  checkout: {
    id: 'checkout.button',
    defaultMessage: 'Checkout',
  },
  searchSlots: {
    id: 'resourceList.sorting.titleDesktop',
    defaultMessage: 'Sorting Search',
  },
  sortingModalHeader: {
    id: 'resourceList.sorting.titleDesktop.modal.header',
    defaultMessage: 'Sorting',
  },
  meetingTime: {
    id: 'resourceList.sorting.meetingTime.meetingTime',
    defaultMessage: 'Desired meeting time',
  },
  timeSlot: {
    id: 'resourceList.sorting.meetingTime.timeSlot',
    defaultMessage: 'Meeting time slot',
  },
  holidays: {
    id: 'resourceList.sorting.meetingTime.holidays',
    defaultMessage: 'Include weekends and holidays',
  },
  period: {
    id: 'resourceList.sorting.meetingTime.period',
    defaultMessage: 'Period of time',
  },
  other: {
    id: 'resourceList.sorting.meetingTime.other',
    defaultMessage: 'Other',
  },
  placeholder: {
    id: 'resourceList.sorting.meetingTime.minute.placeholder',
    defineMessages: 'Please select a duration for the meeting',
  },
  thirtyMin: {
    id: 'resourceList.sorting.meetingTime.30min',
    defaultMessage: '30min',
  },
  sixtyMin: {
    id: 'resourceList.sorting.meetingTime.60min',
    defaultMessage: '60min',
  },
  ninetyMin: {
    id: 'resourceList.sorting.meetingTime.90min',
    defaultMessage: '90min',
  },
  searchButton: {
    id: 'resourceList.sorting.button',
    defaultMessage: 'Search',
  },
  cancel: {
    id: 'resourceList.sorting.cancel',
    defaultMessage: 'Cancel',
  },
  createDisallowPermission: {
    id: 'permission.create_disallow',
    defaultMessage:
      'You do not have permission to create events in this meeting room. Please contact you administrator.',
  },
});
