import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Field, Form } from 'react-final-form';
import { Col, Row, ErrorText } from '@d-lighted/design-system';

import {
  LoginButton,
  Label,
  FormBody,
} from 'containers/SignIn/components/styled';
import Text18 from 'components/Texts/Text18';
import Text14 from 'components/Texts/Text14';
import Text from 'components/Texts/Text';
import { useLocation, useHistory } from 'react-router-dom';
import { useValidation } from 'hooks/useValidation';
import { getSamlCompanyEmailDomain } from 'utils/userUtils';
import SignInHeader from 'components/SignInHeader';
import { isMobileDevice } from 'utils/mobileUtils';
import messages from './messages';
import { useSamlSignIn } from './api';
import {
  Box,
  CheckedCircle,
  Container,
  FieldWrapper,
  InTextLabel,
  SamlWrapper,
  StyledText,
  StyledInput,
} from './components/styled';

const SamlSignIn = ({ intl, useNotify, error }) => {
  const notify = useNotify(intl);
  const history = useHistory();
  const location = useLocation();
  const companyDomainInitialValue = getSamlCompanyEmailDomain();

  const { required, companyEmailDomain } = useValidation(intl);

  const composeValidators = (...validators) => value =>
    validators.reduce(
      (errors, validator) => errors || validator(value),
      undefined,
    );

  const { samlSignIn } = useSamlSignIn({ notify });

  const onClickSwitchSignInButton = () => {
    history.push('/sign_in');
  };

  const hideHeader =
    isMobileDevice() && location.search.includes('webview=true');

  return (
    <SamlWrapper>
      {!hideHeader && <SignInHeader />}
      <Box pt={30} pb={50}>
        <Container>
          <Box textAlign="center">
            <Box mb={20}>
              <CheckedCircle />
            </Box>
            <Text18 mb={15}>
              <FormattedMessage {...messages.samlHeader} />
            </Text18>
            <Text14>
              <FormattedMessage {...messages.inputDomain} />
            </Text14>
          </Box>
          <Form
            onSubmit={samlSignIn}
            render={({ handleSubmit }) => (
              <Box mt={30}>
                <form onSubmit={handleSubmit}>
                  <FormBody>
                    <Row mb="17px">
                      <Col xs>
                        <Row mb="13px">
                          <Col xs>
                            <Label fontSize="14px">
                              <FormattedMessage {...messages.companyDomain} />
                            </Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs>
                            <FieldWrapper>
                              <InTextLabel>@</InTextLabel>
                              <Field
                                id="company_email_domain"
                                name="company_email_domain"
                                component={StyledInput}
                                placeholder="example.co.jp"
                                width="calc(100% - 46px)"
                                marginLeft="46px"
                                borderColor="#e9eff4"
                                validate={composeValidators(
                                  required,
                                  companyEmailDomain,
                                )}
                                initialValue={companyDomainInitialValue}
                              />
                            </FieldWrapper>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </FormBody>
                  {error && (
                    <Row mb="20px">
                      <Col xs>
                        <ErrorText>{error}</ErrorText>
                      </Col>
                    </Row>
                  )}
                  <Box mt={3} textAlign="center" width="100%">
                    <Text>
                      <FormattedMessage {...messages.usablePlan} />
                      <Box mt={1}>
                        <StyledText>
                          <FormattedMessage {...messages.helpLink} />
                        </StyledText>
                      </Box>
                    </Text>
                    <Box textAlign="right" pt={4}>
                      <Text
                        onClick={onClickSwitchSignInButton}
                        cursor="pointer"
                      >
                        <FormattedMessage
                          {...messages.passwordAuthentication}
                        />
                      </Text>
                    </Box>
                  </Box>
                  <LoginButton mt={30} type="submit">
                    <FormattedMessage {...messages.login} />
                  </LoginButton>{' '}
                </form>
              </Box>
            )}
          />
        </Container>
      </Box>
    </SamlWrapper>
  );
};

SamlSignIn.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  useNotify: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

export default injectIntl(SamlSignIn);
