import { React } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  LogoUploadSuccessModalContainer,
  LogoUploadTopTitle,
  LogoUploadTopSubtitle,
  CloseSuccessButton,
  SuccessCheckmark,
  SuccessCircle,
} from './styled';
import messages from '../messages';

const LogoUploadSuccessModal = ({ handleCloseModal }) => {
  return (
    <LogoUploadSuccessModalContainer>
      <LogoUploadTopTitle>
        <FormattedMessage {...messages.uploadSuccessSetting} />
      </LogoUploadTopTitle>
      <SuccessCircle>
        <SuccessCheckmark />
      </SuccessCircle>
      <LogoUploadTopSubtitle>
        <FormattedMessage {...messages.uploadSuccessCompletedTop} />
        <br />
        <FormattedMessage {...messages.uploadSuccessCompletedBottom} />
      </LogoUploadTopSubtitle>
      <CloseSuccessButton onClick={handleCloseModal}>
        <FormattedMessage {...messages.uploadSuccessCloseButton} />
      </CloseSuccessButton>
    </LogoUploadSuccessModalContainer>
  );
};
LogoUploadSuccessModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
};

export default LogoUploadSuccessModal;
