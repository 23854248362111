import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import receptionistLogo from 'images/products/icon_reception.svg';
import schedulingLogo from 'images/products/icon_scheduling.svg';
import roomsLogo from 'images/products/icon_rooms.svg';
import ProductTopSection from './ProductTopSection';
import FeatureContent from './FeatureContent';
import Option from './Option';
import messages from './messages';

const MainContainer = styled.div`
  background-color: #fff;
  border-radius: 10px;
  height: 100%;
  margin-bottom: 22px;
  padding: 32px;
  width: 442px;
`;

function Product({ product, isActive }) {
  const productContentList = {
    reception: {
      productIcon: receptionistLogo,
      productSurtitle: messages.receptionistSurtitle,
      productTitle: messages.receptionistTitle,
      middleContentText: messages.receptionistMiddleContentText,
      featureItem1: messages.receptionistFeature1,
      featureItem2: messages.receptionistFeature2,
      featureItem3: messages.receptionistFeature3,
      option1Title: messages.receptionistOption1Title,
      option1Link: process.env.REACT_APP_CONTACT_US_URL,
      option1Subtitle: messages.receptionistOption1Subtitle,
      option1ComingSoon: true,
      option2Title: messages.receptionistOption2Title,
      option2Link: process.env.REACT_APP_CONTACT_US_URL,
      option2Subtitle: messages.receptionistOption2Subtitle,
    },
    scheduling: {
      productIcon: schedulingLogo,
      productSurtitle: messages.schedulingSurtitle,
      productTitle: messages.schedulingTitle,
      middleContentText: messages.schedulingMiddleContentText,
      featureItem1: messages.schedulingFeature1,
      featureItem2: messages.schedulingFeature2,
      featureItem3: messages.schedulingFeature3,
    },
    meetingroom: {
      productIcon: roomsLogo,
      productSurtitle: messages.roomsSurtitle,
      productTitle: messages.roomsTitle,
      middleContentText: messages.roomsMiddleContentText,
      featureItem1: messages.roomsFeature1,
      featureItem2: messages.roomsFeature2,
      featureItem3: messages.roomsFeature3,
      option1Title: messages.roomsOption1Title,
      option1Link: process.env.REACT_APP_CONTACT_US_URL,
      option1Subtitle: messages.roomsOption1Subtitle,
    },
  };

  return (
    <MainContainer product={product}>
      <ProductTopSection
        productIcon={productContentList[product].productIcon}
        productSurtitle={productContentList[product].productSurtitle}
        productTitle={productContentList[product].productTitle}
      />
      <FeatureContent
        middleContentText={productContentList[product].middleContentText}
        featureItem1={productContentList[product].featureItem1}
        featureItem2={productContentList[product].featureItem2}
        featureItem3={productContentList[product].featureItem3}
        isActive={isActive}
      />
      <Option
        comingSoon={productContentList[product].option1ComingSoon}
        option1Title={productContentList[product].option1Title}
        option1Link={productContentList[product].option1Link}
        option1Subtitle={productContentList[product].option1Subtitle}
        option2Title={productContentList[product].option2Title}
        option2Link={productContentList[product].option2Link}
        option2Subtitle={productContentList[product].option2Subtitle}
        isActive={isActive}
      />
    </MainContainer>
  );
}

Product.defaultProps = {
  product: 'reception',
  isActive: true,
};

Product.propTypes = {
  product: PropTypes.string,
  isActive: PropTypes.bool,
};

export default Product;
