import React from 'react';
import { Row, Col, Label } from '@d-lighted/design-system';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import IconClock from 'components/Icons/IconClock';
import IconDoorOpen from 'components/Icons/IconDoorOpen';
import IconTimes from 'components/Icons/IconTimes';
import IconNotes from 'components/Icons/IconNotes';
import IconUserFriends from 'components/Icons/IconUserFriends';
import { WordBreakLabel } from 'components/_commons/styled';
import * as colors from 'constants/colors';
import {
  getCurrentUser,
  isRedirectToOnetimeAppointment,
} from 'utils/userUtils';
import GarbageSvg from 'images/ic_garbage.svg';
import {
  EventDetailsContent,
  ModalTitle,
  ColorIndicator,
  CustomEventModal,
  Icon,
} from './styled';
import messages from '../messages';

function EventDetailsDialog({
  selectedEvent,
  onCancel,
  timeFormat,
  generateEventStatus,
  onDelete,
  ...otherProps
}) {
  const formattedStartTime =
    moment(selectedEvent.start).format('MMMM Do ') +
    moment(selectedEvent.start).locale('en').format(timeFormat);
  const formattedEndTime = moment(selectedEvent.end)
    .locale('en')
    .format(timeFormat);

  const currentUser = getCurrentUser();
  const currentUserEmails = [currentUser.email?.toLowerCase()];

  if (currentUser.calendar_email != null || currentUser.calendar_id) {
    currentUserEmails.push(currentUser.calendar_email?.toLowerCase());
    currentUserEmails.push(currentUser.calendar_id?.toLowerCase());
  } else {
    currentUserEmails.push(
      currentUser.company?.meetingroom_provider_email?.toLowerCase(),
    );
  }

  let indicatorColor = '';
  let border;
  switch (
    generateEventStatus(selectedEvent.checkedInAt, selectedEvent.checkedOutAt)
  ) {
    case 'done':
      indicatorColor = colors.doneEventGray;
      break;
    case 'busy':
      indicatorColor = colors.busyEvent;
      break;
    default:
      indicatorColor = colors.white;
      border = `solid 0.5px ${colors.primaryLightGreen}`;
      break;
  }

  const attendeeNames = (selectedEvent?.attendees || [])
    .filter(attendee => !attendee.resource)
    .map(attendee => attendee.name || attendee.email);

  const isPrivate =
    selectedEvent.visibility === 'private' ||
    selectedEvent.visibility === 'confidential' ||
    selectedEvent.visibility === 'PRIVATE'; // NOTE: Garoonの場合

  const reditectsOnetime = isRedirectToOnetimeAppointment();

  if (isPrivate) {
    return (
      <CustomEventModal {...otherProps} height="auto">
        <EventDetailsContent>
          <Row padding="16px 16px 14px" width="100%" alignItems="center">
            <Col>
              <ColorIndicator color={indicatorColor} border={border} />
            </Col>
            <Col xs>
              <ModalTitle>
                <FormattedMessage {...messages.privateEvent} />
              </ModalTitle>
            </Col>
            <Col>
              <IconTimes fill={colors.grayThin} onClick={onCancel} />
            </Col>
          </Row>
          <Row padding="0 56px 16px" width="100%">
            <Col xs>
              <Label>
                <FormattedMessage {...messages.privateDescription} />
              </Label>
            </Col>
          </Row>
        </EventDetailsContent>
      </CustomEventModal>
    );
  }

  return (
    <CustomEventModal {...otherProps} height="auto">
      <EventDetailsContent>
        <Row padding="16px 16px 14px" width="100%" alignItems="center">
          <Col>
            <ColorIndicator color={indicatorColor} border={border} />
          </Col>
          <Col overflow="hidden" xs>
            <ModalTitle>{selectedEvent.name}</ModalTitle>
          </Col>
          {selectedEvent.deletable && !reditectsOnetime && (
            <Col>
              <Icon src={GarbageSvg} onClick={onDelete} />
            </Col>
          )}
          <Col>
            <IconTimes fill={colors.grayThin} onClick={onCancel} />
          </Col>
        </Row>

        <Row padding="0 56px 16px" width="100%">
          <Col xs>
            <Row pb="8px" alignItems="center">
              <Col>
                <IconClock fill={colors.grayHeading} width={14} height={14} />
              </Col>
              <Col xs>
                <Label>{`${formattedStartTime} - ${formattedEndTime}`}</Label>
              </Col>
            </Row>
            <Row pb="8px" alignItems="center">
              <Col>
                <IconDoorOpen
                  fill={colors.grayHeading}
                  width={14}
                  height={14}
                />
              </Col>
              <Col xs>
                <WordBreakLabel>{selectedEvent.resourceTitle}</WordBreakLabel>
              </Col>
            </Row>
            {attendeeNames.length > 0 && (
              <Row pb="8px" alignItems="flex-start">
                <Col>
                  <IconUserFriends
                    fill={colors.grayHeading}
                    width={14}
                    height={14}
                  />
                </Col>
                <Col display="flex" flexDirection="column" xs>
                  {attendeeNames.map(attendeeName => (
                    <WordBreakLabel key={attendeeName}>
                      {attendeeName}
                    </WordBreakLabel>
                  ))}
                </Col>
              </Row>
            )}
            {selectedEvent.notes != null && selectedEvent.notes !== '' && (
              <Row pb="8px" alignItems="flex-start">
                <Col>
                  <IconNotes fill={colors.grayHeading} width={14} height={14} />
                </Col>
                <Col style={{ wordBreak: 'break-all' }} xs>
                  <Label style={{ whiteSpace: 'pre-line' }}>
                    {selectedEvent.notes}
                  </Label>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </EventDetailsContent>
    </CustomEventModal>
  );
}

EventDetailsDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectedEvent: PropTypes.oneOfType([PropTypes.object]).isRequired,
  timeFormat: PropTypes.string.isRequired,
  generateEventStatus: PropTypes.func.isRequired,
};

export default EventDetailsDialog;
