const uniqueIdBase =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export function uniqueId() {
  let id = '';
  for (let i = 0; i < 10; i += 1) {
    id += uniqueIdBase.charAt(Math.floor(Math.random() * uniqueIdBase.length));
  }
  return id;
}

export function removeEmpty(obj) {
  Object.keys(obj).forEach(key => obj[key] == null && delete obj[key]);
  return obj;
}

export function queryObjectStringify(queryObj, nesting = '', looping = false) {
  const pairs = Object.entries(queryObj).map(([key, val]) => {
    // eslint-disable-next-line valid-typeof
    if (typeof val === Object) {
      let queryKey = key;
      if (looping) queryKey = `[${key}]`;

      return queryObjectStringify(val, nesting + queryKey, true);
    }
    let queryKey = key;
    if (looping) queryKey = `[${key}]`;
    return [nesting + queryKey, val]
      .map(str => encodeURIComponent(str))
      .join('=');
  });
  return pairs.join('&');
}

export function objectToQuery(queryObj) {
  return queryObjectStringify(removeEmpty(queryObj));
}

export function getTimeFormat(timeConvention) {
  if (!timeConvention) return 'h:mm A';
  return timeConvention === '12-hour' ? 'h:mm A' : 'H:mm';
}

export function getTimeFormatWithDate(timeConvention) {
  if (!timeConvention) return 'MM/DD h:mm A';
  return timeConvention === '12-hour' ? 'MM/DD h:mm A' : 'MM/DD H:mm';
}

export function checkUnexpectedError(status) {
  return status === 500 || status === 404;
}

function preventDefault(e) {
  // eslint-disable-next-line no-param-reassign
  e = e || window.event;
  if (e.preventDefault) e.preventDefault();
  e.returnValue = false;
}

export function enableScroll() {
  if (window.removeEventListener)
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
  document.removeEventListener('wheel', preventDefault, { passive: false }); // Enable scrolling in Chrome
  // eslint-disable-next-line no-multi-assign
  window.onmousewheel = document.onmousewheel = null;
  window.onwheel = null;
  window.ontouchmove = null;
  document.onkeydown = null;
}
