import ReserveScreen from './index';

const route = [
  {
    component: ReserveScreen,
    path: '/reserve',
    exact: true,
    type: 'private',
  },
];

export default route;
