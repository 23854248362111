import React from 'react';
import styled from 'styled-components';
import { Row, Col, Heading } from '@d-lighted/design-system';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import * as color from 'constants/colors';
import EmptyThumbnail from 'images/web_fallback-image.svg';
import messages from './messages';
import { Card, StatusWrapper, StatusText } from './components/styled';

const LimitedHeader = styled(Heading)`
  font-weight: 600;
  overflow-wrap: break-word;
  max-width: 80vw;
  font-size: 20px;
  color: ${color.blackTitle};
`;

const InformationRow = styled(Row)`
  font-size: 12px;
  padding: 3px 12px;
`;

const InformationLabel = styled(Col).attrs(() => {
  return {
    xs: 5,
  };
})`
  color: ${color.grayThin0};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ValueLabel = styled(Col).attrs(() => {
  return {
    xs: 7,
  };
})`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ThumbnailRow = styled(Row)`
  max-height: 194px;
  overflow: hidden;
  display: flex;
  align-content: center;
`;

function DetailCard({ resource, isRoomBusy, settingResourceApp }) {
  const status = isRoomBusy ? (
    <FormattedMessage {...messages.inUse} />
  ) : (
    <FormattedMessage {...messages.vacant} />
  );

  return (
    <Card>
      <ThumbnailRow>
        {settingResourceApp?.enableThumbnail && (
          <img
            src={resource.thumbnail.url || EmptyThumbnail}
            alt={resource?.resourceTitle}
            style={{ width: '100%' }}
          />
        )}
      </ThumbnailRow>
      <Row style={{ padding: '6px 12px' }}>
        <Col display="flex" alignItems="center" style={{ flexGrow: 1 }}>
          <LimitedHeader size={2}>{resource?.resourceTitle}</LimitedHeader>
        </Col>
        <Col pt="6px" style={{ flexGrow: 0 }}>
          <StatusWrapper isRoomBusy={isRoomBusy}>
            <StatusText>{status}</StatusText>
          </StatusWrapper>
        </Col>
      </Row>
      {resource?.providerType !== 'microsoft' && (
        <InformationRow>
          <InformationLabel xs={5}>
            <FormattedMessage {...messages.building} />
          </InformationLabel>
          <Col>{resource?.buildingId}</Col>
        </InformationRow>
      )}
      {settingResourceApp &&
        (resource?.additionalInformation || []).map(infoItem => {
          return (
            <InformationRow key={infoItem.uid}>
              <InformationLabel>{infoItem.labelName}</InformationLabel>
              <ValueLabel>{infoItem.inputValue}</ValueLabel>
            </InformationRow>
          );
        })}
    </Card>
  );
}

DetailCard.propTypes = {
  resource: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isRoomBusy: PropTypes.bool.isRequired,
  settingResourceApp: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default DetailCard;
