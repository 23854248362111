import React from 'react';
import { Loader } from '@d-lighted/design-system';

import { MoreLoaderContainer } from './styled';

function LoadingView() {
  return (
    <MoreLoaderContainer>
      <Loader />
    </MoreLoaderContainer>
  );
}

export default LoadingView;
