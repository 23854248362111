import styled from 'styled-components';
import * as color from 'constants/colors';
import BaseButton from 'components/Buttons/BaseButton';

export const MainButton = styled(BaseButton)`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5.5px 20px 5px 16px;
  border-radius: 3px;
  background-color: ${color.primaryLightGreen};
  border: none;
  font-size: 16px;
`;
