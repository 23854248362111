/**
 *
 * Animation
 *
 */
import { keyframes } from 'styled-components';

export const fade = keyframes`
   0%   { opacity: 0; }
   100% { opacity: 1; }
 `;

export const slideFade = keyframes`
   0%   { 
     opacity: 0;
     transform: translate(-10px);
   }
   100% {
     opacity: 1;
     transform: translate(0px);
   }
 `;
