import styled from 'styled-components';

import * as color from 'constants/colors';

export const Card = styled.div`
  background-color: ${color.white};
  border-radius: 16px;
  padding-bottom: 12px;
  overflow: hidden;
`;

export const StatusWrapper = styled.div`
  border-radius: 3px;
  border: none;
  box-sizing: border-box;
  padding: 2px 4px 3px;
  margin-left: 8px;
  background-color: ${({ isRoomBusy }) =>
    isRoomBusy ? color.inUse : color.available};
`;

export const StatusText = styled.p`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: ${color.white};
`;
