import SamlSignIn from './index';

const samlSignInRoute = [
  {
    component: SamlSignIn,
    path: '/sign_in/saml',
    exact: true,
    feature: 'saml_sign_in',
    type: 'public',
  },
];

export default samlSignInRoute;
