import { Field } from 'react-final-form';
import React from 'react';
import PropTypes from 'prop-types';
import { InvisibleFileUpload } from './styled';

const FileField = ({ name, ...props }) => (
  <Field name={name}>
    {({ input: { value, onChange, ...input } }) => (
      <InvisibleFileUpload
        {...input}
        type="file"
        onChange={({ target }) => onChange(target.files)}
        {...props}
      />
    )}
  </Field>
);

FileField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FileField;
