import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Field } from 'react-final-form';
import { isEqual } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import {
  Checkbox,
  Col,
  InputSelect,
  Row,
  TimePicker,
} from '@d-lighted/design-system';
import PropTypes from 'prop-types';
import messages from 'components/BigCalendar/messages';
import { composeValidators, useValidation } from 'hooks/useValidation';
import {
  DialogLabel,
  SeparatorIcon,
  TimeSelector,
  ConfirmationButton,
  CancelButton,
} from 'components/BigCalendar/components/styled';
import SmallCalendar from 'containers/Sorting/components/SmallCalendar';
import { calculateMinuteSelectionOptions } from 'containers/Home/components/ResourcesList/components/SortingMethods';
import { darkCyan } from 'constants/colors';

const Header = styled.div`
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
  height: 70px;
  padding: 24px 24px 16px;
  width: 100%;
`;

const Body = styled.div`
  box-sizing: border-box;
  height: 454px;
  padding: 0 24px;
  width: 100%;
`;

const Footer = styled.div`
  box-sizing: border-box;
  height: 82px;
  padding: 16px 24px 24px;
  width: 100%;
`;

const DateTimeCol = styled(Col)`
  & > * {
    display: inline-block;
  }
`;

/*
  TODO: using query as initialValues when it exist allows to preload existing query values,
  but it's been disabled because the calendar is not yet capable of loading the values
 */
function ModalContent({ onSubmit, toogleModal /* , query */ }) {
  const { dateArray, twoWeekMaximum, required } = useValidation();
  const [initialValues] = useState({
    desiredDate: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    endTime: '18:30',
    holidays: false,
    meetingTime: '30',
    startTime: '09:00',
  });
  const [minuteSelection, setMinuteSelection] = useState(
    calculateMinuteSelectionOptions(
      initialValues.startTime,
      initialValues.endTime,
    ),
  );
  const meetingTimes = [
    { msg: messages.thirtyMin, value: '30' },
    { msg: messages.sixtyMin, value: '60' },
    { msg: messages.ninetyMin, value: '90' },
    { msg: messages.other, value: 'other' },
  ];

  const handleTimeChange = (start, end, currentMeetingTimes) => {
    const newOptions = calculateMinuteSelectionOptions(
      start || currentMeetingTimes.openingTime,
      end || currentMeetingTimes.closingTime,
    );
    setMinuteSelection(newOptions);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, values, valid, form: { change } }) => {
        const isOther = isEqual(values.meetingTime, 'other');
        return (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Header>
              <FormattedMessage {...messages.sortingModalHeader} />
            </Header>
            <Body>
              <Row mb="10px">
                <Col xs={2}>
                  <DialogLabel>
                    <FormattedMessage {...messages.timeSlot} />
                  </DialogLabel>
                </Col>
                <DateTimeCol xs={10}>
                  <Field
                    name="startTime"
                    component={TimePicker}
                    maxTime={values.endTime}
                    onClose={e =>
                      handleTimeChange(e, null, values?.meetingTimes)
                    }
                  />
                  <SeparatorIcon>~</SeparatorIcon>
                  <Field
                    name="endTime"
                    component={TimePicker}
                    minTime={values.startTime}
                    onClose={e =>
                      handleTimeChange(e, null, values?.meetingTimes)
                    }
                  />
                </DateTimeCol>
              </Row>
              <Row mb={isOther ? '4px' : '10px'}>
                <Col xs={2}>
                  <DialogLabel>
                    <FormattedMessage {...messages.meetingTime} />
                  </DialogLabel>
                </Col>
                <Col xs={10} style={{ display: 'flex', gap: '10px' }}>
                  {meetingTimes.map(meetTime => (
                    <TimeSelector
                      onClick={() => change('meetingTime', meetTime.value)}
                      isSelected={isEqual(meetTime.value, values.meetingTime)}
                      key={meetTime.msg.id}
                    >
                      <FormattedMessage {...meetTime.msg} />
                    </TimeSelector>
                  ))}
                </Col>
              </Row>
              {isOther && (
                <Row mb="10px" alignItems="center">
                  <Col xs={2} />
                  <Col xs={10}>
                    <Field
                      name="minute"
                      component={InputSelect}
                      options={minuteSelection}
                      optionColor={darkCyan}
                      placeholder={
                        <FormattedMessage {...messages.placeholder} />
                      }
                      validate={required}
                      height="40px"
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={2}>
                  <DialogLabel>
                    <FormattedMessage {...messages.period} />
                  </DialogLabel>
                </Col>
                <Col xs={10}>
                  <Field
                    id="holidays"
                    name="holidays"
                    component={Checkbox}
                    useArrayValue={false}
                  >
                    <FormattedMessage {...messages.holidays} />
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col xs={2} />
                <Col xs={5}>
                  <Field
                    name="desiredDate"
                    component={SmallCalendar}
                    validate={composeValidators(dateArray, twoWeekMaximum)}
                  />
                </Col>
                <Col xs={5} />
              </Row>
            </Body>
            <Footer>
              <Row>
                <Col
                  xs
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                  }}
                >
                  <ConfirmationButton
                    className="confirm_button"
                    type="submit"
                    disabled={!valid}
                  >
                    <FormattedMessage {...messages.searchButton} />
                  </ConfirmationButton>
                  <CancelButton type="button" onClick={() => toogleModal()}>
                    <FormattedMessage {...messages.cancel} />
                  </CancelButton>
                </Col>
              </Row>
            </Footer>
          </form>
        );
      }}
    />
  );
}

ModalContent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  toogleModal: PropTypes.func.isRequired,
};

export default ModalContent;
