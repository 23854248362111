import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DatePicker } from '@d-lighted/design-system';

import { DatePickerContainer } from './styled';

const SmallCalendar = ({
  input,
  minDate,
  maxDate,
  meta: { touched, error },
}) => {
  const [date, setDate] = useState([
    moment(input?.value[0]).toDate(),
    moment(input.value[1]).toDate(),
  ]);

  const onDateChange = value => {
    if (value.length === 2) {
      input.onChange([
        moment(value[0]).format('YYYY-MM-DD'),
        moment(value[1]).format('YYYY-MM-DD'),
      ]);
    } else if (value.length === 1) {
      input.onChange([
        moment(value[0]).format('YYYY-MM-DD'),
        moment(value[0]).format('YYYY-MM-DD'),
      ]);
    }
    setDate(value);
  };

  return (
    <DatePickerContainer>
      <DatePicker
        value={date}
        onChange={onDateChange}
        highlightWeek={false}
        selectRange
        showActionButton={false}
        minDate={
          minDate != null ? moment(minDate).add(1, 'day').toDate() : null
        }
        maxDate={
          maxDate != null ? moment(maxDate).subtract(1, 'day').toDate() : null
        }
        allowPartialRange
        boxShadow="none"
        width="100%"
        padding="0"
        id={input.name}
      />

      {touched && error && <span>{error}</span>}
    </DatePickerContainer>
  );
};

export default SmallCalendar;

SmallCalendar.propTypes = {
  input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

SmallCalendar.defaultProps = {
  input: { value: new Date(), onChange: () => {}, name: undefined },
  minDate: undefined,
  maxDate: undefined,
  meta: { error: undefined, touched: undefined },
};
