import { useEffect } from 'react';

function useIntersection(target = null, options, callback) {
  useEffect(() => {
    if (target == null) {
      return () => {};
    }
    const observer = new IntersectionObserver(callback, options);
    observer.observe(target);

    return () => {
      observer.unobserve(target);
    };
  });
}

export default useIntersection;
