import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from '@d-lighted/design-system';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import EmptyThumbnail from 'images/web_fallback-image.svg';

import {
  ResourceRow,
  ResourceTitle,
  CardFirstRow,
  CardSecondaryText,
  ListMeetingRoomThumbnail,
  InUsePill,
  AvailablePill,
} from './styled';
import messages from '../messages';

const ResourceCard = ({
  resource,
  events,
  additionalInformation,
  thumbnail: thumbnailEnabled,
}) => {
  const history = useHistory();

  const {
    resourceTitle,
    capacity,
    buildingId,
    floorName,
    resourceId,
    thumbnail,
  } = resource;

  const isRoomBusy = () =>
    events.some(event => event.checkedInAt && !event.checkedOutAt);

  const renderAvailabilityPill = () => {
    if (!isRoomBusy()) {
      return (
        <AvailablePill>
          <FormattedMessage {...messages.empty} />
        </AvailablePill>
      );
    }

    return (
      <InUsePill>
        <FormattedMessage {...messages.inUse} />
      </InUsePill>
    );
  };

  return (
    <ResourceRow
      onClick={() => {
        window.scrollTo(0, 0);
        history.push(`/resource/${resourceId}`, {
          resourceId,
          resource,
        });
      }}
    >
      <Col style={{ flexGrow: 0 }}>{renderAvailabilityPill()}</Col>
      <Col style={{ flexGrow: 1, minWidth: 0, flex: '1 1 0' }}>
        <CardFirstRow>
          <Col style={{ width: '100%' }}>
            <ResourceTitle>{resourceTitle}</ResourceTitle>
          </Col>
        </CardFirstRow>
        <Row>
          {capacity > 0 && (
            <Col>
              <CardSecondaryText>
                <FormattedMessage {...messages.capacity} />
                {capacity}
                <FormattedMessage {...messages.people} />
              </CardSecondaryText>
            </Col>
          )}
          <Col>
            <CardSecondaryText>
              {buildingId}
              {floorName != null && `・${floorName}`}
              {floorName != null && <FormattedMessage {...messages.floor} />}
            </CardSecondaryText>
          </Col>
        </Row>
      </Col>
      {additionalInformation && thumbnailEnabled && (
        <Col style={{ flexGrow: 0 }}>
          <ListMeetingRoomThumbnail
            src={thumbnail?.url || EmptyThumbnail}
            alt={resourceTitle}
          />
        </Col>
      )}
    </ResourceRow>
  );
};

ResourceCard.propTypes = {
  resource: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onEnterRoomClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  events: PropTypes.oneOfType([PropTypes.array]).isRequired,
  additionalInformation: PropTypes.bool.isRequired,
  thumbnail: PropTypes.bool.isRequired,
};

export default ResourceCard;
