import SortingScreen from './index';

const route = [
  {
    component: SortingScreen,
    path: '/sorting',
    exact: true,
    type: 'private',
  },
];

export default route;
