export const GET_RESOURCES = 'REQUEST/GET_RESOURCES';
export const GET_VISIBLE_RESOURCES = 'REQUEST/GET_VISIBLE_RESOURCES';
export const GET_EVENTS = 'REQUEST/GET_EVENTS';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOADING_MORE = 'SET_LOADING_MORE';
export const SET_CALENDAR_EVENT = 'SET_CALENDAR_EVENT';
export const SET_MEETING = 'SET_MEETING';
export const SET_NEW_CALENDAR_EVENT = 'SET_NEW_CALENDAR_EVENT';
export const SET_FILTERING_STATUS = 'SET_FILTERING_STATUS';
export const SET_FILTERING_TIME = 'SET_FILTERING_TIME';
export const SET_FILTERED_RESOURCES = 'SET_FILTERED_RESOURCES';
export const SET_FILTERED_EVENTS = 'SET_FILTERED_EVENTS';
export const SET_TEMPORARY_EVENT = 'SET_TEMPORARY_EVENT';
export const GET_BUILDINGS = 'GET_BUILDINGS';
export const SET_SELECTED_BUILDING = 'SET_SELECTED_BUILDING';
export const GET_RESOURCE_PROVIDERS = 'GET_RESOURCE_PROVIDERS';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const SET_SEARCH_DIALOG_OPEN = 'SET_SEARCH_DIALOG_OPEN';
export const SET_SEARCH_DIALOG_TYPE = 'SET_SEARCH_DIALOG_TYPE';
export const SET_AVAILABLE_TIME_SLOTS = 'SET_AVAILABLE_TIME_SLOTS';
export const SET_FILE_UPLOAD_SCREEN = 'SET_FILE_UPLOAD_SCREEN';
export const SET_UPLOAD_ERROR_SCREEN = 'SET_UPLOAD_ERROR_SCREEN';
export const SET_UPLOAD_SUCCESS_SCREEN = 'SET_UPLOAD_SUCCESS_SCREEN';
export const SET_IS_UPLOADING_SCREEN = 'SET_IS_UPLOADING_SCREEN';
export const SET_IS_UPLOADING_INFO_SCREEN = 'SET_IS_UPLOADING_INFO_SCREEN';
export const SET_SETTING_RESOURCE_APP = 'SET_SETTING_RESOURCE_APP';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES';
export const SET_CALENDAR_SCROLL_LOCATION = 'SET_CALENDAR_SCROLL_LOCATION';
export const SET_AVAILABLE_INSTANT_MEETING_CREATE =
  'SET_AVAILABLE_INSTANT_MEETING_CREATE';
