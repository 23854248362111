import { combineReducers } from '@reduxjs/toolkit';
import { loadingBarReducer } from 'react-redux-loading-bar';

import resourceEventReducer from 'containers/Home/reducer';
import calendarReducer from 'containers/Resource/reducer';
import signInReducer from 'containers/SignIn/reducer';
import localeReducer from './reducers/localeReducer';

export const reducers = combineReducers({
  locale: localeReducer,
  resourceEvent: resourceEventReducer,
  calendar: calendarReducer,
  loadingBar: loadingBarReducer,
  signIn: signInReducer,
});
