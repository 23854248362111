import React from 'react';
import { PropTypes } from 'prop-types';

import SortingContainer from 'containers/Sorting';
import Header from 'components/Header';

function SortingScreen({ setCurrentUser, ...props }) {
  return (
    <>
      <Header setCurrentUser={setCurrentUser} />
      <SortingContainer {...props} />
    </>
  );
}

SortingScreen.propTypes = {
  setCurrentUser: PropTypes.func.isRequired,
};

export default SortingScreen;
