import { React, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from '@d-lighted/design-system';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { Player } from '@lottiefiles/react-lottie-player';
import { HeaderLeft, Icon } from 'components/Header/components/styled';
import RoomsLogoIcon from 'images/img_logo_rooms.svg';
import * as color from 'constants/colors';
import { setCalendarScrollLocation } from 'containers/Home/actions';
import {
  ExitFullScreenButton,
  ExitFullScreenButtonWrapper,
  FullscreenHeaderWrapper,
  TagIndicator,
  TagText,
  TagWrapper,
  SignageModeLogo,
  CountdownWrapper,
} from './styled';
import messages from '../messages';
import countdown from '../../../animations/countdown.json';

gsap.registerPlugin(ScrollToPlugin);
const FullScreenHeader = ({
  fullscreenState,
  partialHeader,
  filteringTime,
  companyLogo,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showCountdown, setShowCountdown] = useState(true);
  const calendar = document.querySelector('.rbc-time-content');
  const cellWidth = 172;
  const scrollLocationState = useSelector(
    state => state.resourceEvent?.calendarScrollLocation,
  );
  const handleExitFullscreenMode = () => {
    if (fullscreenState) {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement
      ) {
        const exitFullScreen =
          document.exitFullscreen ||
          document.mozCancelFullScreen ||
          document.webkitExitFullscreen ||
          document.msExitFullscreen;
        exitFullScreen.call(document);
      }
    }
  };
  const checkIfShowCountdown = () => {
    const calendarColumn = document.querySelectorAll('.rbc-time-column');
    if ((calendarColumn.length - 1) * 172 < window.innerWidth) {
      setShowCountdown(false);
    }
  };
  const playerRef = useRef(null);
  const handleEventPlayer = e => {
    if (!calendar) {
      return;
    }
    checkIfShowCountdown();
    if (playerRef.current && e === 'load') {
      // NOTE: autoplayだとcalendarの再描画によるunmount時にメモリリークが発生するため
      playerRef.current.play();
    } else if (e === 'loop') {
      const shouldResetScrollLocation =
        calendar.scrollLeft + calendar.clientWidth >= calendar.scrollWidth;
      const scrollLocation = shouldResetScrollLocation
        ? 0
        : scrollLocationState;
      gsap.to(calendar, {
        duration: 0.1,
        ease: 'power4.out',
        scrollTo: {
          // eslint-disable-next-line
          x:
            cellWidth *
            scrollLocation *
            Math.floor(calendar.offsetWidth / cellWidth),
          offsetY: 4,
        },
      });
      dispatch(setCalendarScrollLocation(scrollLocation + 1));
    }
  };
  return (
    <FullscreenHeaderWrapper>
      <HeaderLeft>
        {companyLogo ? (
          <SignageModeLogo src={companyLogo} />
        ) : (
          <Icon
            src={RoomsLogoIcon}
            onClick={() => history.push('/')}
            fullscreenState={fullscreenState}
          />
        )}
        {partialHeader || (
          <Row
            ml="30px"
            style={{
              flexDirection: 'row',
            }}
          >
            {!filteringTime && (
              <>
                <TagWrapper
                  bg={color.white}
                  border={`solid 0.5px ${color.tagActiveBorder}`}
                  padding="8px 16px"
                >
                  <TagText fullscreenState={fullscreenState}>
                    <FormattedMessage {...messages.vacancy} />
                  </TagText>
                </TagWrapper>
                <TagWrapper
                  border={`solid 0.5px ${color.busyBGGray}`}
                  bg={color.busyBGGray}
                  ml="12px"
                  padding="8px 16px"
                >
                  <TagText fullscreenState={fullscreenState}>
                    <FormattedMessage {...messages.inUse} />
                  </TagText>
                </TagWrapper>
              </>
            )}
            <TagWrapper ml="30px">
              <TagIndicator bg={color.doneEventGray} />
              <TagText fullscreenState={fullscreenState}>
                <FormattedMessage {...messages.checkedOut} />
              </TagText>
            </TagWrapper>
            <TagWrapper ml="24px">
              <TagIndicator
                bg={color.busyEvent}
                border={`solid 0.5px ${color.busyEvent}`}
              />
              <TagText fullscreenState={fullscreenState}>
                <FormattedMessage {...messages.inAMeeting} />
              </TagText>
            </TagWrapper>
            <TagWrapper ml="30px">
              <TagIndicator
                bg={color.incomingEvent}
                border={`solid 0.5px ${color.incomingEvent}`}
              />
              <TagText fullscreenState={fullscreenState}>
                <FormattedMessage {...messages.reserved} />
              </TagText>
            </TagWrapper>
          </Row>
        )}
      </HeaderLeft>
      {showCountdown && (
        <CountdownWrapper>
          <Player
            lottieRef={ref => {
              playerRef.current = ref;
            }}
            src={countdown}
            width={23}
            height={23}
            loop
            speed={1}
            onEvent={handleEventPlayer}
          />
        </CountdownWrapper>
      )}
      <ExitFullScreenButtonWrapper>
        <ExitFullScreenButton onClick={() => handleExitFullscreenMode()}>
          <FormattedMessage {...messages.endFullScreen} />
        </ExitFullScreenButton>
      </ExitFullScreenButtonWrapper>
    </FullscreenHeaderWrapper>
  );
};

FullScreenHeader.defaultProps = {
  fullscreenState: false,
  partialHeader: false,
  filteringTime: null,
  companyLogo: null,
};

FullScreenHeader.propTypes = {
  fullscreenState: PropTypes.bool,
  partialHeader: PropTypes.bool,
  filteringTime: PropTypes.number,
  companyLogo: PropTypes.string,
};

export default FullScreenHeader;
