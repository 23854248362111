/*
  The commented sections in this file are not required in current version and may be required in the next version
*/
import React from 'react';
import { Label } from '@d-lighted/design-system';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import * as colors from 'constants/colors';
import IconTimes from 'components/Icons/IconTimes';
import { useCurrentUser, utils } from 'hooks/useCurrentUser';
import SortingMethods from './SortingMethods';
import messages from '../messages';
import {
  CustomSearchModal,
  ConfirmationContainer,
  ConfirmationTitle,
  ChoiceRow,
} from './styled';

// TODO: This file needs to be refactored
export default function SearchDialog({
  buildings,
  setBuilding,
  filterByStatus,
  dialogType,
  handleSearch,
  searchMethod,
  searchValues,
  selectedBuilding,
  ...restProps
}) {
  const [currentUser] = useCurrentUser();

  const returnBuildings = choices => {
    return choices.map(choice => (
      <ChoiceRow
        key={choice.building_id}
        className="space_top"
        onClick={() => {
          setBuilding(choice);
          restProps.toggleModal();
        }}
        checked={selectedBuilding?.building_id === choice.building_id}
      >
        <div>{choice.building_name}</div>
      </ChoiceRow>
    ));
  };

  const renderDialogContent = () => {
    switch (dialogType) {
      case 'setting':
        return (
          <>
            <ChoiceRow className="space_top">
              <Label fontWeight={600} color={colors.darkBlue} fontSize="16px">
                {!utils.isNissanEmployee() ? (
                  <FormattedMessage
                    {...(currentUser.company.office_filtered
                      ? messages.officeTitle
                      : messages.buildingTitle)}
                  />
                ) : (
                  <FormattedMessage {...messages.meetingroomGroup} />
                )}
              </Label>
            </ChoiceRow>
            {!currentUser.company.office_filtered && (
              <ChoiceRow
                className="space_top"
                onClick={() => {
                  setBuilding({});
                  restProps.toggleModal();
                }}
                checked={selectedBuilding?.building_id == null}
              >
                <FormattedMessage {...messages.notSpecified} />
              </ChoiceRow>
            )}
            {returnBuildings(buildings)}
          </>
        );
      case 'sorting':
        return (
          <SortingMethods onSearch={handleSearch} searchValues={searchValues} />
        );
      default:
        return null;
    }
  };

  return (
    <CustomSearchModal {...restProps} width="100%" height="100%">
      <ConfirmationContainer className="pad_bot">
        <ConfirmationTitle
          fontSize="16px"
          lineHeight="19px"
          color={colors.darkBlue}
          padding="16px 0 0"
        >
          {/* <DragBar /> */}
          {dialogType === 'setting' ? (
            <FormattedMessage {...messages.settingTitle} />
          ) : (
            <FormattedMessage {...messages.searchText} />
          )}
        </ConfirmationTitle>
        <IconTimes
          fill={colors.brandBlue}
          width={14}
          height={14}
          style={{ position: 'absolute', top: '18px', right: '26px' }}
          onClick={restProps.toggleModal}
        />

        {renderDialogContent()}
      </ConfirmationContainer>
    </CustomSearchModal>
  );
}

SearchDialog.propTypes = {
  buildings: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setBuilding: PropTypes.func.isRequired,
  filterByStatus: PropTypes.func.isRequired,
  dialogType: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  searchMethod: PropTypes.string.isRequired,
  searchValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
  selectedBuilding: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
