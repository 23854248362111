import { React, useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import { FileUploader } from 'react-drag-drop-files';
import aspectRatio from 'images/aspect_ratio.svg';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useLocale } from 'hooks/useLocale';
import { usePutSignageModeLogo } from 'containers/Resource/hooks/usePutSignageModeLogo';
import {
  LogoUploadModalContainer,
  LogoUploadTopTitle,
  LogoUploadTopSubtitle,
  UploadButton,
  AspectRatioImage,
  LogoUploadButtonSubtitle,
  LogoUploadButtonBottomText,
  BlueLogoUpload,
  LogoUploadErrorMessage,
  SignageModeLogoLoaderWrapper,
} from './styled';
import LogoUploadSuccessModal from './LogoUploadSuccessModal';
import FileField from './FileField';
import messages from '../messages';

const fileTypes = ['JPG', 'PNG', 'JPEG'];

const LogoUploadModal = ({ openLogoUploadModal }) => {
  const {
    putSignageModeLogo,
    setFileUploadScreen,
    uploadErrorScreen,
    setUploadErrorScreen,
    uploadSuccessScreen,
    setUploadSuccessScreen,
  } = usePutSignageModeLogo();
  const [isUploadingScreen, setIsUploadingScreen] = useState(null);
  const [locale] = useLocale();
  const [hoverText, setHoverText] = useState();

  const handleSubmit = logo => {
    const reader = new FileReader();
    reader.onload = async function onLoad(e) {
      setIsUploadingScreen(true);
      putSignageModeLogo(e.target.result);
    };
    reader.readAsDataURL(logo);
  };

  const hoverTitleLanguage = () => {
    if (locale === 'en') {
      setHoverText('Drop image here');
    }
    if (locale === 'ja') {
      setHoverText('ここに画像をドロップしてください');
    }
  };
  const handleChange = draggedFile => {
    handleSubmit(draggedFile);
  };
  const handleCloseModal = () => {
    setFileUploadScreen(false);
    setUploadErrorScreen(false);
    setUploadSuccessScreen(false);
    setIsUploadingScreen(false);
    openLogoUploadModal(false);
  };
  useEffect(() => {
    hoverTitleLanguage();
  });
  return uploadSuccessScreen ? (
    <LogoUploadSuccessModal handleCloseModal={handleCloseModal} />
  ) : (
    <LogoUploadModalContainer className="signageModeModal">
      <LogoUploadTopTitle>
        <FormattedMessage {...messages.logoUploadTitle} />
      </LogoUploadTopTitle>
      <LogoUploadTopSubtitle>
        <FormattedMessage {...messages.logoUploadSubtitle} />
      </LogoUploadTopSubtitle>
      {uploadErrorScreen && (
        <LogoUploadErrorMessage>
          <FormattedMessage {...messages.logoUploadError} />
        </LogoUploadErrorMessage>
      )}
      {isUploadingScreen && (
        <div>
          <BlueLogoUpload>
            <FormattedMessage {...messages.logoUploadUploading} />
            <SignageModeLogoLoaderWrapper>
              <circle className="not-loaded-inner" cx="32" cy="32" r="24" />
              <circle className="loading-inner" cx="32" cy="32" r="24" />
            </SignageModeLogoLoaderWrapper>
          </BlueLogoUpload>
        </div>
      )}
      <Form
        onSubmit={handleSubmit}
        render={() => (
          <form onSubmit={handleSubmit}>
            <FileUploader
              handleChange={handleChange}
              hoverTitle={hoverText}
              types={fileTypes}
              maxSize="2"
              dropMessageStyle={{
                backgroundColor: '#cceff5',
                color: 'gray',
                height: '400px',
                marginTop: '-157px',
                opacity: '1',
                fontSize: '16px',
                width: '100%',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                border: 'dashed 2px #57c2e9',
              }}
              // eslint-disable-next-line
              children={
                // needs an empty div or it will display the default upload of the library
                isUploadingScreen ? (
                  <div />
                ) : (
                  <UploadButton className="signageModeModal" type="file">
                    <AspectRatioImage src={aspectRatio} />
                    <LogoUploadButtonSubtitle>
                      <FormattedMessage
                        {...messages.logoUploadAspectRatio}
                        values={{ br: <br /> }}
                      />
                    </LogoUploadButtonSubtitle>
                    <LogoUploadButtonBottomText>
                      <FormattedMessage
                        {...messages.logoUploadDragOption}
                        values={{ br: <br /> }}
                      />
                    </LogoUploadButtonBottomText>
                  </UploadButton>
                )
              }
            />
            <FileField name="files" />
          </form>
        )}
      />
    </LogoUploadModalContainer>
  );
};
LogoUploadModal.propTypes = {
  openLogoUploadModal: PropTypes.oneOfType([PropTypes.func]).isRequired,
};
export default LogoUploadModal;
