import LoadingBar from 'react-redux-loading-bar';
import styled, { keyframes } from 'styled-components';

import * as colors from 'constants/colors';

const colorGradient = keyframes`
  0% {
    background-color: ${colors.primaryGreen};
  }
  25% {
    background-color: ${colors.gradientGreen};
  }
  100% {
    background-color: ${colors.primaryGreen};
  }
`;

const StyledLoadingBar = styled(LoadingBar)`
  position: fixed;
  top: 0;
  z-index: 99999;
  animation: ${colorGradient} 2s linear infinite;
  height: 2px;
`;

export default StyledLoadingBar;
