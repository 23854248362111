import { PropTypes } from 'prop-types';
import Text from 'components/Texts/Text';

const Text18 = Text.withComponent('p');

Text18.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

Text18.defaultProps = {
  fontSize: 18,
  color: 'gray.deep',
};

export default Text18;
