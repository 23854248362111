import React from 'react';
import { Row, Col } from '@d-lighted/design-system';
import PropTypes from 'prop-types';

const DayHeader = ({ date, localizer }) => {
  const dayOfWeek = localizer.format(date, localizer.formats.weekdayFormat);
  const day = localizer.format(date, localizer.formats.dateFormat);
  return (
    <>
      <Row>
        <Col xs mb="10px" style={{ fontSize: '18px', fontWeight: 800 }}>
          {day}
        </Col>
      </Row>
      <Row>
        <Col xs style={{ fontSize: '14px', fontWeight: 200 }}>
          {dayOfWeek}
        </Col>
      </Row>
    </>
  );
};

DayHeader.defaultProps = {};

DayHeader.propTypes = {
  localizer: PropTypes.oneOfType([PropTypes.object]).isRequired,
  date: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default DayHeader;
