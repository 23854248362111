import React from 'react';
import { Row, Col, IconNew as Icon } from '@d-lighted/design-system';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import * as color from 'constants/colors';
import messages from '../messages';
import {
  CustomModal,
  CancelDialogButton,
  CircleIcon,
  MainStatusText,
  DialogContainer,
} from './styled';

function BookingDeleteSuccessDialog({ onButtonClick, ...props }) {
  return (
    <CustomModal {...props} width="336px" pb="20px">
      <DialogContainer>
        <CircleIcon success>
          <Icon className="material-icons" color={color.white} fontSize="48px">
            check
          </Icon>
        </CircleIcon>
        <Row width="100%" mt="29px">
          <Col width="100%" pl="25px" pr="25px">
            <MainStatusText>
              <FormattedMessage {...messages.deleteEventSuccessMsg} />
            </MainStatusText>
          </Col>
        </Row>
        <Row mt="21px" width="100%">
          <Col pl="25px" pr="25px" width="100%">
            <CancelDialogButton outlined onClick={onButtonClick}>
              <FormattedMessage {...messages.return} />
            </CancelDialogButton>
          </Col>
        </Row>
      </DialogContainer>
    </CustomModal>
  );
}

BookingDeleteSuccessDialog.propTypes = {
  onButtonClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
};

export default BookingDeleteSuccessDialog;
