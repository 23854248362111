import React, { useState, useEffect, useRef } from 'react';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { Col, Loader } from '@d-lighted/design-system';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';
import DetectRTC from 'detectrtc';
import humps from 'lodash-humps';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { LoaderContainer } from 'containers/Home/components/styled';
import MobileFooter from 'components/Footer/Mobile_Footer';
import EventCreateDialog from 'components/Mobile_BigCalendar/components/EventCreateDialog';
import BookingSuccessDialog from 'components/Mobile_BigCalendar/components/BookingSuccessDialog';
import BookingErrorDialog from 'components/Mobile_BigCalendar/components/BookingErrorDialog';
import {
  getCurrentUser,
  getQRCodeAllowed,
  isNissanEmployee,
  redirectToReceptionist,
  isRedirectToOnetimeAppointment,
} from 'utils/userUtils';
import * as commonUtil from 'utils/commonUtils';
import {
  setSearchDialogOpen,
  setSearchDialogType,
  setAvailableTimeSlots,
} from 'containers/Home/actions';
import { useCalendarResource } from 'containers/Resource/hooks/useCalendarResource';
import useIntersection from 'hooks/useIntersection';
import useValueRef from 'hooks/useValueRef';
import ResourceCard from './components/ResourceCard';
import FilteredDayCard from './components/FilteredDayCard';
import SearchDialog from './components/SearchDialog';
import ConfirmationDialog from './components/ConfirmationDialog';
import QRScannerDialog from './components/QRScannerDialog';
import QRSuccessDialog from './components/QRSuccessDialog';
import QRErrorDialog from './components/QRErrorDialog';
import messages from './messages';
import {
  useFilteringStatus,
  STATUS_CONSTANTS,
} from '../../hooks/useFilteringStatus';
import {
  SettingsIcon,
  TitleRow,
  MainContentsWrapper,
  CenteredCol,
  SortingTitle,
  SearchLabel,
  EmptySearchResultWrapper,
  MoreLoaderWrapper,
} from './components/styled';

// This file contains some mock data and text
const ResourcesList = ({
  resources,
  events,
  getCalendarEventDetails,
  calendarEvent,
  postCheckIn,
  putCheckOut,
  postInstantMeeting,
  fetchBuildings,
  buildings,
  setBuilding,
  selectedBuilding,
  setDate,
  isLoading,
  searchTimeSlots,
  availableTimeSlots,
  fetchResourceAndEvent,
  updateResourceAndEvent,
  currentPage,
  totalPages,
  isLoadingMore,
  isAvailableInstantMeetingCreate,
  settingResourceApp,
  fetchSettingResourceApp,
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [QRScannerOpen, setQRScannerOpen] = useState(false);
  const [qrSuccessDialogOpen, setQRSuccessDialogOpen] = useState(false);
  const [qrErrorDialogOpen, setQRErrorDialogOpen] = useState(false);
  const [eventCreateDialogOpen, setEventCreateDialogOpen] = useState(false);
  const [bookingSuccessDialogOpen, setBookingSuccessDialogOpen] = useState(
    false,
  );
  const [bookingErrorDialogOpen, setBookingErrorDialogOpen] = useState(false);
  const [eventErrorMessage, setEventErrorMessage] = useState('');
  const [qrErrorMessage, setQRErrorMessage] = useState(null);
  const [filteringStatus, setFilteringStatus] = useFilteringStatus();
  const [isFetching, setFetching] = useState(true);
  const [fetchingType, setFetchingType] = useState('default');
  const [statusCode, setStatusCode] = useState(null);
  const [meetingStatus, setMeetingStatus] = useState('');
  const [resUid, setResUid] = useState(null);
  const [isNFCInteraction, setIsNFCInteraction] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({});
  const [selectedFormValues, setSelectedFormValues] = useState({
    methods: 'allRooms',
  });

  const searchDialogOpen = useSelector(
    state => state.resourceEvent?.searchDialogOpen,
  );
  const searchDialogType = useSelector(
    state => state.resourceEvent?.searchDialogType,
  );

  const { postCalendarEvent } = useCalendarResource();

  const currentUser = getCurrentUser();
  const camelizedUser = humps(currentUser);
  const { timeConvention, officeFiltered } = camelizedUser?.company;
  const timeFormat = commonUtil.getTimeFormat(timeConvention);
  const qrCodeAllowed = getQRCodeAllowed();

  const toggleQRScannerModal = () => {
    setQRScannerOpen(!QRScannerOpen);
  };
  const toggleConfirmationDialogOpen = () =>
    setConfirmationDialogOpen(!confirmationDialogOpen);
  const toggleQRSuccessDialogOpen = () =>
    setQRSuccessDialogOpen(!qrSuccessDialogOpen);
  const toggleQRErrorDialogOpen = () =>
    setQRErrorDialogOpen(!qrErrorDialogOpen);
  const toggleSearchDialogOpen = () =>
    dispatch(setSearchDialogOpen(!searchDialogOpen));
  const toggleEventCreateDialogOpen = () =>
    setEventCreateDialogOpen(!eventCreateDialogOpen);
  const toggleBookingSuccessDialogOpen = () => {
    return setBookingSuccessDialogOpen(!bookingSuccessDialogOpen);
  };
  const toggleBookingErrorDialogOpen = () =>
    setBookingErrorDialogOpen(!bookingErrorDialogOpen);

  const openSearchDialog = dialogType => {
    dispatch(setSearchDialogType(dialogType));
    dispatch(setSearchDialogOpen(true));
  };

  useEffect(() => {
    fetchSettingResourceApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResult = (result, message, code) => {
    setFetching(false);
    if (result === 'success') {
      setStatusCode(code);
    }
    if (result === 'error' || result === 'empty') {
      setConfirmationDialogOpen(false);
      setQRErrorMessage(message);
      setQRErrorDialogOpen(true);
    }
  };

  const removeParamOrState = () => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has('resource_uid')) {
        queryParams.delete('resource_uid');
        if (queryParams.has('media_type')) queryParams.delete('media_type');
        history.replace({
          search: queryParams.toString(),
        });
      }
    }
    if (location.state && location.state?.path) {
      if (location.state?.path?.includes('?resource_uid=')) {
        const state = { ...location.state };
        delete state.path;
        history.replace({ state });
      }
    }
  };

  const handleScan = data => {
    if (data) {
      if (QRScannerOpen) setQRScannerOpen(false);
      const url = new URL(data);
      const params = url.searchParams;
      const resourceUid = params.get('resource_uid');
      const mediaType = params.get('media_type');
      if (resourceUid) {
        setResUid(resourceUid);
        setIsNFCInteraction(mediaType && mediaType === 'nfc');
        setFetching(true);
        setFetchingType('default');
        setConfirmationDialogOpen(true);
        getCalendarEventDetails(resourceUid, handleResult);

        removeParamOrState();
      } else {
        setQRErrorMessage(messages.incorrectQr);
        setQRErrorDialogOpen(true);
      }
    }
  };

  const handleError = () => {
    setQRScannerOpen(false);

    DetectRTC.load(() => {
      setQRErrorMessage(
        !DetectRTC.isWebsiteHasWebcamPermissions
          ? messages.qrPermissionError
          : messages.qrGeneralError,
      );
      setQRErrorDialogOpen(true);
    });
  };

  const handleOtherResult = (result, message) => {
    setFetching(false);
    setConfirmationDialogOpen(false);
    if (result === 'success') setQRSuccessDialogOpen(!qrSuccessDialogOpen);
    if (result === 'error') {
      setQRErrorMessage(message);
      setQRErrorDialogOpen(true);
    }
  };

  const handleConfirmation = (status, values) => {
    const mediaType = isNFCInteraction ? 'nfc' : 'qr';
    setMeetingStatus(status);
    if (status === 'enter') {
      const { id, start, end, repeatId } = calendarEvent?.calendarEvent;
      postCheckIn(
        {
          event_uid: id,
          begin_at: start,
          end_at: end,
          repeat_id: repeatId,
          checked_in_with: mediaType,
        },
        handleOtherResult,
        resUid,
      );
      setFetchingType('enter');
    }
    if (status === 'exit') {
      const { uid } = calendarEvent?.meeting;
      putCheckOut(
        {
          uid,
          checked_out_with: mediaType,
        },
        handleOtherResult,
        resUid,
      );
      setFetchingType(status);
    }
    if (status === 'instantMeet') {
      const { name, time } = values;
      postInstantMeeting(
        {
          title: name,
          duration: `${time * 60}`,
          duration_unit: 'second',
          checked_in_with: mediaType,
        },
        handleOtherResult,
        resUid,
      );
      setFetchingType('instantMeet');
    }
    setFetching(true);
  };

  const handleFooterClick = navItem => {
    switch (navItem) {
      case 'rooms':
        setQRScannerOpen(false);
        setConfirmationDialogOpen(false);
        setQRSuccessDialogOpen(false);
        setQRErrorDialogOpen(false);
        break;
      case 'qr':
        setQRScannerOpen(true);
        break;
      default:
        break;
    }
  };

  const checkAnyDialogOpen = () => {
    return (
      QRScannerOpen ||
      confirmationDialogOpen ||
      qrSuccessDialogOpen ||
      qrErrorDialogOpen
    );
  };

  const getEventsOfRoom = resource => {
    return events.filter(event => {
      return resource.resourceId === event.resourceId;
    });
  };

  function calculateAvailability(resource, status) {
    const eventsInRoom = getEventsOfRoom(resource);
    if (status === STATUS_CONSTANTS.VACANT) {
      return !eventsInRoom.some(
        event => event.checkedInAt && !event.checkedOutAt,
      );
    }

    return true;
  }

  const filterRoomsByStatusOfUse = status => {
    fetchResourceAndEvent();
    setFilteringStatus(status);
    dispatch(setSearchDialogOpen(false));
  };

  const handleSearchResult = result => {
    if ((result === 'success' || result === 'empty') && searchDialogOpen)
      dispatch(setSearchDialogOpen(false));
    // if (result === 'error') return null;
  };

  const callSearchApi = searchValues => {
    const {
      duration,
      meetingTimes,
      desiredDate,
      holidays,
      minute,
    } = searchValues;
    searchTimeSlots(
      {
        building: !isEmpty(selectedBuilding)
          ? selectedBuilding.building_id
          : '',
        duration: duration !== 'other' ? duration : minute?.value,
        timeStart: meetingTimes?.openingTime,
        timeEnd: meetingTimes?.closingTime,
        dateStart: desiredDate[0],
        dateEnd: desiredDate[1],
        holidays,
      },
      handleSearchResult,
    );
  };

  const handleSearch = values => {
    setSelectedFormValues({ ...values });

    switch (values?.methods) {
      case 'allRooms':
      default:
        filterRoomsByStatusOfUse(STATUS_CONSTANTS.ALL);
        break;
      case 'available':
        filterRoomsByStatusOfUse(STATUS_CONSTANTS.VACANT);
        break;
      case 'period':
        setFilteringStatus(STATUS_CONSTANTS.PERIOD);
        callSearchApi(values);
    }
  };

  const handleSelectTimeSlot = slotValues => {
    slotValues.resourceId = slotValues.resource.uid;
    setSelectedTimeSlot(slotValues);
    setEventCreateDialogOpen(true);
  };

  const handleReserveResult = (result, message) => {
    if (result === 'success') {
      setBookingSuccessDialogOpen(true);

      const { date, index } = selectedTimeSlot;
      const updatedTimeSlots = availableTimeSlots.map(daySlot => {
        if (date === daySlot.date) {
          const filteredTimeSlots = daySlot.timeslots.filter(
            (timeSlot, slotIndex) => index !== slotIndex,
          );
          return { date: daySlot.date, timeslots: [...filteredTimeSlots] };
        }
        return daySlot;
      });
      dispatch(setAvailableTimeSlots(updatedTimeSlots));
    }
    if (result === 'error') {
      setBookingErrorDialogOpen(true);
      setEventErrorMessage(message);
    }
  };

  const handleConfirmEventReserve = values => {
    const { start, end, resource } = selectedTimeSlot;
    if (isRedirectToOnetimeAppointment()) {
      redirectToReceptionist({
        start,
        end,
        title: values.title,
        notes: null,
        resource_id: resource.email,
      });
    } else {
      postCalendarEvent(
        {
          start,
          end,
          name: values.title,
        },
        resource.uid,
        handleReserveResult,
      );
    }
    setEventCreateDialogOpen(false);
  };

  const handleCompleteButtonClick = () => {
    setSelectedFormValues({ ...selectedFormValues, methods: 'allRooms' });
    setFilteringStatus(STATUS_CONSTANTS.ALL);
    toggleBookingSuccessDialogOpen();
  };

  const handleReturnButtonClick = status => {
    if (status === 'success') {
      setBookingSuccessDialogOpen(false);

      if (
        availableTimeSlots.length > 0
          ? !availableTimeSlots.some(day => day?.timeslots.length > 0)
          : true
      ) {
        handleCompleteButtonClick();
      }
    } else setBookingErrorDialogOpen(false);
  };

  const noResourcesInLabel = () => {
    let message = messages.noResourcesInSelectedBuilding;

    if (officeFiltered) message = messages.noResourcesInSelectedOffice;
    if (isNissanEmployee())
      message = messages.noResourcesInSelectedMeetingroomGroup;

    return <FormattedMessage {...message} />;
  };

  const noResourcesLabel = () => {
    let message = messages.noResourcesNoBuilding;

    if (officeFiltered) message = messages.noResourcesNoOffice;
    if (isNissanEmployee()) message = messages.noResourcesNoMeetingroomGroup;

    return <FormattedMessage {...message} />;
  };

  const mainContentsRef = useRef(null);
  const target = () => {
    const elements = mainContentsRef.current
      ? Array.from(mainContentsRef.current.children)
      : null;
    return elements ? elements[elements.length - 1] : null;
  };
  const handleIntersection = entries => {
    entries.forEach(entry => {
      if (!isLoadingMore && entry.isIntersecting && currentPage < totalPages) {
        fetchResourceAndEvent(currentPage + 1);
      }
    });
  };
  useIntersection(target(), null, handleIntersection);

  const renderResourceCardList = () => {
    const resourceCardList = resources.map(resource => {
      if (!calculateAvailability(resource, filteringStatus)) return null;
      return (
        <ResourceCard
          key={resource.resourceId}
          resource={resource}
          events={getEventsOfRoom(resource)}
          onEnterRoomClick={toggleQRScannerModal}
          additionalInformation={settingResourceApp?.enableCustomFields}
          thumbnail={settingResourceApp?.enableThumbnail}
        />
      );
    });
    const noResourceCardMessage = (
      <EmptySearchResultWrapper>
        {selectedBuilding?.building_id != null
          ? noResourcesInLabel()
          : noResourcesLabel()}
      </EmptySearchResultWrapper>
    );
    return resources.length > 0 ? resourceCardList : noResourceCardMessage;
  };

  const renderFilteredContents = () => {
    return availableTimeSlots.length ? (
      availableTimeSlots.map(day => (
        <FilteredDayCard
          key={day.date}
          availableDate={day}
          onReserveTimeSlot={handleSelectTimeSlot}
        />
      ))
    ) : (
      <EmptySearchResultWrapper>
        <FormattedMessage {...messages.emptySearchResult} />
      </EmptySearchResultWrapper>
    );
  };

  // eslint-disable-next-line consistent-return
  const renderTitleText = () => {
    switch (filteringStatus) {
      case STATUS_CONSTANTS.ALL:
        return <FormattedMessage {...messages.allRooms} />;
      case STATUS_CONSTANTS.VACANT:
        return <FormattedMessage {...messages.availableNow} />;
      case STATUS_CONSTANTS.PERIOD:
        // eslint-disable-next-line no-case-declarations
        const { duration, minute } = selectedFormValues;
        return (
          !isEmpty(availableTimeSlots) &&
          `条件: ${
            duration !== 'other' ? `${duration}分` : minute?.label
          }枠, ${moment(availableTimeSlots[0].date).format('MM/DD')}~${moment(
            availableTimeSlots[availableTimeSlots?.length - 1].date,
          ).format('MM/DD')}`
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setDate(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (buildings.length === 0) {
      fetchBuildings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const path = window.location.href;
    if (path.includes('?resource_uid=')) handleScan(path);
    else if (location.state && location.state?.path) {
      if (location.state?.path?.includes('?resource_uid='))
        handleScan(location.state.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedFormValues?.methods === 'period') {
      callSearchApi?.(selectedFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuilding]);

  const currentPageRef = useValueRef(currentPage);
  useEffect(() => {
    const interval = setInterval(() => {
      updateResourceAndEvent(currentPageRef.current);
    }, 300000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <>
      <TitleRow>
        <Col xs overflow="hidden">
          <SearchLabel>{renderTitleText()}</SearchLabel>
        </Col>
        <CenteredCol onClick={() => openSearchDialog('sorting')}>
          <SortingTitle>
            <FormattedMessage {...messages.searchText} />
          </SortingTitle>
        </CenteredCol>
        <CenteredCol onClick={() => openSearchDialog('sorting')}>
          <SettingsIcon icon={faSlidersH} />
        </CenteredCol>
      </TitleRow>

      <MainContentsWrapper ref={mainContentsRef}>
        {selectedFormValues.methods !== 'period'
          ? renderResourceCardList()
          : renderFilteredContents()}
        {isLoadingMore && (
          <MoreLoaderWrapper>
            <Loader />
          </MoreLoaderWrapper>
        )}
      </MainContentsWrapper>

      <SearchDialog
        buildings={buildings}
        setBuilding={setBuilding}
        filterByStatus={() => {}}
        selectedBuilding={selectedBuilding}
        dialogType={searchDialogType}
        handleSearch={handleSearch}
        searchMethod={selectedFormValues.methods}
        isOpen={searchDialogOpen}
        searchValues={selectedFormValues}
        toggleModal={toggleSearchDialogOpen}
        onBackgroundClick={toggleSearchDialogOpen}
        onEscapeKeydown={toggleSearchDialogOpen}
      />

      {confirmationDialogOpen && (
        <ConfirmationDialog
          calendarEvent={calendarEvent}
          onConfirm={handleConfirmation}
          onCancel={toggleConfirmationDialogOpen}
          isFetching={isFetching}
          fetchingType={fetchingType}
          statusCode={statusCode}
          isOpen={confirmationDialogOpen}
          toggleModal={toggleConfirmationDialogOpen}
          onBackgroundClick={toggleConfirmationDialogOpen}
          onEscapeKeydown={toggleConfirmationDialogOpen}
          isSkipConfirmation={isNFCInteraction}
          isAvailableInstantMeetingCreate={isAvailableInstantMeetingCreate}
        />
      )}

      <QRScannerDialog
        handleScan={handleScan}
        handleError={handleError}
        isOpen={QRScannerOpen}
        toggleModal={toggleQRScannerModal}
        onBackgroundClick={toggleQRScannerModal}
      />

      <QRSuccessDialog
        onButtonClick={toggleQRSuccessDialogOpen}
        status={meetingStatus}
        isOpen={qrSuccessDialogOpen}
        toggleModal={toggleQRSuccessDialogOpen}
        onBackgroundClick={toggleQRSuccessDialogOpen}
        onEscapeKeydown={toggleQRSuccessDialogOpen}
      />

      <QRErrorDialog
        onButtonClick={toggleQRErrorDialogOpen}
        message={qrErrorMessage}
        isOpen={qrErrorDialogOpen}
        toggleModal={toggleQRErrorDialogOpen}
        onBackgroundClick={toggleQRErrorDialogOpen}
        onEscapeKeydown={toggleQRErrorDialogOpen}
      />

      <EventCreateDialog
        isHomeBooking
        timeSlot={selectedTimeSlot}
        onConfirm={handleConfirmEventReserve}
        timeFormat={timeFormat}
        isOpen={eventCreateDialogOpen}
        toggleModal={toggleEventCreateDialogOpen}
        onBackgroundClick={() => setEventCreateDialogOpen(false)}
        onEscapeKeydown={() => setEventCreateDialogOpen(false)}
      />

      <BookingSuccessDialog
        isHomeBooking
        onButtonClick={() => handleReturnButtonClick('success')}
        onCompleteClick={handleCompleteButtonClick}
        isOpen={bookingSuccessDialogOpen}
        toggleModal={toggleBookingSuccessDialogOpen}
        onBackgroundClick={toggleBookingSuccessDialogOpen}
        onEscapeKeydown={toggleBookingSuccessDialogOpen}
        timeSlot={selectedTimeSlot}
      />

      <BookingErrorDialog
        message={eventErrorMessage}
        onButtonClick={() => handleReturnButtonClick('error')}
        isOpen={bookingErrorDialogOpen}
        toggleModal={toggleBookingErrorDialogOpen}
        onBackgroundClick={toggleBookingErrorDialogOpen}
        onEscapeKeydown={toggleBookingErrorDialogOpen}
      />

      {!searchDialogOpen && qrCodeAllowed && (
        <MobileFooter
          handleNavItemClick={handleFooterClick}
          isAnyDialogOpen={checkAnyDialogOpen()}
        />
      )}
    </>
  );
};

ResourcesList.propTypes = {
  resources: PropTypes.oneOfType([PropTypes.array]).isRequired,
  getCalendarEventDetails: PropTypes.func.isRequired,
  calendarEvent: PropTypes.oneOfType([PropTypes.object, PropTypes.node])
    .isRequired,
  postCheckIn: PropTypes.func.isRequired,
  putCheckOut: PropTypes.func.isRequired,
  postInstantMeeting: PropTypes.func.isRequired,
  events: PropTypes.oneOfType([PropTypes.array]).isRequired,
  fetchBuildings: PropTypes.func.isRequired,
  buildings: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setBuilding: PropTypes.func.isRequired,
  selectedBuilding: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setDate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchTimeSlots: PropTypes.func.isRequired,
  availableTimeSlots: PropTypes.oneOfType([PropTypes.array]).isRequired,
  fetchResourceAndEvent: PropTypes.func.isRequired,
  updateResourceAndEvent: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  isAvailableInstantMeetingCreate: PropTypes.bool.isRequired,
  settingResourceApp: PropTypes.oneOfType([PropTypes.object]).isRequired,
  fetchSettingResourceApp: PropTypes.func.isRequired,
};

export default ResourcesList;
