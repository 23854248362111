import { defineMessages } from 'react-intl';

export default defineMessages({
  summary: {
    id: 'resourceCalendarEventLists.resourceSettingGuide.summary',
    defaultMessage: 'Please configure meeting room setting',
  },
  officeFilteredSummary: {
    id: 'resourceCalendarEventLists.resourceSettingGuide.officeFilteredSummary',
    defaultMessage: 'Please select an office',
  },
  meetingroomGroupSummary: {
    id:
      'resourceCalendarEventLists.resourceSettingGuide.meetingroomGroupSummary',
    defaultMessage: 'Please select a meeting room group',
  },
  description: {
    id: 'resourceCalendarEventLists.resourceSettingGuide.description',
    defaultMessage:
      'Meeting rooms configuration is necessary for browsing meeting rooms availabilty.',
  },
  officeFilteredDescription: {
    id:
      'resourceCalendarEventLists.resourceSettingGuide.officeFilteredDescription',
    defaultMessage: 'Please select an office to load its meetingrooms.',
  },
  meetingroomGroupDescription: {
    id:
      'resourceCalendarEventLists.resourceSettingGuide.meetingroomGroupDescription',
    defaultMessage:
      'Please select a meeting room group to load its meetingrooms.',
  },
});
