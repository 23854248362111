/* The commented sections in this file are not required in current version and may be required in the next version */
import React, { useRef, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import {
  Heading,
  Row,
  Col,
  IconButton,
  DatePicker as SmallCalendar,
} from '@d-lighted/design-system';
import Dropdown, {
  DropdownContent,
  DropdownTrigger,
} from 'react-simple-dropdown';
import 'react-simple-dropdown/styles/Dropdown.css';
import { FormattedMessage } from 'react-intl';
import BuildingSelector from 'components/BuildingSelector';
import CsvDownloadButton from 'containers/Home/components/CsvDownloadButton';
import CalendarHeaderDropdown from 'containers/Home/components/CalendarHeaderDropdown';
import DisplaySettingsButton from 'containers/Home/components/DisplaySettingsButton';
import CalendarIcon from 'images/Icons-Content-Calendar.svg';
import * as calendarNavigationTypes from 'constants/calendarNavigationTypes';
import * as color from 'constants/colors';
import { isAdmin, isNissanReceptionAdmin } from 'utils/userUtils';
import TunningIcon from 'images/tunning_icon.svg';
import SortingDialog from '../../SortingDialog';
import messages from '../messages';
import {
  MainRow,
  TagWrapper,
  TagText,
  TagIndicator,
  CalendarWrapper,
  SlotSearchButton,
  IconFrame,
  ButtonCol,
} from './styled';

export function CalendarHeader({
  date,
  onNavigate,
  children,
  filterByTime,
  downloadMeetingRoomCsv,
  filteringTime,
  partialHeader,
  buildings,
  setBuilding,
  selectedBuilding,
  editMode,
  handleEditModeChange,
  handleSaveChanges,
  isSekisuiCompanyUser,
}) {
  const dropdown = useRef(null);
  const [dropdownActive, setDropdownActive] = useState(false);
  const selectedDate = moment(date);
  const currentDate = moment().format('YYYY-MM-DD');

  const handleClickNavigateNext = () => {
    onNavigate(calendarNavigationTypes.NEXT);
  };

  const handleClickNavigatePrev = () => {
    onNavigate(calendarNavigationTypes.PREVIOUS);
  };

  const handleClickOutside = event => {
    const targetElement = event?.target;
    if (
      !targetElement?.matches('#calendar-icon-btn') &&
      !targetElement?.parentElement?.matches('#calendar-icon-btn') &&
      !targetElement?.parentElement?.closest('.dropdown__content')
    )
      setDropdownActive(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <MainRow mt={['10px', '15px']}>
        <Col col sm={3}>
          {!editMode && (
            <Row
              justifyContent="flex-start"
              alignItems="center"
              mb="4px"
              style={{ flexWrap: 'nowrap' }}
            >
              <Col col pr="1px">
                <Heading size={6} style={{ color: color.grayThin3 }}>
                  {selectedDate.format('YYYY')}
                  <FormattedMessage {...messages.year} />
                </Heading>
                <Heading size={2} style={{ color: color.darkBlue }}>
                  {selectedDate.format('MM')}/{selectedDate.format('DD')}
                </Heading>
              </Col>

              {!filteringTime && (
                <Col col display="flex" justifyContent="flex-end" px="0px">
                  <IconButton
                    mr="3px"
                    onClick={handleClickNavigatePrev}
                    disabled={selectedDate.format('YYYY-MM-DD') === currentDate}
                  >
                    <i className="fa fa-angle-left" aria-hidden="true" />
                  </IconButton>
                  <IconButton mr="15px" onClick={handleClickNavigateNext}>
                    <i className="fa fa-angle-right" aria-hidden="true" />
                  </IconButton>
                  <Col
                    col
                    display={['none', 'flex']}
                    justifyContent="flex-end"
                    pr="20px"
                  >
                    <div ref={dropdown}>
                      <Dropdown active={dropdownActive}>
                        <DropdownTrigger>
                          <IconButton
                            id="calendar-icon-btn"
                            onClick={() => {
                              setDropdownActive(!dropdownActive);
                            }}
                          >
                            <img src={CalendarIcon} alt="Favicon" />
                          </IconButton>
                        </DropdownTrigger>
                        <CalendarWrapper>
                          <DropdownContent>
                            <SmallCalendar
                              value={selectedDate.toDate()}
                              showActionButton={false}
                              highlightWeek={false}
                              onChange={value => {
                                onNavigate(calendarNavigationTypes.DATE, value);
                                setTimeout(() => setDropdownActive(false), 100);
                              }}
                            />
                          </DropdownContent>
                        </CalendarWrapper>
                      </Dropdown>
                    </div>
                  </Col>
                </Col>
              )}
            </Row>
          )}
        </Col>

        <Col col sm={9}>
          <Row
            justifyContent="flex-end"
            alignItems="center"
            style={{ flexWrap: 'nowrap' }}
          >
            {!editMode && (
              <>
                <Col col display={['none', 'flex']}>
                  <BuildingSelector
                    buildings={buildings}
                    setBuilding={setBuilding}
                    selectedBuilding={selectedBuilding}
                  />
                </Col>
                <ButtonCol col style={{ flexShrink: 1 }}>
                  {!isSekisuiCompanyUser && (
                    <SortingDialog
                      anchor={
                        <SlotSearchButton>
                          <FormattedMessage {...messages.searchSlots} />
                          <IconFrame src={TunningIcon} alt="SlotSearchButton" />
                        </SlotSearchButton>
                      }
                    />
                  )}
                </ButtonCol>
                {(isAdmin() || isNissanReceptionAdmin()) && (
                  <ButtonCol col style={{ flexShrink: 2 }}>
                    <CsvDownloadButton
                      downloadMeetingRoomCsv={downloadMeetingRoomCsv}
                    />
                  </ButtonCol>
                )}
                <ButtonCol col style={{ flexShrink: 2 }}>
                  <CalendarHeaderDropdown filterByTime={filterByTime} />
                </ButtonCol>
              </>
            )}
            <ButtonCol col style={{ flexShrink: 1 }}>
              <DisplaySettingsButton
                editMode={editMode}
                handleEditModeChange={handleEditModeChange}
                handleSaveChanges={handleSaveChanges}
              />
            </ButtonCol>
          </Row>
        </Col>
      </MainRow>

      {!editMode && (
        <>
          <Row mt="20px" width={['100%']}>
            <Col xs padding="0px">
              {children}
            </Col>
          </Row>

          {partialHeader || (
            <Row width={['100%']} mb="12px" style={{ flexDirection: 'row' }}>
              {!filteringTime && (
                <>
                  <TagWrapper
                    bg={color.white}
                    border={`solid 0.5px ${color.tagActiveBorder}`}
                    padding="8px 16px"
                  >
                    <TagText>
                      <FormattedMessage {...messages.vacancy} />
                    </TagText>
                  </TagWrapper>
                  <TagWrapper
                    border={`solid 0.5px ${color.busyBGGray}`}
                    bg={color.busyBGGray}
                    ml="12px"
                    padding="8px 16px"
                  >
                    <TagText>
                      <FormattedMessage {...messages.inUse} />
                    </TagText>
                  </TagWrapper>
                </>
              )}
              <TagWrapper ml="30px">
                <TagIndicator bg={color.doneEventGray} />
                <TagText>
                  <FormattedMessage {...messages.checkedOut} />
                </TagText>
              </TagWrapper>
              <TagWrapper ml="24px">
                <TagIndicator
                  bg={color.busyEvent}
                  border={`solid 0.5px ${color.busyEvent}`}
                />
                <TagText>
                  <FormattedMessage {...messages.inAMeeting} />
                </TagText>
              </TagWrapper>
              <TagWrapper ml="30px">
                <TagIndicator
                  bg={color.incomingEvent}
                  border={`solid 0.5px ${color.incomingEvent}`}
                />
                <TagText>
                  <FormattedMessage {...messages.reserved} />
                </TagText>
              </TagWrapper>
            </Row>
          )}
        </>
      )}
    </>
  );
}

CalendarHeader.defaultProps = {
  partialHeader: false,
  filteringTime: null,
  date: new Date(),
  children: null,
  onNavigate: () => {},
  editMode: false,
  isSekisuiCompanyUser: false,
};

CalendarHeader.propTypes = {
  filterByTime: PropTypes.func.isRequired,
  downloadMeetingRoomCsv: PropTypes.func.isRequired,
  partialHeader: PropTypes.bool,
  filteringTime: PropTypes.number,
  date: PropTypes.instanceOf(Date),
  onNavigate: PropTypes.func,
  children: PropTypes.node,
  buildings: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setBuilding: PropTypes.func.isRequired,
  selectedBuilding: PropTypes.oneOfType([PropTypes.object]).isRequired,
  editMode: PropTypes.bool,
  handleEditModeChange: PropTypes.func.isRequired,
  handleSaveChanges: PropTypes.func.isRequired,
  isSekisuiCompanyUser: PropTypes.bool,
};
