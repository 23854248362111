import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';

import HomeContainer from 'containers/Home';
import Header from 'components/Header';
import messages from './messages';

function IndexScreen({ setCurrentUser, ...props }) {
  const [fullscreenState, setFullscreenState] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight === window.screen.height) {
        setFullscreenState(true);
      } else {
        setFullscreenState(false);
      }
    };

    window.addEventListener('resize', handleResize);

    const checkF11Pressed = e => {
      if (e.key === 'F11') {
        e.preventDefault();
        if (!fullscreenState) {
          const elem = document.documentElement;
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.webkitRequestFullscreen) {
            /* Safari */
            elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) {
            /* IE11 */
            elem.msRequestFullscreen();
          }
        } else if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement
        ) {
          const exitFullScreen =
            document.exitFullscreen ||
            document.mozCancelFullScreen ||
            document.webkitExitFullscreen ||
            document.msExitFullscreen;
          exitFullScreen.call(document);
        }
      }
    };

    document.addEventListener('keydown', checkF11Pressed);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('keydown', checkF11Pressed);
    };
  }, [fullscreenState]);

  return (
    <>
      {!fullscreenState && (
        <Header
          title={<FormattedMessage {...messages.title} />}
          setCurrentUser={setCurrentUser}
          fullscreenState={fullscreenState}
        />
      )}
      <HomeContainer fullscreenState={fullscreenState} {...props} />
    </>
  );
}

IndexScreen.propTypes = {
  setCurrentUser: PropTypes.func.isRequired,
};

export default IndexScreen;
