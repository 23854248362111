import styled from 'styled-components';
import * as color from 'constants/colors';
import BaseButton from 'components/Buttons/BaseButton';
import { FadeIn } from 'animate-css-styled-components';

export const Logo = styled.img`
  height: 250px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: calc(10px + 2vmin);
  color: ${color.deepGreen};
  padding: ${props =>
    props.fullscreenState ? '20px 30px 0px' : '60px 30px 0'};
`;

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: calc(10px + 2vmin);
  color: ${color.deepGreen};
  padding: 0 16px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  align-self: center;
`;

export const CsvDownloadButtonWrapper = styled.div`
  position: relative;
  z-index: 3;
`;

export const DownloadIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  cursor: pointer;
  position: relative;
  fill: ${color.white};

  > svg {
    max-width: 20px;
    max-height: 20px;
  }
`;

export const GearButton = styled(BaseButton)`
  width: auto;
  display: flex;
  align-items: center;
  padding: 5px 20px 5px 20px;
  border-radius: 3px;
  background-color: ${color.white};
  border: 5px solid ${color.white};
  font-size: 16px;
  color: ${color.grayThin3};

  &:hover {
    border: 5px solid ${color.white};
    background: ${color.lightGray};
  }
`;

export const DropDownContentWrapper = styled(FadeIn)`
  z-index: 2;
  position: absolute;
  top: 53px;
  right: 0;
  min-width: 280px;
  background-color: ${color.white};
  border-radius: 3px;
  box-shadow: 0 10px 26px 0 rgba(63, 78, 90, 0.14);
  padding: 5px;
  box-sizing: border-box;
`;

export const DropDownItem = styled.div`
  color: ${color.black};
  padding: 10px 25px;
  transition: all 0.2s linear;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: ${color.lightGray};
  }
`;

export const IconWrapper = styled.span`
  margin-left: 10px;
`;

export const SaveButton = styled(BaseButton)`
  width: auto;
  padding: 0px 20px;
  border-radius: 3px;
  background-color: ${color.primaryLightGreen};
  border: none;
  font-size: 16px;
`;
export const DropDownItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const TooltipTextContainer = styled.div`
  line-height: 22px;
`;
export const PremiumText = styled.div`
  letter-spacing: 0.08px;
  font-size: 11px;
`;
export const LabelContainer = styled.div`
  margin-top: -3px;
`;
