import React from 'react';
import DatePicker from 'rc-calendar/lib/Picker';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import moment from 'moment';
import { PropTypes } from 'prop-types';

import './components/style.css';
import * as dateUnitTypes from 'constants/dateUnitTypes';
import { CalendarContainer } from './components/styled';

function RangeCalendarPicker({
  defaultValue,
  disabledDate,
  onOk,
  value: propValue,
  children,
}) {
  const format = 'YYYY-MM-DD';
  const now = moment();

  const calendar = (
    <CalendarContainer>
      <RangeCalendar
        type="both"
        format={format}
        showOk
        dateInputPlaceholder={['start', 'end']}
        defaultValue={
          defaultValue || [now, now.clone().add(1, dateUnitTypes.MONTHS)]
        }
        disabledDate={disabledDate}
        onOk={finalValue => onOk(finalValue)}
        showDateInput={false}
      />
    </CalendarContainer>
  );

  return (
    <DatePicker calendar={calendar} value={propValue}>
      {({ value }) => {
        return (
          <div>
            <input
              readOnly
              className="ant-calendar-picker-input ant-input"
              value={(value && value.format(format)) || ''}
              style={{ display: 'none' }}
            />
            {children}
          </div>
        );
      }}
    </DatePicker>
  );
}

RangeCalendarPicker.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.array]).isRequired,
  disabledDate: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  value: PropTypes.instanceOf(Date),
};

RangeCalendarPicker.defaultProps = {
  value: null,
};

export default RangeCalendarPicker;
