import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import Header from 'components/Header';
import ResourceContainer from 'containers/Resource';
import messages from './messages';

function ResourceScreen({ setCurrentUser, ...props }) {
  const location = useLocation();

  return (
    <>
      <Header
        title={<FormattedMessage {...messages.title} />}
        setCurrentUser={setCurrentUser}
      />
      <ResourceContainer {...location.state} {...props} />
    </>
  );
}

ResourceScreen.propTypes = {
  setCurrentUser: PropTypes.func.isRequired,
};

export default ResourceScreen;
