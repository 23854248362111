import React from 'react';
import logo from 'images/img_logo_rooms.svg';
import {
  Header,
  FlexWrapper,
  HeaderLeft,
  Content,
  Logo,
} from './components/styled';

function SignInHeader() {
  return (
    <Header>
      <FlexWrapper>
        <HeaderLeft>
          <Content>
            <Logo src={logo} alt="RECEPTIONIST" />
          </Content>
        </HeaderLeft>
      </FlexWrapper>
    </Header>
  );
}

export default SignInHeader;
