import styled from 'styled-components';
import * as color from 'constants/colors';

export const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  padding: 0 20px;
  background-color: ${color.white};
  box-sizing: border-box;
  border-bottom: 1px solid ${color.grayShade};
  z-index: 2;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLeft = styled.div`
  margin-top: 7px;
  justify-self: flex-start;
`;

export const Content = styled.span`
  line-height: 80px;
  color: ${color.white};
  padding-left: 5px;
  margin: 0;
  cursor: pointer;
`;

export const Logo = styled.img`
  margin-left: -1px;
  margin-top: 6px;
`;
