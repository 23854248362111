import styled from 'styled-components';
import {
  width,
  minWidth,
  height,
  color,
  space,
  borders,
  position,
  textAlign,
  fontSize,
  borderRadius,
} from 'styled-system';

export const BaseButton = styled.button`
  padding: ${props =>
    props.isIconSvgPresent || props.isIconPresent
      ? '9px 20px 12px'
      : '9px 0 12px'};
  box-sizing: border-box;
  cursor: ${props => (props.submitFailed === true ? 'no-drop' : 'pointer')};
  padding-left: ${props => (props.isIconPresent ? '20px' : 0)};
  &:before {
    position: absolute;
    content: '+';
    display: ${props => (props.isIconPresent ? 'block' : 'none')};
    left: 20px;
    top: 0px;
    font-size: 30px;
    font-weight: 100;
  }
  ${position}
  ${width}
  ${minWidth}
  ${height}
  ${fontSize}
  ${color}
  ${space}
  ${borders}
  ${borderRadius}
  ${textAlign}
`;
