import SignIn from './index';

const signInRoute = [
  {
    component: SignIn,
    path: '/sign_in',
    exact: true,
    feature: 'sign_in',
    type: 'public',
  },
];

export default signInRoute;
