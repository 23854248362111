import moment from 'moment';

export function isOverlapping(events, slotInfo, allowDuplicateAllDayEvent) {
  const eventsInARoom = events.filter(event => {
    const isEventsInARoom = slotInfo.resourceId === event.resourceId;
    return allowDuplicateAllDayEvent
      ? isEventsInARoom && !event.isAllDay
      : isEventsInARoom;
  });

  return eventsInARoom.some(event => {
    return (
      (moment(slotInfo.start).isBetween(
        moment(event.start),
        moment(event.end),
        null,
        [],
      ) &&
        moment(slotInfo.start).isBefore(moment(event.end))) ||
      (moment(slotInfo.end).isBetween(
        moment(event.start),
        moment(event.end),
        null,
        [],
      ) &&
        moment(slotInfo.end).isAfter(moment(event.start))) ||
      (moment(slotInfo.start).isBefore(moment(event.start)) &&
        moment(slotInfo.end).isAfter(moment(event.end)))
    );
  });
}

export const checkRoomIsBusy = (resourceId, events) => {
  const eventsInARoom = events.filter(event => {
    return resourceId === event.resourceId;
  });
  return eventsInARoom.some(event => event.checkedInAt && !event.checkedOutAt);
};
