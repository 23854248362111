import { createReducer } from '@reduxjs/toolkit';
import { setEvents, setNewCalendarEvent, setTemporaryEvent } from './actions';

const INITIAL_STATUS = {
  calendarEvent: {},
  events: [],
  temporaryEvent: {},
};

const calendarReducer = createReducer(INITIAL_STATUS, builder => {
  builder.addCase(setNewCalendarEvent, (state, action) => {
    state.calendarEvent = action.payload;
  });
  builder.addCase(setEvents, (state, action) => {
    state.events = action.payload;
  });
  builder.addCase(setTemporaryEvent, (state, action) => {
    state.temporaryEvent = action.payload;
  });
});

export default calendarReducer;
