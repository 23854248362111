import React from 'react';
import { PropTypes } from 'prop-types';
import { MainButton } from './components/styled';

function ActionButton({ buttonText, children, onClick }) {
  const handleButtonClick = () => {
    if (onClick) onClick();
  };

  return (
    <div style={{ position: 'relative' }}>
      <MainButton onClick={handleButtonClick}>
        {children}
        {buttonText}
      </MainButton>
    </div>
  );
}

ActionButton.propTypes = {
  buttonText: PropTypes.node.isRequired,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

ActionButton.defaultProps = {
  children: null,
  onClick: null,
};

export default ActionButton;
