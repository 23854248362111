import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import Button from './components/Button';
import Item from './components/Item';
import { ItemWrapper } from './components/styled';

function DropDownButton({ buttonText, items }) {
  const [dropdown, setDropdown] = useState(false);

  const handleClickButton = () => {
    setDropdown(!dropdown);
  };

  const handleClickOutside = event => {
    const targetElement = event.target;
    if (
      !(
        targetElement?.matches('#dropdownBtn') ||
        targetElement?.parentElement?.matches('#dropdownBtn')
      )
    )
      setDropdown(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <Button onClick={handleClickButton}>{buttonText}</Button>
      {dropdown && (
        <ItemWrapper duration="0.2s">
          {items.map(item => (
            <Item key={item.id} {...item} />
          ))}
        </ItemWrapper>
      )}
    </div>
  );
}

DropDownButton.propTypes = {
  buttonText: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      onClick: PropTypes.func,
      text: PropTypes.node,
    }),
  ).isRequired,
};

export default DropDownButton;
