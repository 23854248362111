import React, { useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import Text13 from 'components/Texts/Text13';
import Text16 from 'components/Texts/Text16';

import * as colors from 'constants/colors';
import messages from '../messages';

export default function EventContent({ event, isSumitomolifeCompanyUser }) {
  const element = useRef(null);
  const isFullScreen = // eslint-disable-next-line
    window.innerWidth === screen.width && window.innerHeight === screen.height;
  const isPrivate =
    event.visibility === 'private' ||
    event.visibility === 'confidential' ||
    event.visibility === 'PRIVATE'; // NOTE: Garoonの場合

  useEffect(() => {
    if (event.checkedInAt && !event.checkedOutAt) {
      const textNode = element.current;
      const eventNode = textNode.parentNode.parentNode;
      const timeline = textNode.parentNode.parentNode.parentNode.parentNode.querySelector(
        '.rbc-current-time-indicator',
      );
      const now = moment();
      const eventEnd = moment(event.end);

      if (
        now.isAfter(eventEnd) &&
        eventNode?.offsetTop + eventNode?.offsetHeight < timeline?.offsetTop
      ) {
        if (textNode.parentNode.style.height === '') {
          // We reduce by 16 by padding to fit proportions
          textNode.parentNode.style.overflow = 'hidden';
          textNode.parentNode.style.height = `${
            eventNode?.offsetHeight - 16
          }px`;
        }

        // We increase by 16 by faking padding in background color
        const offset = textNode.parentNode.offsetHeight + 16;

        eventNode.style.height = `${
          timeline?.offsetTop - eventNode?.offsetTop
        }px`;

        eventNode.style.backgroundColor = null;

        // eslint-disable-next-line max-len
        eventNode.style.background = `linear-gradient(${colors.busyEvent} 0%, ${colors.busyEvent} ${offset}px, ${colors.busyEvent}66 ${offset}px, ${colors.busyEvent}66 100%)`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <div>
      {isFullScreen ? (
        <Text16
          ref={element}
          key={event?.name}
          style={{
            marginTop: '0',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {isPrivate &&
            !isSumitomolifeCompanyUser && ( // 住友生命さんの非公開予定はなにも表示しない
              <FormattedMessage {...messages.privateEvent} />
            )}
          {!isPrivate && event.name}
        </Text16>
      ) : (
        <Text13
          ref={element}
          key={event?.name}
          style={{
            marginTop: '0',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {isPrivate &&
            !isSumitomolifeCompanyUser && ( // 住友生命さんの非公開予定はなにも表示しない
              <FormattedMessage {...messages.privateEvent} />
            )}
          {!isPrivate && event.name}
        </Text13>
      )}
    </div>
  );
}

EventContent.propTypes = {
  event: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isSumitomolifeCompanyUser: PropTypes.bool.isRequired,
};
