import React from 'react';
import { Row, Col, IconNew as Icon } from '@d-lighted/design-system';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import * as color from 'constants/colors';
import messages from '../messages';
import {
  CustomModal,
  CancelDialogButton,
  CircleIcon,
  MainStatusText,
  SubText,
  DialogContainer,
} from './styled';

function BookingErrorDialog({ message, onButtonClick, ...props }) {
  return (
    <CustomModal {...props} width="90vw" pb="12px">
      <DialogContainer>
        <CircleIcon error>
          <Icon className="material-icons" color={color.white} fontSize="48px">
            close
          </Icon>
        </CircleIcon>
        <Row width="100%" mt="28px">
          <Col width="100%" pl="25px" pr="25px">
            <MainStatusText>
              <FormattedMessage {...messages.errorDialogTitle} />
            </MainStatusText>
          </Col>
        </Row>
        <Row width="100%" mt="8px">
          <Col width="100%" pl="25px" pr="25px">
            <SubText>
              {message || <FormattedMessage {...messages.errMsg} />}
            </SubText>
          </Col>
        </Row>
        <Row mt="21px" width="100%">
          <Col pl="25px" pr="25px" width="100%">
            <CancelDialogButton outlined onClick={onButtonClick}>
              <FormattedMessage {...messages.return} />
            </CancelDialogButton>
          </Col>
        </Row>
      </DialogContainer>
    </CustomModal>
  );
}

BookingErrorDialog.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onButtonClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
};

BookingErrorDialog.defaultProps = {
  message: undefined,
};

export default BookingErrorDialog;
