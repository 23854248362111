import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const NotInUseOption = styled.a`
  background-color: #ffffff;
  border-radius: 3px;
  border: solid 1px #e7e9ea;
  color: #484e62;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 25px;
  padding: 12px 9px 12px 10px;
  position: absolute;
  right: 5px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #e7e9ea;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 16px;
`;

const OptionTextContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const OptionText = styled.span`
  color: #68878d;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: normal;
  margin-bottom: 16px;
`;

const OptionTitle = styled.span`
  color: #484e62;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  height: 26px;
`;

const OptionSubtitle = styled.span`
  color: #68878d;
  font-size: 13px;
  height: 16px;
  margin-top: 4px;
`;

const BlueText = styled.p`
  background-color: #57c2e91a;
  color: #00aed4;
  font-size: 10px;
  margin-left: 8px;
  padding: 5px;
`;

function Option({
  option1Title,
  option1Subtitle,
  option1Link,
  option2Title,
  option2Subtitle,
  option2Link,
  option1ComingSoon,
}) {
  return (
    <div>
      {option1Title !== undefined && (
        <OptionText>
          <FormattedMessage {...messages.option} />
        </OptionText>
      )}
      {option1Title !== undefined && (
        <BottomContainer>
          <OptionTextContainer>
            <OptionTitle>
              <FormattedMessage {...option1Title} />
              {option1ComingSoon && (
                <BlueText>
                  <FormattedMessage {...messages.comingSoon} />
                </BlueText>
              )}
            </OptionTitle>
            <OptionSubtitle>
              <FormattedMessage {...option1Subtitle} />
            </OptionSubtitle>
          </OptionTextContainer>
          <NotInUseOption href={option1Link}>
            <FormattedMessage {...messages.optionButton} />
          </NotInUseOption>
        </BottomContainer>
      )}

      {option2Title !== undefined && (
        <BottomContainer>
          <OptionTextContainer>
            <OptionTitle>
              <FormattedMessage {...option2Title} />
            </OptionTitle>
            <OptionSubtitle>
              <FormattedMessage {...option2Subtitle} />
            </OptionSubtitle>
          </OptionTextContainer>
          <NotInUseOption href={option2Link}>
            <FormattedMessage {...messages.optionButton} />
          </NotInUseOption>
        </BottomContainer>
      )}
    </div>
  );
}
Option.defaultProps = {
  option1Title: undefined,
  option1Subtitle: undefined,
  option1Link: undefined,
  option2Title: undefined,
  option2Subtitle: undefined,
  option2Link: undefined,
  option1ComingSoon: false,
};
Option.propTypes = {
  option1Title: PropTypes.oneOfType([PropTypes.object]),
  option1Subtitle: PropTypes.oneOfType([PropTypes.object]),
  option1Link: PropTypes.string,
  option2Title: PropTypes.oneOfType([PropTypes.object]),
  option2Subtitle: PropTypes.oneOfType([PropTypes.object]),
  option2Link: PropTypes.string,
  option1ComingSoon: PropTypes.bool,
};

export default Option;
