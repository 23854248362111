import { defineMessages } from 'react-intl';

export default defineMessages({
  building: {
    id: 'buildingSelector.building',
    defaultMessage: 'Building',
  },
  notSelected: {
    id: 'buildingSelector.notSelected',
    defaultMessage: 'Not Selected',
  },
  title: {
    id: 'buildingSelector.title',
    defaultMessage: 'Select a building',
  },
  buildingSelect: {
    id: 'buildingSelector.buildingSelect',
    defaultMessage: 'Select',
  },
  buildingSelected: {
    id: 'buildingSelector.buildingSelected',
    defaultMessage: 'Selected',
  },
  unselect: {
    id: 'buildingSelector.unselect',
    defaultMessage: 'Unselect a building',
  },
  notSelectedWarning: {
    id: 'buildingSelector.notSelectedWarning',
    defaultMessage:
      'It may be possible to get limited resource information if you did not select a building.',
  },
  noBuilding: {
    id: 'buildingSelector.noBuilding',
    defaultMessage: 'Buildings are not found',
  },
  titleMeetingroomGroup: {
    id: 'buildingSelector.meetingroomGroup.title',
    defaultMessage: 'Select a meeting room group',
  },
  meetingroomGroup: {
    id: 'buildingSelector.meetingroomGroup',
    defaultMessage: 'Meeting room group',
  },
  meetingroomGroupSelect: {
    id: 'buildingSelector.meetingroomGroup.select',
    defaultMessage: 'Select',
  },
  meetingroomGroupSelected: {
    id: 'buildingSelector.meetingroomGroup.selected',
    defaultMessage: 'Selected',
  },
  meetingroomGroupUnselect: {
    id: 'buildingSelector.meetingroomGroup.unselect',
    defaultMessage: 'Unselect an meeting room group',
  },
  notSelectedWarningMeetingRoomGroup: {
    id: 'buildingSelector.meetingroomGroup.notSelectedWarning',
    defaultMessage:
      'It may be possible to get limited resource information if you did not select a meeting room group.',
  },
  noMeetingroomGroup: {
    id: 'buildingSelector.meetingroomGroup.noMeetingroomGroup',
    defaultMessage: 'Meeting room group were not found',
  },
  titleOffice: {
    id: 'buildingSelector.office.title',
    defaultMessage: 'Select an office',
  },
  office: {
    id: 'buildingSelector.office',
    defaultMessage: 'Office',
  },
  officeSelect: {
    id: 'buildingSelector.office.select',
    defaultMessage: 'Select',
  },
  officeSelected: {
    id: 'buildingSelector.office.selected',
    defaultMessage: 'Selected',
  },
  officeUnselect: {
    id: 'buildingSelector.office.unselect',
    defaultMessage: 'Unselect an office',
  },
  noOffice: {
    id: 'buildingSelector.office.noOffice',
    defaultMessage: 'Offices are not found',
  },
  noOfficeSelectedWarning: {
    id: 'buildingSelector.office.notSelectedWarning',
    defaultMessage:
      'It may be possible to get limited resource information if you did not select an office.',
  },
});
