import ProductInfoScreen from './index';

const route = [
  {
    component: ProductInfoScreen,
    path: '/product',
    exact: true,
    type: 'private',
  },
];

export default route;
