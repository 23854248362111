import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';

export default function CustomView({
  date,
  localizer,
  max = localizer.endOf(new Date(), 'day'),
  min = localizer.startOf(new Date(), 'day'),
  events,
  ...props
}) {
  const currRange = useMemo(() => CustomView.range(date, { localizer }), [
    date,
    localizer,
  ]);

  return (
    <TimeGrid
      date={date}
      eventOffset={15}
      localizer={localizer}
      max={max}
      min={min}
      range={currRange}
      scrollToTime={new Date()}
      events={events}
      {...props}
    />
  );
}

/* eslint-disable react/forbid-prop-types,react/require-default-props */
CustomView.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  events: PropTypes.object,
};
/* eslint-enable react/forbid-prop-types,react/require-default-props */

CustomView.range = (date, { localizer }) => {
  const start = date;
  const end = localizer.add(start, localizer.weekRange || 0, 'day');

  return localizer.range(start, end);
};

CustomView.navigate = date => date;

CustomView.title = () => 'custom view';
