import React from 'react';
import { Row, Col } from '@d-lighted/design-system';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import moment from 'moment';
import { isEmpty } from 'lodash';

import Popover from 'components/Popover';
import { useEventInitialValues } from 'hooks/useEventInitialValues';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useValidation, composeValidators } from 'hooks/useValidation';
import GarbageSvg from 'images/ic_garbage.svg';
import messages from '../messages';
import {
  CustomForm,
  ModalTitle,
  Icon,
  TitleLabel,
  InputField,
  TextAreaField,
  ConfirmationDialogButton,
  DescriptionText,
  NotCreatableMessage,
} from './styled';

function EventCreatePopover({
  open,
  onConfirm,
  timeSlot,
  onCancel,
  timeFormat,
  tempEvent,
  settingResourceApp,
  multitenantRelated,
  resourceMap,
}) {
  const [currentUser] = useCurrentUser();
  const formattedStartTime =
    moment(timeSlot.start).format('MMMM Do ') +
    moment(timeSlot.start).locale('en').format(timeFormat);
  const formattedEndTime = moment(timeSlot.end).locale('en').format(timeFormat);
  const { titleRequired, inputLength255 } = useValidation();
  const { initialValues, descriptions } = useEventInitialValues(
    currentUser.company.id,
  );

  if (timeSlot == null || timeSlot.resourceId == null) return null;

  const resource = resourceMap.find(r => r.resourceId === timeSlot.resourceId);

  const generateResourceEventId = () => {
    if (isEmpty(tempEvent)) {
      return `rbc-event ${timeSlot.resourceId}.${timeSlot.id}`;
    }
    return `rbc-event ${tempEvent.resourceId}.${tempEvent.id}`;
  };

  return (
    <div>
      <Popover
        open={open}
        positionTargetKey="className"
        positionTargetValue={generateResourceEventId()}
        onClickOverlay={onCancel}
        overlay={false}
      >
        {resource?.creatable !== false ? (
          <Form
            onSubmit={onConfirm}
            initialValues={initialValues}
            render={({ handleSubmit }) => (
              <CustomForm onSubmit={handleSubmit}>
                <Row width="100%">
                  <Col xs display="flex" alignItems="center">
                    <ModalTitle>
                      {`${formattedStartTime} - ${formattedEndTime}`}
                    </ModalTitle>
                  </Col>
                  <Col>
                    <Icon src={GarbageSvg} onClick={onCancel} />
                  </Col>
                </Row>

                {(!currentUser.company.is_subcompany ||
                  (currentUser.company.is_subcompany &&
                    settingResourceApp?.showCalendarEventTitlesMultitenant)) && (
                  <Row width="100%" mt="18px">
                    <Col xs pl="16px" pr="16px">
                      <Row>
                        <Col>
                          <TitleLabel>
                            <FormattedMessage {...messages.titleLabel} />
                            {descriptions.title != null ? (
                              <DescriptionText>
                                {descriptions.title}
                              </DescriptionText>
                            ) : null}
                          </TitleLabel>
                        </Col>
                      </Row>
                      <Row mt="12px">
                        <Col xs>
                          <Field
                            id="title"
                            name="title"
                            component={InputField}
                            placeholder=""
                            validate={composeValidators(
                              titleRequired,
                              inputLength255,
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                {multitenantRelated && (
                  <Row width="100%">
                    <Col xs pl="16px" pr="16px">
                      <Row>
                        <Col xs>
                          <TitleLabel>
                            <FormattedMessage {...messages.noteLabel} />
                            {descriptions.notes != null ? (
                              <DescriptionText>
                                {descriptions.notes}
                              </DescriptionText>
                            ) : null}
                          </TitleLabel>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <Field
                            id="notes"
                            name="notes"
                            component={TextAreaField}
                            placeholder=""
                            validate={inputLength255}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                <Row mt="30px" width="100%" justifyContent="flex-end">
                  <Col>
                    <ConfirmationDialogButton
                      className="confirm_button"
                      type="submit"
                    >
                      <FormattedMessage {...messages.reserveButton} />
                    </ConfirmationDialogButton>
                  </Col>
                </Row>
              </CustomForm>
            )}
          />
        ) : (
          <NotCreatableMessage>
            <FormattedMessage {...messages.createDisallowPermission} />
          </NotCreatableMessage>
        )}
      </Popover>
    </div>
  );
}

EventCreatePopover.propTypes = {
  open: PropTypes.bool.isRequired,
  timeSlot: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onConfirm: PropTypes.oneOfType([PropTypes.func]).isRequired,
  onCancel: PropTypes.func.isRequired,
  timeFormat: PropTypes.string.isRequired,
  tempEvent: PropTypes.oneOfType([PropTypes.object]).isRequired,
  settingResourceApp: PropTypes.oneOfType([PropTypes.object]).isRequired,
  multitenantRelated: PropTypes.bool.isRequired,
  resourceMap: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default EventCreatePopover;
