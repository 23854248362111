import { PropTypes } from 'prop-types';
import {
  width,
  minWidth,
  height,
  color,
  space,
  borders,
  position,
  textAlign,
} from 'styled-system';

import * as colors from 'constants/colors';
import { BaseButton } from './components/styled';

BaseButton.defaultProps = {
  borderRadius: 0,
  position: 'relative',
  height: 46,
  color: 'white',
  bg: colors.primaryLightGreen,
  fontSize: 3,
  textAlign: 'center',
  width: 220,
};

BaseButton.propTypes = {
  ...position.propTypes,
  ...width.propTypes,
  ...minWidth.propTypes,
  ...height.propTypes,
  ...color.propTypes,
  ...space.propTypes,
  ...borders.propTypes,
  ...textAlign.propTypes,
  border: PropTypes.string,
  submitFailed: PropTypes.bool,
};

export default BaseButton;
