import React, { useState, useEffect, useRef } from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import PropTypes from 'prop-types';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import humps from 'lodash-humps';
import { isEmpty, isEqual } from 'lodash';
import { useSnackbar } from 'react-simple-snackbar';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';

import * as color from 'constants/colors';
import { isOverlapping, checkRoomIsBusy } from 'utils/eventUtils';
import {
  getCurrentUser,
  isSuspended,
  isRedirectToOnetimeAppointment,
  redirectToReceptionist,
} from 'utils/userUtils';
import * as commonUtil from 'utils/commonUtils';
import { timeRangeFormat } from 'utils/momentUtils';
import * as dateUnitTypes from 'constants/dateUnitTypes';
import ResourceSettingGuideView from 'components/ResourceSettingGuideView';
import RestrictImage from 'components/RestrictImage';
import * as eventDetailsButtonTypes from 'constants/eventDetailsButtonTypes';
import useIntersection from 'hooks/useIntersection';
import useValueRef from 'hooks/useValueRef';
import { CalendarHeader } from './CalendarHeader';
import ResourceHeader from './ResourceHeader';
import EventContent from './EventContent';
import EventCreatePopover from './EventCreatePopover';
import EventDetailsPopover from './EventDetailsPopover';
import LoadingView from './LoadingView';
import LoadingMoreView from './LoadingMoreView';
import messages from '../messages';
import FullScreenHeader from './FullScreenHeader';
import { MainWrapper, DateHeaderBox } from './styled';

const snackBarOptions = {
  position: 'bottom-center',
  style: {
    backgroundColor: color.errorRed,
    border: 'none',
    color: color.white,
    fontSize: '14px',
    textAlign: 'center',
  },
  closeStyle: {
    display: 'none',
  },
};

export default function CalendarBody({
  resourceMap,
  events,
  postCalendarEvent,
  filterByTime,
  filteringTime,
  filteredResources,
  filteredEvents,
  downloadMeetingRoomCsv,
  setTempEvent,
  tempEvent,
  isLoading,
  isLoadingMore,
  deleteCalendarEvent,
  setDate,
  fetchBuildings,
  buildings,
  setBuilding,
  selectedBuilding,
  fetchResourceAndEvent,
  updateResourceAndEvent,
  fullscreenState,
  postCheckIn,
  putCheckOut,
  fetchSettingResourceApp,
  settingResourceApp,
  notify,
  currentPage,
  totalPages,
  allResources,
  fetchAllResources,
}) {
  const localizer = momentLocalizer(moment);
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [openSnackbar] = useSnackbar(snackBarOptions);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [eventCreatePopoverOpen, setEventCreatePopoverOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [eventDetailsPopoverOpen, setEventDetailsPopoverOpen] = useState(false);
  const currentUser = getCurrentUser();
  const camelizedUser = humps(currentUser);
  const multitenantRelated =
    camelizedUser.company.isSubcompany || camelizedUser.company.hasSubcompany;
  const { timeConvention } = camelizedUser?.company;
  // TODO: currentUser model作って移動させる
  // This could be moved to the userUtil
  // 積水ハウス
  const isSekisuiCompanyUser = camelizedUser?.company?.id === 6885;
  // 住友生命保険相互
  const isSumitomolifeCompanyUser = camelizedUser?.company?.id === 7842;
  const isTaitoCompanyUser = camelizedUser?.company?.id === 7493;
  const timeFormat = commonUtil.getTimeFormat(timeConvention);
  const [now, setNow] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  // to persist localstorage value between re renders so that infinite re-renders are prevented
  const localEditConfig = useRef(
    JSON.parse(localStorage.getItem('roomsWebEditConfig')),
  );

  const [editMode, setEditMode] = useState(false);
  const [visibleResources, setVisibleResources] = useState([]);
  const [editableResources, setEditableResources] = useState([]);

  const [buttonType, setButtonType] = useState(eventDetailsButtonTypes.NONE);

  const buildResourcesWithVisible = resources => {
    const localEditUser = localEditConfig?.current?.user;
    const invisibleResources = localEditConfig?.current?.invisibleResources;

    // only use locally stored configuration if it is the same user
    if (localEditUser && localEditUser === currentUser.id) {
      // checking for new resources and adding/removing resources while maintaining local resources's visibility state
      if (resources?.length > 0) {
        return resources.map(item => {
          if (invisibleResources?.length > 0) {
            const foundObj = invisibleResources.find(element => {
              return item.resourceId === element.resourceId;
            });

            if (foundObj) {
              return {
                ...item,
                visible: foundObj.visible,
              };
            }
          }
          return item;
        });
      }
      return [];
    }
    return resources;
  };

  const allEvents = Array.from(document.querySelectorAll('.rbc-event'));
  useEffect(() => {
    allEvents.map(eventInCalendar => {
      // This way only the event inside an all day event will be selected
      if (eventInCalendar.style.width === '50%') {
        eventInCalendar.style.width = '100%';
      }
      return true;
    });
  }, [allEvents]);
  useEffect(() => {
    setVisibleResources(buildResourcesWithVisible(resourceMap));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceMap, currentUser.id]);

  useEffect(() => {
    setEditableResources(buildResourcesWithVisible(allResources));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allResources]);

  const handleEditModeChange = () => {
    setEditMode(!editMode);

    // setting filteringTime to null so that it does not affect edit mode
    filterByTime(null);
  };

  const handleResourceVisibleChange = resourceId => {
    // changing visiblity of each resource
    const newArr = editableResources.map(item => {
      if (item.resourceId === resourceId) {
        return {
          ...item,
          visible: !item.visible,
        };
      }
      return item;
    });
    setVisibleResources(newArr);
    setEditableResources(newArr);
  };

  const handleSaveChanges = () => {
    // storing invisibleResources to local storage and ref after clicking 'Save Changes' button
    let invisibleResources = [];
    if (editableResources?.length > 0) {
      invisibleResources = editableResources
        .filter(item => !item.visible)
        .map(item => {
          return {
            resourceId: item.resourceId,
            visible: item.visible,
          };
        });
    }

    const localObject = {
      user: currentUser.id,
      invisibleResources,
    };
    const lastInvisibleResources =
      localEditConfig.current?.invisibleResources || [];
    localStorage.setItem('roomsWebEditConfig', JSON.stringify(localObject));
    localEditConfig.current = localObject;

    // NOTE: 会議室の非表示対象の会議室が変更された場合は再度1ページ目の20件のみを表示する
    if (!isEqual(lastInvisibleResources, invisibleResources))
      fetchResourceAndEvent();
  };

  const handleResources = () => {
    const filteredVisibleResources = [];

    // showing filtered resources when filteringTime is received
    if (filteringTime) {
      if (visibleResources?.length > 0) {
        // filtering visible resources only
        if (filteredResources?.length > 0) {
          filteredResources.map(item => {
            visibleResources.map(el => {
              if (item.resourceId === el.resourceId) {
                filteredVisibleResources.push(el);
              }
              return null;
            });
            return null;
          });
        }
      }
      return filteredVisibleResources;
    }

    // showing all resources with their visibility state
    if (editMode) {
      return editableResources;
    }

    // showing visible resources only
    if (visibleResources?.length > 0) {
      return visibleResources.filter(item => item.visible);
    }

    return visibleResources;
  };

  const formats = {
    timeGutterFormat: date => moment(date).locale('en').format(timeFormat),
    selectRangeFormat: range => timeRangeFormat(range, timeFormat, 'en'),
    eventTimeRangeFormat: () => '',
    eventTimeRangeStartFormat: () => '',
    eventTimeRangeEndFormat: () => '',
  };

  const closePopover = (popoverType, apiCallStart) => {
    if (popoverType === 'create') {
      setEventCreatePopoverOpen(false);
      if (!apiCallStart) setTempEvent({});
    }
    if (popoverType === 'details') setEventDetailsPopoverOpen(false);
  };

  const generateEventStatus = (checkedInAt, checkedOutAt) => {
    if (checkedInAt && checkedOutAt) {
      return 'done';
    }
    if (checkedInAt && !checkedOutAt) {
      return 'busy';
    }
    return 'future';
  };

  const eventWrapperStyle = ({
    checkedInAt,
    checkedOutAt,
    occupyNew,
    temporary,
    resourceId,
    id,
  }) => {
    let customStyle = {
      border: 0,
      fontSize: '12px',
      padding: '8px 8px',
      flexFlow: 'row wrap',
    };

    if (editMode) {
      customStyle = {
        ...customStyle,
        visibility: 'hidden',
      };
    }

    switch (generateEventStatus(checkedInAt, checkedOutAt)) {
      case 'done':
        customStyle = {
          ...customStyle,
          backgroundColor: color.doneEventGray,
        };
        break;
      case 'busy':
        customStyle = {
          ...customStyle,
          backgroundColor: color.busyEvent,
          color: color.white,
        };
        break;
      case 'future':
      default:
        customStyle = {
          ...customStyle,
          backgroundColor: color.incomingEvent,
          border: `solid 0.5px ${color.white}`,
          color: color.white,
        };
        break;
    }
    if (occupyNew || temporary) {
      customStyle = {
        ...customStyle,
        backgroundColor: color.primaryGreen,
        color: color.white,
        border: 0,
      };
    }
    return {
      className: `${resourceId}.${id}`,
      style: customStyle,
    };
  };

  const isRoomBusy = resourceId => checkRoomIsBusy(resourceId, events);

  const slotWrapperStyle = (date, resourceId) => {
    let resourceObj;

    const resources = editMode ? editableResources : visibleResources;
    // changing slot background according to visible value of resource
    if (resources?.length) {
      resources.find(item => {
        if (item.resourceId === resourceId && item.visible) {
          resourceObj = item;
        }
        return null;
      });
    }

    const handleBackgroundColor = () => {
      if (isRoomBusy(resourceId) && !editMode) {
        return color.busyBGGray;
      }

      if (editMode && !resourceObj?.visible) {
        return color.lightGray;
      }

      return color.white;
    };

    return {
      className: date.toISOString(),
      style: {
        background: handleBackgroundColor(),
      },
    };
  };

  const handleSelectSlot = slotInfo => {
    if (editMode) {
      handleResourceVisibleChange(slotInfo?.resourceId);
      return;
    }

    if (
      isOverlapping(
        events,
        slotInfo,
        settingResourceApp?.allowDuplicateAllDayEvent,
      )
    ) {
      openSnackbar(<FormattedMessage {...messages.overlap} />);
      return;
    }

    setSelectedSlot(slotInfo);
    setTempEvent(slotInfo, timeFormat);
    setEventCreatePopoverOpen(true);
  };

  const handleEventReserve = values => {
    if (isRedirectToOnetimeAppointment()) {
      const resource = resourceMap.find(
        res => res.resourceId === selectedSlot.resourceId,
      );
      redirectToReceptionist({
        start: selectedSlot.start,
        end: selectedSlot.end,
        title: values.title,
        notes: values.notes,
        resource_id: resource.externalId,
      });
    } else {
      postCalendarEvent(
        {
          start: selectedSlot.start,
          end: selectedSlot.end,
          name: values.title,
          notes: values.notes,
        },
        selectedSlot.resourceId,
        setTempEvent,
      );
    }

    closePopover('create', true);
  };

  const getResourceTitle = event => {
    const selectedResource = resourceMap.filter(
      resource => resource.resourceId === event.resourceId,
    );
    return selectedResource[0].resourceTitle;
  };
  // 開始時刻が現在時刻から15分後以内かつ終了時刻が現在時刻以降の予定であるか
  const isAvailableTimeToCheckin = event => {
    const minutesUntilStart = moment(event.start).diff(
      moment(),
      'minutes',
      true,
    );
    const minutesUntilEnd = moment(event.end).diff(moment(), 'minutes', true);
    return minutesUntilStart <= 15 && minutesUntilEnd >= 0;
  };

  // 終日予定に重複して予約できる設定の場合は終日予定を入室対象から除く
  const isAllDayEventWhenAllowDuplicate = event =>
    settingResourceApp?.allowDuplicateAllDayEvent && event.isAllDay;

  // 特定の会議室において入室可能な予定の中で最も開始時間が早い予定を取得する
  const earliestEventOfAvailableEvents = resourceId => {
    const eventsInARoom = events.filter(
      event => event.resourceId === resourceId,
    );
    const availableEvents = eventsInARoom.filter(
      event =>
        isAvailableTimeToCheckin(event) &&
        !isAllDayEventWhenAllowDuplicate(event) &&
        event.checkInAble,
    );
    return availableEvents.sort((a, b) =>
      moment(a.start).diff(moment(b.start)),
    )[0];
  };
  const handleSelectEvent = event => {
    if (editMode) {
      handleResourceVisibleChange(event?.resourceId);
      return;
    }
    if (!event.occupyNew) {
      const resourceTitle = getResourceTitle(event);
      setSelectedEvent({ ...event, resourceTitle });
      if (!settingResourceApp?.checkinWithLaptop) {
        setButtonType(eventDetailsButtonTypes.NONE);
      } else if (
        event.checkInAble &&
        event.id === earliestEventOfAvailableEvents(event.resourceId)?.id &&
        !isRoomBusy(event.resourceId)
      ) {
        setButtonType(eventDetailsButtonTypes.CHECKIN);
      } else if (event.checkOutAble) {
        setButtonType(eventDetailsButtonTypes.CHECKOUT);
      } else {
        setButtonType(eventDetailsButtonTypes.NONE);
      }
      setEventDetailsPopoverOpen(true);
    } else {
      setSelectedSlot({
        start: event.start,
        end: event.end,
        resourceId: event.resourceId,
        id: event.id,
      });
      setEventCreatePopoverOpen(true);
    }
  };
  const handleCheckInOutResult = (result, message) => {
    if (result === 'success') {
      setEventDetailsPopoverOpen(false);
      notify.setNotify(message);
    }

    if (result === 'error') {
      notify.setError(message);
    }
  };

  const handleMeetingCheckInOut = () => {
    const eventData = {
      event_uid: selectedEvent.id,
      begin_at: selectedEvent.start,
      end_at: selectedEvent.end,
      repeat_id: selectedEvent.repeatId,
      checked_in_with: 'laptop',
    };

    if (buttonType === eventDetailsButtonTypes.CHECKIN) {
      // eslint-disable-next-line no-alert
      if (window.confirm(intl.formatMessage(messages.confirmCheckIn))) {
        postCheckIn(
          eventData,
          handleCheckInOutResult,
          selectedEvent.resourceId,
        );
      }
    }

    if (buttonType === eventDetailsButtonTypes.CHECKOUT) {
      // eslint-disable-next-line no-alert
      if (window.confirm(intl.formatMessage(messages.confirmCheckOut))) {
        putCheckOut(
          {
            uid: selectedEvent.meetingUid,
            checked_out_with: 'laptop',
          },
          handleCheckInOutResult,
          selectedEvent.resourceId,
        );
      }
    }
  };
  const handleEventDelete = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(intl.formatMessage(messages.confirmDelete))) {
      const { id, microsoftCalUid, resourceId, organizer } = selectedEvent;
      deleteCalendarEvent(resourceId, microsoftCalUid || id, organizer?.email);
      closePopover('details');
    }
  };

  const handleNavigate = date => {
    setSelectedDate(date);
    setDate(date);

    if (moment(date).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
      const queryParams = new URLSearchParams({
        date: moment(date).format('YYYY-MM-DD'),
      });
      history.replace({ search: queryParams.toString() });
    } else {
      history.replace({ search: '' });
    }
  };

  const eventsToPass = () => {
    if (filteringTime) {
      return !isEmpty(tempEvent)
        ? [...filteredEvents, tempEvent]
        : filteredEvents;
    }
    return !isEmpty(tempEvent) ? [...events, tempEvent] : events;
  };

  useEffect(() => {
    if (buildings.length === 0) {
      fetchBuildings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNow(moment().subtract(1, dateUnitTypes.HOURS).toDate());

    if (filteringTime) {
      const currentDate = moment().toDate();
      handleNavigate(currentDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteringTime]);

  useEffect(() => {
    if (location.search.includes('?date=')) {
      const [, formattedDate] = location.search.split('?date=');
      const date = moment(formattedDate).toDate();

      setSelectedDate(date);
      setDate(date);
    } else setDate(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const currentPageRef = useValueRef(currentPage);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!location.search.includes('?date=')) {
        // when selected date is today
        const today = new Date();
        if (today.getDate() !== selectedDate.getDate()) {
          setDate(today);
          return setSelectedDate(today);
        }
      }
      setEventDetailsPopoverOpen(false);
      return updateResourceAndEvent(currentPageRef.current);
    }, 300000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedBuilding]);

  useEffect(() => {
    fetchSettingResourceApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editMode) {
      fetchAllResources();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  const target = () => {
    const elements = document.querySelectorAll('.rbc-time-column:last-child');
    return elements ? elements[0] : null;
  };
  const handleIntersection = entries => {
    entries.forEach(entry => {
      if (
        !editMode &&
        !isLoadingMore &&
        entry.isIntersecting &&
        currentPage < totalPages
      ) {
        fetchResourceAndEvent(currentPage + 1);
      }
    });
  };
  const options = {
    root: document.querySelector('.rbc-time-content'),
  };
  useIntersection(target(), options, handleIntersection);

  if (isSuspended()) return <RestrictImage />;

  if (isLoading) {
    return (
      <div>
        <CalendarHeader
          partialHeader
          downloadMeetingRoomCsv={downloadMeetingRoomCsv}
          filterByTime={filterByTime}
          buildings={buildings}
          setBuilding={setBuilding}
          selectedBuilding={selectedBuilding}
          date={selectedDate}
          editMode={editMode}
          handleEditModeChange={handleEditModeChange}
          handleSaveChanges={handleSaveChanges}
          isSekisuiCompanyUser={isSekisuiCompanyUser}
        />
        <LoadingView />
      </div>
    );
  }

  let calendarSlotEndTime = 23;
  if (isSekisuiCompanyUser) calendarSlotEndTime = 19;
  if (isSumitomolifeCompanyUser) calendarSlotEndTime = 18;

  return (
    <MainWrapper edit={editMode}>
      {resourceMap.length ? (
        <DateHeaderBox
          fullscreenState={fullscreenState}
          today={new Date().getDate() === selectedDate.getDate()}
        >
          {isLoadingMore && (
            <div>
              <LoadingMoreView />
            </div>
          )}
          <Calendar
            min={
              new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                isSekisuiCompanyUser || isSumitomolifeCompanyUser ? 9 : 0,
                0,
                0,
              )
            }
            max={
              new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                calendarSlotEndTime,
                isSekisuiCompanyUser || isSumitomolifeCompanyUser ? 0 : 59,
                0,
              )
            }
            localizer={localizer}
            events={eventsToPass()}
            defaultView={Views.DAY}
            views={['day']}
            defaultDate={selectedDate}
            date={selectedDate}
            resources={handleResources()}
            resourceIdAccessor="resourceId"
            resourceTitleAccessor="resourceTitle"
            formats={formats}
            components={{
              // eslint-disable-next-line react/display-name
              toolbar: props => (
                <>
                  {fullscreenState ? (
                    <FullScreenHeader
                      {...props}
                      filteringTime={filteringTime}
                      fullscreenState={fullscreenState}
                      companyLogo={settingResourceApp?.signageModeLogo.url}
                    />
                  ) : (
                    <CalendarHeader
                      {...props}
                      filterByTime={filterByTime}
                      downloadMeetingRoomCsv={downloadMeetingRoomCsv}
                      filteringTime={filteringTime}
                      buildings={buildings || []}
                      setBuilding={setBuilding}
                      selectedBuilding={selectedBuilding}
                      editMode={editMode}
                      handleEditModeChange={handleEditModeChange}
                      handleSaveChanges={handleSaveChanges}
                      isSekisuiCompanyUser={isSekisuiCompanyUser}
                    />
                  )}
                </>
              ),
              // eslint-disable-next-line react/display-name
              resourceHeader: props => (
                <ResourceHeader
                  {...props}
                  isRoomBusy={isRoomBusy}
                  fullScreenState={fullscreenState}
                  editMode={editMode}
                  handleResourceVisibleChange={handleResourceVisibleChange}
                  additionalInformation={settingResourceApp?.enableCustomFields}
                  thumbnail={settingResourceApp?.enableThumbnail}
                />
              ),
              // eslint-disable-next-line react/display-name
              event: props => (
                <EventContent
                  {...props}
                  isSumitomolifeCompanyUser={isSumitomolifeCompanyUser}
                />
              ),
            }}
            eventPropGetter={eventWrapperStyle}
            slotPropGetter={slotWrapperStyle}
            onNavigate={handleNavigate}
            selectable={
              !isSekisuiCompanyUser && !isTaitoCompanyUser && !fullscreenState
            }
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            scrollToTime={now}
            showMultiDayTimes
          />

          <EventCreatePopover
            open={eventCreatePopoverOpen}
            timeSlot={selectedSlot}
            onConfirm={handleEventReserve}
            onCancel={() => closePopover('create')}
            timeFormat={timeFormat}
            tempEvent={tempEvent}
            settingResourceApp={settingResourceApp}
            multitenantRelated={multitenantRelated}
            resourceMap={resourceMap}
          />

          <EventDetailsPopover
            open={eventDetailsPopoverOpen}
            selectedEvent={selectedEvent}
            onCancel={() => closePopover('details')}
            timeFormat={timeFormat}
            generateEventStatus={generateEventStatus}
            onDelete={handleEventDelete}
            buttonType={buttonType}
            handleMeetingCheckInOut={handleMeetingCheckInOut}
            settingResourceApp={settingResourceApp}
          />
        </DateHeaderBox>
      ) : (
        <>
          <CalendarHeader
            partialHeader
            downloadMeetingRoomCsv={downloadMeetingRoomCsv}
            filterByTime={filterByTime}
            buildings={buildings}
            setBuilding={setBuilding}
            selectedBuilding={selectedBuilding}
            editMode={editMode}
            handleEditModeChange={handleEditModeChange}
            handleSaveChanges={handleSaveChanges}
          />
          <ResourceSettingGuideView />
        </>
      )}
    </MainWrapper>
  );
}

CalendarBody.propTypes = {
  resourceMap: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  events: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  postCalendarEvent: PropTypes.func.isRequired,
  filterByTime: PropTypes.func.isRequired,
  filteringTime: PropTypes.number,
  filteredResources: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  filteredEvents: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  downloadMeetingRoomCsv: PropTypes.func.isRequired,
  setTempEvent: PropTypes.func.isRequired,
  tempEvent: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  deleteCalendarEvent: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired,
  fetchBuildings: PropTypes.func.isRequired,
  buildings: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setBuilding: PropTypes.func.isRequired,
  selectedBuilding: PropTypes.oneOfType([PropTypes.object]).isRequired,
  fetchResourceAndEvent: PropTypes.func.isRequired,
  updateResourceAndEvent: PropTypes.func.isRequired,
  fullscreenState: PropTypes.bool,
  postCheckIn: PropTypes.func.isRequired,
  putCheckOut: PropTypes.func.isRequired,
  fetchSettingResourceApp: PropTypes.func.isRequired,
  settingResourceApp: PropTypes.oneOfType([PropTypes.object]).isRequired,
  notify: PropTypes.oneOfType([PropTypes.object]).isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  allResources: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  fetchAllResources: PropTypes.func.isRequired,
};

CalendarBody.defaultProps = {
  filteredResources: [],
  filteredEvents: [],
  filteringTime: null,
  fullscreenState: false,
};
