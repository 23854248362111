import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from 'components/BigCalendar/messages';

const COMPANIES = [9622, 9633, 9635];

function useEventInitialValues(companyId) {
  const intl = useIntl();
  const [initialValues, setInitialValues] = useState();
  const [descriptions, setDescriptions] = useState();

  useEffect(() => {
    const tempInitialState = {
      title: intl.formatMessage(messages.meeting),
      notes: '',
    };

    const tempDescriptions = {
      title: null,
      notes: null,
    };

    // Exception for this companies
    if (COMPANIES.includes(companyId)) {
      tempInitialState.title =
        '任意の会議タイトル／来訪社名／代表者氏名・役職／人数';
      tempInitialState.notes = '部署名：\n担当者氏名：\n連絡先（外線）：';
      tempDescriptions.notes =
        '（対応者の部署名/担当者氏名/連絡先（外線）を記載してください）\n' +
        '※備考欄は顧客情報の入力は厳禁です\n' +
        '※備考欄は予約者情報の入力がない場合、受付から呼び出しができません';
    }

    setInitialValues(tempInitialState);
    setDescriptions(tempDescriptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return {
    initialValues,
    descriptions,
  };
}

export { useEventInitialValues };
