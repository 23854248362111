import { useDispatch, useSelector } from 'react-redux';
import { setFilteringStatus as setFilteringStatusAction } from 'containers/Home/actions';
import { FILTERING_STATUS_CONSTANTS as STATUS_CONSTANTS } from 'containers/Home/reducer';

function useFilteringStatus() {
  const filteringStatus = useSelector(
    state => state.resourceEvent?.filteringStatus,
  );
  const dispatch = useDispatch();

  const setFilteringStatus = status => {
    dispatch(setFilteringStatusAction(status));
  };

  return [filteringStatus, setFilteringStatus];
}

export { useFilteringStatus, STATUS_CONSTANTS };
