import WebViewScreen from './index';

const webViewRoute = [
  {
    component: WebViewScreen,
    path: '/webview',
    exact: true,
    feature: 'webview',
    type: 'public',
  },
];

export default webViewRoute;
