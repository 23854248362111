import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import QRIcon from 'images/art_qr.svg';
import BulletListSelSVG from 'images/bullet_list_selected.svg';
import BulletListDefSVG from 'images/bullet_list_default.svg';
import {
  Container,
  NavItem,
  NavIcon,
  NavLabel,
  SelfColoringNavIcon,
} from './components/styled';
import messages from './messages';

export default function MobileFooter({ handleNavItemClick, isAnyDialogOpen }) {
  return (
    <Container>
      <NavItem onClick={() => handleNavItemClick('rooms')}>
        {isAnyDialogOpen ? (
          <NavIcon src={BulletListDefSVG} />
        ) : (
          <NavIcon src={BulletListSelSVG} />
        )}
        <NavLabel isSelected={!isAnyDialogOpen}>
          <FormattedMessage {...messages.rooms} />
        </NavLabel>
      </NavItem>

      <NavItem onClick={() => handleNavItemClick('qr')}>
        <SelfColoringNavIcon src={QRIcon} isSelected={isAnyDialogOpen} />
        <NavLabel isSelected={isAnyDialogOpen}>
          <FormattedMessage {...messages.qr} />
        </NavLabel>
      </NavItem>
    </Container>
  );
}

MobileFooter.propTypes = {
  handleNavItemClick: PropTypes.func.isRequired,
  isAnyDialogOpen: PropTypes.bool.isRequired,
};
