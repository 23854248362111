import styled from 'styled-components';
import { Button } from '@d-lighted/design-system';

import * as color from 'constants/colors';

export const ReserveWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 85px;
  background: ${color.white};
  box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.09);
  border-radius: 16px 16px 0px 0px;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ReserveButton = styled(Button)`
  width: 204px;
  height: 33px;
  background: ${color.brandBlue};
  box-shadow: 0px 2px 5px rgba(14, 100, 127, 0.24);
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
`;
