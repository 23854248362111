import ResourceScreen from './index';

const route = [
  {
    component: ResourceScreen,
    path: '/resource/:id',
    exact: true,
    type: 'private',
  },
];

export default route;
