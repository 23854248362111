import React from 'react';
import { PropTypes } from 'prop-types';

function IconTimes({ style, width, height, fill, strokeWidth, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16.224 16.224"
      style={style}
      onClick={onClick}
      cursor="pointer"
    >
      <title id="title">Icon Times</title>
      <g transform="translate(-44.815 -32.314)">
        <line
          x2="13.397"
          y2="12.504"
          transform="translate(46.228 34.175)"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
        <line
          x1="12.504"
          y2="13.397"
          transform="translate(46.674 33.728)"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
}

IconTimes.defaultProps = {
  width: 18,
  height: 18,
  strokeWidth: 2,
  fill: '#000000',
  style: {},
  onClick: () => false,
};

IconTimes.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  strokeWidth: PropTypes.number,
  fill: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  onClick: PropTypes.func,
};

export default IconTimes;
