import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { CustomModal } from 'components/BigCalendar/components/styled';
import ModalContent from 'components/SortingDialog/components/ModalContent';
import { isEmpty, isEqual } from 'lodash';
import { useBuildings } from 'containers/Home/hooks/useBuildings';

function SortingDialog({ anchor, query }) {
  const [modalOpen, setModalOpen] = useState(false);
  const { selectedBuilding } = useBuildings();
  const history = useHistory();

  const toggleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  const getQueryValues = values => {
    let result = values;

    if (isEqual(values.meetingTime, 'other')) {
      result = {
        ...values,
        meetingTime: values.minute?.value || 15,
      };
    }

    delete result.minute;
    return {
      ...result,
      building: !isEmpty(selectedBuilding) ? selectedBuilding.building_id : '',
    };
  };

  const onSubmit = values => {
    const queryParam = new URLSearchParams(getQueryValues(values));

    history.push(`/sorting?${queryParam.toString()}`);
  };

  return (
    <>
      {React.cloneElement(anchor, { onClick: toggleModalOpen })}

      <CustomModal
        isOpen={modalOpen}
        toggleModal={toggleModalOpen}
        onBackgroundClick={toggleModalOpen}
        onEscapeKeydown={toggleModalOpen}
        width="738px"
        height="606px"
      >
        <ModalContent
          onSubmit={onSubmit}
          toogleModal={toggleModalOpen}
          query={query}
        />
      </CustomModal>
    </>
  );
}

SortingDialog.defaultProps = {
  query: null,
};

SortingDialog.propTypes = {
  anchor: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  query: PropTypes.object,
};

export default SortingDialog;
