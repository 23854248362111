import React from 'react';
import styled from 'styled-components';
import MeetingIllustration from 'images/meeting_illustration.svg';
import BaseButton from 'components/Buttons/BaseButton';
import * as colors from 'constants/colors';

const Wrapper = styled.div`
  margin-top: 40px;
  text-align: center;
`;

const Image = styled.img`
  width: 35%;
`;

const GuideText = styled.p`
  font-size: 18px;
  margin-bottom: 8px;
  color: #484e62;
  a {
    text-decoration: none;
  }
`;

export const ContactButton = styled(BaseButton)`
  text-align: center;
  margin-top: 16px;
  padding: 5.5px 16px 5px 16px;
  border-radius: 3px;
  background-color: ${colors.primaryLightGreen};
  border: none;
  font-size: 16px;
`;

function RestrictImage() {
  return (
    <Wrapper>
      <Image src={MeetingIllustration} />
      <div>
        <GuideText>
          トライアル期間が終了し、アカウントが停止されました。
        </GuideText>
        <GuideText>
          アカウントを再開するには、弊社カスタマーサクセスまでお問い合わせください。
        </GuideText>
        <a
          href="https://rooms.receptionist.jp/contact/"
          target="_blank"
          rel="noreferrer"
        >
          <ContactButton>問い合わせページへ</ContactButton>
        </a>
      </div>
    </Wrapper>
  );
}

export default RestrictImage;
