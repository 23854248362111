import { defineMessages } from 'react-intl';

export default defineMessages({
  qr: {
    id: 'mobileFooter.navItem.qr.label',
    defaultMessage: 'Enter/Leave Room',
  },
  rooms: {
    id: 'mobileFooter.navItem.roomList.label',
    defaultMessage: 'MeetingRooms List',
  },
});
