import React from 'react';
import { PropTypes } from 'prop-types';

import Text18 from 'components/Texts/Text18';
import Text13 from 'components/Texts/Text13';
import { Wrapper, ChildrenWrapper } from './styled';

function IllustrationLayout({ illustration, summary, description, children }) {
  return (
    <Wrapper>
      {illustration}
      <Text18 p="26px 0 22px" color="gray.body">
        {summary}
      </Text18>
      <Text13 color="gray.body2">{description}</Text13>
      {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </Wrapper>
  );
}

IllustrationLayout.propTypes = {
  illustration: PropTypes.node.isRequired,
  summary: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  children: PropTypes.node,
};

IllustrationLayout.defaultProps = {
  children: null,
};

export default IllustrationLayout;
