import React from 'react';
import { Row, Col, IconNew as Icon } from '@d-lighted/design-system';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import * as color from 'constants/colors';
import messages from '../messages';
import {
  CustomConfirmationModal,
  CancelDialogButton,
  CircleIcon,
  MainStatusText,
  QRDialogContainer,
} from './styled';

function QRSuccessDialog({ onButtonClick, status, ...props }) {
  return (
    <CustomConfirmationModal
      {...props}
      width="90vw"
      height="315px"
      maxHeight="70%"
      mb="88px"
    >
      <QRDialogContainer className="space_evenly">
        <CircleIcon success>
          <Icon className="material-icons" color={color.white} fontSize="48px">
            check
          </Icon>
        </CircleIcon>
        <Row width="100%">
          <Col width="100%" pl="25px" pr="25px">
            <MainStatusText>
              {status === 'exit' ? (
                <FormattedMessage {...messages.exitSuccess} />
              ) : (
                <FormattedMessage {...messages.enterSuccess} />
              )}
            </MainStatusText>
          </Col>
        </Row>
        {/* These is not currently required, hence it is disabled for now */}
        {/* <Row width="100%" mt="8px" display="none">
          <Col width="100%" pl="25px" pr="25px">
            <SubText>
              この文章はダミーですお読みにならないでください。文章の構成をわかりや
            </SubText>
          </Col>
        </Row> */}
        <Row width="100%">
          <Col
            pl="25px"
            pr="25px"
            width="100%"
            display="flex"
            justifyContent="center"
          >
            <CancelDialogButton outlined onClick={onButtonClick}>
              <FormattedMessage {...messages.return} />
            </CancelDialogButton>
          </Col>
        </Row>
      </QRDialogContainer>
    </CustomConfirmationModal>
  );
}

QRSuccessDialog.propTypes = {
  onButtonClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  status: PropTypes.string.isRequired,
};

export default QRSuccessDialog;
