import moment from 'moment';
import * as dateUnitTypes from 'constants/dateUnitTypes';

export function disabledDateTomorrow(current) {
  if (!current) {
    return false;
  }
  const date = moment();
  date.add(1, dateUnitTypes.DAYS).hour(0).minute(0).second(0);
  return current.valueOf() > date.valueOf();
}

export function disabledDateYesterday(current) {
  if (!current) {
    return false;
  }
  const date = moment();
  date.hour(0).minute(0).second(0);
  return current.valueOf() < date.valueOf();
}

export function timeRangeFormat({ start, end }, timeFormat, locale) {
  if (locale) {
    return `${moment(start).locale(locale).format(timeFormat)}~${moment(end)
      .locale(locale)
      .format(timeFormat)}`;
  }
  return `${moment(start).format(timeFormat)}~${moment(end).format(
    timeFormat,
  )}`;
}

export function differentDay(date1, date2) {
  return Math.abs(moment(date1).diff(moment(date2), 'hours')) > 23;
}

export function getStartDate(start, date) {
  const today = moment(date || new Date()).startOf('day');
  const startObj = moment(start);

  return today.isAfter(startObj) ? today : startObj;
}

export function getEndDate(end, date) {
  const today = moment(date || new Date()).endOf('day');
  const endObj = moment(end);

  return today.isBefore(endObj) ? today : endObj;
}
