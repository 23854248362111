import styled from 'styled-components';
import * as color from 'constants/colors';
import { Link } from 'react-router-dom';
import { space } from 'styled-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@d-lighted/design-system';

export const BackButton = styled(IconButton)`
  background-color: ${color.hoverGray};
  font-size: 16px;
  margin-right: 3px;
`;

export const GearIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const MobileHeaderContent = styled.div`
  position: relative;
  width: 100%;
`;

export const MobileHeaderWrapper = styled.header`
  height: auto;
  width: 100%;
  padding: 22px 0 22px 0;
  background-color: ${color.hoverGray};
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  z-index: 20;
  ${({ sticky }) =>
    sticky
      ? `
    position: sticky;
    top: 0;
  `
      : `
    position: relative;
  `}
`;

export const MobileHeaderRight = styled.div`
  position: absolute;
  top: -31px;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
`;

export const MobileHeaderLeft = styled.div`
  position: absolute;
  left: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileHeaderCenter = styled.p`
  text-align: center;
  font-weight: 200;
  font-size: 18px;
`;

export const GreenIcon = styled(FontAwesomeIcon)`
  color: ${color.brandBlue};
`;

export const HeaderWrapper = styled.header`
  height: 42.5px;
  width: 100%;
  padding: 9px 0 8.5px 0;
  background-color: ${color.hoverGray};
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  ${space}
`;

export const HeaderRight = styled.div`
  margin-right: 0;
  display: inline-flex;
  align-items: center;
`;

export const HeaderLeft = styled.div`
  padding-left: 26px;
  display: inline-flex;
  align-items: center;
`;

export const Icon = styled.img`
  padding-left: 0;
  cursor: pointer;
  margin-top: 8px;
`;

export const LinkText = styled(Link)`
  color: ${color.primaryLightGreen};
  text-decoration: none;
  font-size: 15px;
  line-height: 11px;
  text-align: left;
  cursor: pointer;
  ${space}
`;

export const Anchor = styled.a`
  color: ${color.primaryLightGreen};
  text-decoration: none;
  font-size: 15px;
  line-height: 11px;
  text-align: left;
  cursor: pointer;
  ${space}
`;

export const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 26px;
`;

export const NewIcon = styled.div`
  border-radius: 1.5px;
  background-color: ${color.lightRed};
  color: ${color.errorRed};
  padding: 0 6px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  display: inline;
`;

export const LangSelector = styled.div`
  display: inline-flex;
  margin-left: 39px;
  margin-right: 22px;
  box-sizing: border-box;
  align-items: center;
`;

export const LangText = styled.span`
  color: ${p => (p.active ? color.doneEventGray : color.primaryDarkGreen)};
  cursor: pointer;
  padding: 0 13px 0 0;
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  border-right: 1px solid ${color.tagInactiveBorder};

  &:last-child {
    border-right: none;
    padding: 0 11px 0 13px;
  }
`;
