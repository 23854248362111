import { PropTypes } from 'prop-types';
import Text from 'components/Texts/Text';

const Text13 = Text.withComponent('p');

Text13.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

Text13.defaultProps = {
  fontSize: 13,
  color: 'gray.body',
};

export default Text13;
