export const ACTIONS = {
  SIGN_OUT: 'sign_out',
  UPDATE_CREDENTIALS: 'update_credentials',
  CHECK_IN: 'check_in',
  CHECK_OUT: 'check_out',
  INSTANT_MEETING: 'instant_meeting',
};

export function isReactNativeWebView() {
  return localStorage.getItem('isReactNative') || false;
}

function sendPayload(action, payload = {}) {
  try {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action,
        payload,
      }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error sending message to ReactNativeWebView');
  }
}

export function signOut() {
  sendPayload(ACTIONS.SIGN_OUT);
}

function getAndParseLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key)) || {};
}

export function updateCredentials() {
  const currentUser = getAndParseLocalStorage('currentUser');
  const authHeaders = getAndParseLocalStorage('auth_headers');
  const refreshToken = localStorage.getItem('refresh_token');

  sendPayload(ACTIONS.UPDATE_CREDENTIALS, {
    currentUser,
    authHeaders,
    refreshToken,
  });
}

export function isRNWebViewUpdateCredentials() {
  if (isReactNativeWebView()) updateCredentials();
}

export function broadcastCheckIn(success) {
  if (!isReactNativeWebView()) return;

  sendPayload(ACTIONS.CHECK_IN, { success });
}

export function broadcastCheckOut(success) {
  if (!isReactNativeWebView()) return;

  sendPayload(ACTIONS.CHECK_OUT, { success });
}

export function broadcastInstaMeeting(success) {
  if (!isReactNativeWebView()) return;

  sendPayload(ACTIONS.INSTANT_MEETING, { success });
}
