import { defineMessages } from 'react-intl';

export default defineMessages({
  cap: {
    id: 'resource.detailCard.capacity',
    defaultMessage: 'Capacity',
  },
  building: {
    id: 'resource.detailCard.building',
    defaultMessage: 'Building',
  },
  vacant: {
    id: 'resourceList.card.roomEmpty',
    defaultMessage: 'Vacant',
  },
  inUse: {
    id: 'resourceList.card.roomInUse',
    defaultMessage: 'In Use',
  },
  category: {
    id: 'resource.detailCard.category',
    defaultMessage: 'Category',
  },
});
