import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import styled from 'styled-components';
import { height, borderRadius } from 'styled-system';

const ErrorText = styled.span`
  margin-top: ${props => (props?.mt ? props.mt : '0px')};
  padding-left: 10px;
  display: inline-block;
  white-space: ${props => (props.whiteSpace ? props.whiteSpace : 'nowrap')};
  color: ${props => (props?.color ? props.color : '#b00020')};
`;

const TextArea = styled.textarea`
  box-sizing: border-box;
  margin-bottom: 5px;
  ${height};
  width: ${p => (p.width ? p.width : '100%')};
  ${borderRadius};
  border: solid 1px #f6f8fa;
  padding: 12px 18px;
  font-size: 15px;
  resize: none;
  color: #314143;
  transition: all 0.4s ease, border-color 0.15s ease-in-out;
  background: #f6f8fa !important;
  border-radius: 11px !important;

  &:focus {
    outline: none !important;
    border: 1px solid #f6f8fa;
  }
`;

const Wrapper = styled.div`
  position: relative;
  font-size: 14px;
`;

const WarningIcon = styled(FontAwesome)`
  width: 16px;
  height: 16px;
  margin-left: 10px;
  position: relative;
  margin-top: ${props => props.top}px;
  color: #e84c3d;
`;

const ErrorWrapper = styled.div`
  padding-top: ${props => (props.mt ? props.mt : '20px')};
  margin-left: ${props => (props.ml ? props.ml : '14px')};
`;

function ErrorUI({ meta: { touched, error, warning }, icon }) {
  return (
    <Wrapper>
      {touched &&
        ((error && icon && (
          <ErrorWrapper>
            <WarningIcon
              name={`${icon}-circle`}
              className={`fas fa-${icon}-circle fa-lg`}
              role="button"
            />
            <ErrorText>{error}</ErrorText>
          </ErrorWrapper>
        )) ||
          (error && !icon && <ErrorText>{error}</ErrorText>) ||
          (warning && <span>{warning}</span>))}
    </Wrapper>
  );
}

ErrorUI.defaultProps = {
  meta: {
    touched: undefined,
    error: undefined,
    warning: undefined,
  },
  icon: '',
};

ErrorUI.propTypes = {
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  icon: PropTypes.string,
};

export function TextAreaInput({ id, ...restProps }) {
  return (
    <div>
      <TextArea id={id} {...restProps.input} {...restProps} />
      <ErrorUI meta={restProps.meta} />
    </div>
  );
}

TextAreaInput.defaultProps = {
  id: undefined,
};

TextAreaInput.propTypes = {
  id: PropTypes.string,
};
