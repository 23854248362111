import { defineMessages } from 'react-intl';

export default defineMessages({
  ProductListTitle: {
    id: 'containers.productinfo.bottomsectiontitle',
    defaultMessage: '日程調整ツール「調整アポ」をご利用中のあなたへのおすすめ',
  },
  ProductInfoTitle: {
    id: 'header.title.productInfo',
    defaultMessage: 'RECEPTIONIST提供サービス一覧',
  },
  BackButton: {
    id: 'header.button.back',
    defaultMessage: '戻る',
  },
});
