import { defineMessages } from 'react-intl';

export default defineMessages({
  commonErrorMsg: {
    id: 'notify.home.commonErrorMsg',
    defaultMessage: 'An unexpected error has occurred',
  },
  notifyAlert: {
    id: 'notify.home.alert',
    defaultMessage: 'Rooms could not be found',
  },
  getResEvtErrSubMsg: {
    id: 'notify.home.getResourceEvent.errorSubMsg',
    defaultMessage: 'There are no rooms configured',
  },
  postEventSuccessMsg: {
    id: 'notify.home.postEvent.successMsg',
    defaultMessage: 'Succeeded in Creating an event.',
  },
  postEventErrMsg: {
    id: 'notify.home.postEvent.errorMsg',
    defaultMessage: 'Error while creating event',
  },
  postEventErrSubMsg: {
    id: 'notify.home.postEvent.errorSubMsg',
    defaultMessage: 'An error occured while trying to create new event',
  },
  deleteEventSuccessMsg: {
    id: 'notify.home.deleteEvent.successMsg',
    defaultMessage: 'Succeeded in deleting the event.',
  },
  delEventErrMsg: {
    id: 'notify.home.deleteEvent.errorMsg',
    defaultMessage: 'Error while deleting event',
  },
  delEventErrSubMsg: {
    id: 'notify.home.deleteEvent.errorSubMsg',
    defaultMessage: 'An error occured while trying to delete the event',
  },
  getCalEvtErrSubMsg: {
    id: 'notify.home.getCalEvent.errorSubMsg',
    defaultMessage: 'No such calendar event found.',
  },
  postChkInErrSubMsg: {
    id: 'notify.home.postCheckIn.errorSubMsg',
    defaultMessage: 'Could not Check In',
  },
  putChkOutErrSubMsg: {
    id: 'notify.home.putCheckOut.errorSubMsg',
    defaultMessage: 'Could not Check Out',
  },
  dlMtgRoomsSuccessMsg: {
    id: 'notify.download_meeting_rooms.successMsg',
    defaultMessage: 'Succeeded in download_meeting_rooms.',
  },
  dlMtgRoomsErrMsg: {
    id: 'notify.download_meeting_rooms.errorMsg',
    defaultMessage: 'An error occured. download_meeting_rooms has failed.',
  },
  dlMtgRoomsOffDatRange: {
    id: 'notify.download_meeting_rooms.dlMtgRoomsOffDatRange',
    defaultMessage: 'The range must be within one month.',
  },
  resourceCalendarEventLists: {
    id: 'resourceCalendarEventLists',
    defaultMessage: 'Meeting rooms availability',
  },
  availableResourcesForMinutes: {
    id: 'resourceCalendarEventLists.availableResourcesForMinutes',
    defaultMessage: 'Available meeting rooms for {minutes} minutes',
  },
  occupy: {
    id: 'resourceCalendarEvent.occupy',
    defaultMessage: 'Available',
  },
  checkInSuccessMsg: {
    id: 'notify.home.postCheckIn.successMsg',
    defaultMessage: 'Checked in successfully.',
  },
  checkOutSuccessMsg: {
    id: 'notify.home.putCheckOut.successMsg',
    defaultMessage: 'Checked out successfully.',
  },
});
