import React from 'react';
import PropTypes from 'prop-types';
import CalendarBody from './components/CalendarBody';
import { BigCalendarWrapper } from './components/BigCalendarWrapper';

function BigCalendar(props) {
  const { fullscreenState, isLoadingMore } = props;
  return (
    // eslint-disable-next-line react/destructuring-assignment
    <BigCalendarWrapper
      fullscreenState={fullscreenState}
      isLoadingMore={isLoadingMore}
    >
      <CalendarBody {...props} />
    </BigCalendarWrapper>
  );
}
BigCalendar.propTypes = {
  fullscreenState: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
};
export default BigCalendar;
