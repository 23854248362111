import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import App from './App';
import { setupAxios } from './setupAxios';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
};

TagManager.initialize(tagManagerArgs);

// This configures Axios to send tokens with every request and it enables refresh token.
setupAxios();

// Removed React.StrictMode, because there's some third party options that use deprecated options in that mode
ReactDOM.render(<App />, document.getElementById('root'));
