import React from 'react';
import { Row, Col, IconNew as Icon } from '@d-lighted/design-system';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import * as color from 'constants/colors';
import messages from '../messages';
import {
  CustomConfirmationModal,
  CancelDialogButton,
  CircleIcon,
  MainStatusText,
  QRDialogContainer,
} from './styled';

function QRErrorDialog({ message, onButtonClick, ...props }) {
  return (
    <CustomConfirmationModal
      {...props}
      width="90vw"
      height="auto"
      maxHeight="80%"
      mb="88px"
      pb="20px"
      pt="20px"
    >
      <QRDialogContainer>
        <CircleIcon error>
          <Icon className="material-icons" color={color.white} fontSize="48px">
            close
          </Icon>
        </CircleIcon>
        <Row width="100%" mt="24px">
          <Col width="100%" pl="25px" pr="25px">
            <MainStatusText>
              {typeof message === 'object' ? (
                <FormattedMessage {...message} />
              ) : (
                message
              )}
            </MainStatusText>
          </Col>
        </Row>
        {/* This is not currently required, hence it is disabled for now */}
        {/* <Row width="100%" mt="8px" display="none">
          <Col width="100%" pl="25px" pr="25px">
            <SubText>
              <FormattedMessage {...message} />
            </SubText>
          </Col>
        </Row> */}
        <Row width="100%" mt="20px">
          <Col
            pl="25px"
            pr="25px"
            width="100%"
            display="flex"
            justifyContent="center"
          >
            <CancelDialogButton outlined onClick={onButtonClick}>
              <FormattedMessage {...messages.return} />
            </CancelDialogButton>
          </Col>
        </Row>
      </QRDialogContainer>
    </CustomConfirmationModal>
  );
}

QRErrorDialog.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onButtonClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
};

QRErrorDialog.defaultProps = {
  message: messages.error,
};

export default QRErrorDialog;
