import React from 'react';
import { Row, Col } from '@d-lighted/design-system';
import PropTypes from 'prop-types';

import BigCalendar from 'components/Mobile_BigCalendar';
import { Card } from './components/styled';

function CalendarCard({ resource, ...props }) {
  const resourceArray = [resource];

  return (
    <Card>
      <Row mt="10px" ml="0" mr="0">
        <Col width="100%">
          <BigCalendar resourceMap={resourceArray} {...props} />
        </Col>
      </Row>
    </Card>
  );
}

CalendarCard.propTypes = {
  resource: PropTypes.oneOfType([PropTypes.object, PropTypes.node]).isRequired,
};

export default CalendarCard;
