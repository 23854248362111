import React from 'react';
import styled from 'styled-components';
import { Row, Col, IconNew as Icon } from '@d-lighted/design-system';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import moment, { localeData } from 'moment';

import * as color from 'constants/colors';
import messages from '../messages';
import {
  CustomModal,
  CancelDialogButton,
  ConfirmationDialogButton,
  CircleIcon,
  MainStatusText,
  SubText,
  DialogContainer,
} from './styled';

const Bold = styled.b`
  font-weight: 900;
`;

function BookingSuccessDialog({
  onButtonClick,
  isHomeBooking,
  onCompleteClick,
  timeSlot,
  ...props
}) {
  const dateMoment = moment(timeSlot?.date);
  const dates = {
    date: dateMoment.format('LL'),
    dayOfWeek: localeData().weekdaysShort(dateMoment),
    start: moment(timeSlot?.start).format('LT'),
    end: moment(timeSlot?.end).format('LT'),
  };
  return (
    <CustomModal {...props} width="90vw" height="auto" pb="16px">
      <DialogContainer>
        <CircleIcon success isHomeBooking={isHomeBooking}>
          <Icon className="material-icons" color={color.white} fontSize="48px">
            check
          </Icon>
        </CircleIcon>
        <Row width="100%" mt="24px">
          <Col width="100%" pl="25px" pr="25px">
            <MainStatusText>
              <FormattedMessage {...messages.successDialogTitle} />
            </MainStatusText>
          </Col>
        </Row>
        <Row width="100%" pt="8px">
          <Col width="100%" pl="25px" pr="25px">
            <SubText>
              {isHomeBooking != null && timeSlot != null ? (
                <>
                  <Bold>{`${dates.date}(${dates.dayOfWeek}) ${dates.start}-${dates.end}`}</Bold>
                  <br />
                  <FormattedMessage {...messages.succMsgShort} />
                </>
              ) : (
                <FormattedMessage {...messages.succMsg} />
              )}
            </SubText>
          </Col>
        </Row>

        <Row mt="21px" width="100%">
          <Col pl="25px" pr="25px" width="100%">
            {!isHomeBooking ? (
              <CancelDialogButton outlined onClick={onButtonClick}>
                <FormattedMessage {...messages.return} />
              </CancelDialogButton>
            ) : (
              <>
                <Row>
                  <Col width="100%">
                    <ConfirmationDialogButton
                      marginBottom="8px"
                      onClick={onCompleteClick}
                    >
                      <FormattedMessage {...messages.complete} />
                    </ConfirmationDialogButton>
                  </Col>
                </Row>

                <Row>
                  <Col width="100%">
                    <CancelDialogButton outlined onClick={onButtonClick}>
                      <FormattedMessage {...messages.returnRefined} />
                    </CancelDialogButton>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </DialogContainer>
    </CustomModal>
  );
}

BookingSuccessDialog.propTypes = {
  onButtonClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  isHomeBooking: PropTypes.bool,
  onCompleteClick: PropTypes.func,
  timeSlot: PropTypes.oneOfType([PropTypes.object]),
};

BookingSuccessDialog.defaultProps = {
  isHomeBooking: false,
  onCompleteClick: () => {},
  timeSlot: null,
};

export default BookingSuccessDialog;
