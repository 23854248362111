import { useDispatch, useSelector } from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import axios from 'setupAxios';
import { isEmpty } from 'lodash';
import { getResources } from 'containers/Home/actions';
import humps from 'lodash-humps';

function buildResources(data) {
  return data.resources.reduce((acc, resource) => {
    switch (resource.providerType) {
      case 'google':
        // eslint-disable-next-line no-case-declarations
        const {
          name: googleName,
          capacity: googleCapacity,
          floorName,
          buildingId,
        } = resource.resourceGoogle;

        acc.push({
          resourceId: resource.uid,
          resourceTitle: googleName,
          capacity: googleCapacity || 0,
          floorName,
          buildingId,
          providerType: resource.providerType,
          visible: true,
        });
        break;
      case 'microsoft':
        // eslint-disable-next-line no-case-declarations
        const {
          name: msName,
          capacity: msCapacity,
          floorNumber,
          buildingName,
        } = resource.resourceMicrosoft;

        acc.push({
          resourceId: resource.uid,
          resourceTitle: msName,
          capacity: msCapacity || 0,
          floorName: floorNumber,
          buildingName,
          providerType: resource.providerType,
          visible: true,
        });
        break;
      case 'garoon':
        // eslint-disable-next-line no-case-declarations
        const { name: garoonName, facilityGroupName } = resource.resourceGaroon;

        acc.push({
          resourceId: resource.uid,
          resourceTitle: garoonName,
          buildingId: facilityGroupName,
          providerType: resource.providerType,
          visible: true,
        });
        break;
      default:
    }
    // eslint-disable-next-line consistent-return
    return acc;
  }, []);
}

function useGetResources() {
  const resources = useSelector(state => state.resourceEvent?.resources);
  const dispatch = useDispatch();

  const fetchResources = async () => {
    dispatch(showLoading());
    try {
      const { data } = await axios('/company/resources', {
        method: 'GET',
      }).catch(e => e.response);
      if (!isEmpty(data) && !isEmpty(data?.resources)) {
        const camelizedData = humps(data);
        dispatch(getResources(buildResources(camelizedData)));
      }
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  return {
    fetchResources,
    resources,
  };
}

export { useGetResources };
