import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import ActionButton from 'components/Buttons/ActionButton';
import { disabledDateTomorrow } from 'utils/momentUtils';
import RangeCalendarPicker from 'components/RangeCalendarPicker';
import * as dateUnitTypes from 'constants/dateUnitTypes';
import messages from 'components/BigCalendar/messages';
import DownloadSvg from 'images/ic_download.svg';
import { CsvDownloadButtonWrapper, DownloadIcon } from './styled';

export default function CsvDownloadButton(props) {
  const handleButtonClick = dates => {
    const [startDate, endDate] = dates;
    props.downloadMeetingRoomCsv(startDate, endDate);
  };

  return (
    <CsvDownloadButtonWrapper>
      <RangeCalendarPicker
        onOk={handleButtonClick}
        disabledDate={disabledDateTomorrow}
        defaultValue={[
          moment().clone().subtract(1, dateUnitTypes.MONTHS),
          moment(),
        ]}
      >
        <ActionButton
          buttonText={<FormattedMessage {...messages.downloadButtonText} />}
        >
          <DownloadIcon src={DownloadSvg} />
        </ActionButton>
      </RangeCalendarPicker>
    </CsvDownloadButtonWrapper>
  );
}

CsvDownloadButton.propTypes = {
  downloadMeetingRoomCsv: PropTypes.func.isRequired,
};
