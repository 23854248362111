import styled from 'styled-components';

export const WordBreakLabel = styled.p`
  word-break: break-all;
  font-size: 15px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  text-align: left;
  cursor: default;
  color: #688798;
  letter-spacing: 0.1px;
`;
