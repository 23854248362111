import { defineMessages } from 'react-intl';

export default defineMessages({
  activeButton: {
    id: 'containers.productinfo.activebutton',
    defaultMessage: '無料で試す',
  },
  inactiveButton: {
    id: 'containers.productinfo.inactivebutton',
    defaultMessage: 'ご利用中',
  },
  inquiryButton: {
    id: 'containers.productinfo.inquiry',
    defaultMessage: 'お問い合わせ',
  },
  option: {
    id: 'containers.productinfo.option',
    defaultMessage: 'オプション',
  },
  receptionistTopproductSurtitle: {
    id: 'containers.productinfo.topproduct.receptionist.surtitle',
    defaultMessage: '日程調整ツール「調整アポ」をご利用中のあなたへのおすすめ',
  },
  receptionistTopproductTitle: {
    id: 'containers.productinfo.topproduct.receptionist.title',
    defaultMessage: '来訪者の受付・ご案内までワンストップ',
  },
  receptionistSurtitle: {
    id: 'containers.productinfo.receptionist.surtitle',
    defaultMessage: '受付システム',
  },
  receptionistTitle: {
    id: 'containers.productinfo.receptionist.title',
    defaultMessage: 'RECEPTIONIST',
  },
  receptionistMiddleContentText: {
    id: 'containers.productinfo.receptionist.middleContentText',
    defaultMessage: 'iPad1台で、受付を無人化できるクラウドサービスです。',
  },
  receptionistFeature1: {
    id: 'containers.productinfo.receptionist.feature1',
    defaultMessage: '元受付嬢が開発した使いやすさ',
  },
  receptionistFeature2: {
    id: 'containers.productinfo.receptionist.feature2',
    defaultMessage: '様々なシステムと連携',
  },
  receptionistFeature3: {
    id: 'containers.productinfo.receptionist.feature3',
    defaultMessage: '口コミ評価サイトで10期連続No.1',
  },
  receptionistOption1Title: {
    id: 'containers.productinfo.receptionist.option1Title',
    defaultMessage: '出社管理',
  },
  receptionistOption1Subtitle: {
    id: 'containers.productinfo.receptionist.option1Subtitle',
    defaultMessage: '出社・在宅勤務予定をチームで共有',
  },
  receptionistOption2Title: {
    id: 'containers.productinfo.receptionist.option2Title',
    defaultMessage: 'セキュリティーゲート連携',
  },
  receptionistOption2Subtitle: {
    id: 'containers.productinfo.receptionist.option2Subtitle',
    defaultMessage: '出社・在宅勤務予定をチームで共有',
  },
  receptionistPromoText: {
    id: 'containers.productinfo.receptionist.promoText',
    defaultMessage: 'iPad1台で、受付を無人化',
  },
  roomsTopproductSurtitle: {
    id: 'containers.productinfo.topproduct.rooms.surtitle',
    defaultMessage:
      '受付システム「RECEPTIONIST」をご利用中のあなたへのおすすめ',
  },
  roomsTopproductTitle: {
    id: 'containers.productinfo.topproduct.rooms.title',
    defaultMessage: 'お客様をお待たせせず会議室へご案内',
  },
  roomsTopproductSubtitle: {
    id: 'containers.productinfo.topproduct.rooms.subtitle',
    defaultMessage:
      '来客や社内会議で使う会議室の予約・管理をデジタル化。「空予約」「無断延長」を防止します。',
  },
  roomsSurtitle: {
    id: 'containers.productinfo.rooms.surtitle',
    defaultMessage: 'スペース予約管理システム',
  },
  roomsTitle: {
    id: 'containers.productinfo.rooms.title',
    defaultMessage: '予約ルームズ',
  },
  roomsMiddleContentText: {
    id: 'containers.productinfo.rooms.middleContentText',
    defaultMessage:
      '会議室の予約管理を効率化し、空予約や無断延長を自動で防止します。',
  },
  roomsOption1Title: {
    id: 'containers.productinfo.rooms.option1Title',
    defaultMessage: 'マルチテナント',
  },
  roomsOption1Subtitle: {
    id: 'containers.productinfo.rooms.option1Subtitle',
    defaultMessage: '拠点結合による会議室予約を解決',
  },
  roomsFeature1: {
    id: 'containers.productinfo.rooms.feature1',
    defaultMessage: '会議室の管理・予約のデジタル化',
  },
  roomsFeature2: {
    id: 'containers.productinfo.rooms.feature2',
    defaultMessage: '空予約や無断延長を自動で防止',
  },
  roomsFeature3: {
    id: 'containers.productinfo.rooms.feature3',
    defaultMessage: 'オフィスリソースの効果最大化',
  },
  roomsPromoText: {
    id: 'containers.productinfo.rooms.promoText',
    defaultMessage: '会議室の「空いてない」を改善',
  },
  schedulingTopproductSurtitle: {
    id: 'containers.productinfo.topproduct.scheduling.surtitle',
    defaultMessage: 'あなたへのおすすめ',
  },
  schedulingTopproductTitle: {
    id: 'containers.productinfo.topproduct.scheduling.title',
    defaultMessage: '日程調整から当日のご案内まで一気通貫',
  },
  schedulingTopproductSubtitle: {
    id: 'containers.productinfo.topproduct.scheduling.subtitle',
    defaultMessage:
      'ゲストと日程調整を行う際に発生する作業を、コピペで終わらすことができます。同時に受付コード発行・会議室予約もすべて自動化します。',
  },
  schedulingSurtitle: {
    id: 'containers.productinfo.scheduling.surtitle',
    defaultMessage: '日程調整ツール',
  },
  schedulingTitle: {
    id: 'containers.productinfo.scheduling.title',
    defaultMessage: '調整アポ',
  },
  schedulingMiddleContentText: {
    id: 'containers.productinfo.scheduling.middleContentText',
    defaultMessage: '日程調整と当日までのフォローアップを自動化します。',
  },
  schedulingFeature1: {
    id: 'containers.productinfo.scheduling.feature1',
    defaultMessage: '元受付嬢が開発した使いやすさ',
  },
  schedulingFeature2: {
    id: 'containers.productinfo.scheduling.feature2',
    defaultMessage: '様々なシステムと連携',
  },
  schedulingFeature3: {
    id: 'containers.productinfo.scheduling.feature3',
    defaultMessage: '口コミ評価サイトで10期連続No.1',
  },
  schedulingPromoText: {
    id: 'containers.productinfo.scheduling.promoText',
    defaultMessage: '日程調整がコピペで終わる',
  },
});
