import { hideLoading, showLoading } from 'react-redux-loading-bar';
import axios from 'axios';
import moment from 'moment';
import messages from 'containers/Home/messages';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

function useSorting(notify) {
  const [slots, setSlots] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const transformResponse = availableDates => {
    const events = availableDates
      .flatMap(day => day.timeslots)
      .reduce((acc, value) => {
        const current = acc[value.start];
        if (current) {
          acc[value.start].resources.push(value.resource);
        } else {
          acc[value.start] = {
            start: moment(value.start).toDate(),
            end: moment(value.end).toDate(),
            resources: [value.resource],
          };
        }

        return acc;
      }, {});
    return Object.getOwnPropertyNames(events).map(key => events[key]);
  };

  const searchTimeSlots = async values => {
    dispatch(showLoading());
    setLoading(true);
    const {
      meetingTime,
      startTime,
      endTime,
      desiredDate,
      holidays,
      building,
    } = values;
    const valuesObj = {
      duration: meetingTime,
      time_start: startTime,
      time_end: endTime,
      date_start: desiredDate[0],
      date_end: desiredDate[1],
      holidays,
      building,
    };
    try {
      const response = await axios(
        `company/resource_calendar_event_lists/search?${new URLSearchParams(
          valuesObj,
        ).toString()}`,
      ).catch(e => e.response);
      const { data } = response;

      if (!data || (data && data?.error)) {
        notify.setError(
          messages.commonErrorMsg,
          data?.error?.message || messages.commonErrorMsg,
        );
        dispatch(hideLoading());
        setLoading(false);
      } else if (!isEmpty(data)) {
        if (!isEmpty(data?.available_dates)) {
          setSlots(transformResponse(data?.available_dates));
        } else {
          setSlots([]);
        }
        dispatch(hideLoading());
        setLoading(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return {
    slots,
    loading,
    searchTimeSlots,
  };
}

export default useSorting;
