import React, { useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';

export default function PopoverView({ children, ...props }) {
  const node = useRef(null);

  const handleMutationObserverCallback = () => {
    if (props.onChangeElement) props.onChangeElement();
  };

  useEffect(() => {
    const element = node.current;
    const observer = new MutationObserver(handleMutationObserverCallback);
    observer.observe(element, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={node} className="popover-view">
      {children}
    </div>
  );
}

PopoverView.propTypes = {
  onChangeElement: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
