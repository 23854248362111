import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from 'react-redux';
import axios from 'setupAxios';
import { setSamlCompanyEmailDomain } from 'utils/userUtils';
import messages from './messages';

export function useSamlSignIn({ notify }) {
  const dispatch = useDispatch();

  async function samlSignIn(values) {
    const { company_email_domain: companyEmailDomain } = values;
    dispatch(showLoading());

    const response = await axios
      .get(
        `/integrations/saml/sessions?company_email_domain=${companyEmailDomain}`,
      )
      .catch(e => e.response);

    if (response?.status >= 300) {
      notify.setError(messages.notifyError, response?.data?.error?.message);
      dispatch(hideLoading());
    } else {
      /* NEED TO VERIFY IF THIS IS REQUIRED OR NOT */
      // pushUserIdToPageViewGA();
      const {
        data: {
          data: { url },
        },
      } = response;

      setSamlCompanyEmailDomain(companyEmailDomain);
      window.location.href = `${url}`;
      dispatch(hideLoading());
    }
  }

  return {
    samlSignIn,
  };
}
