import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Heading, IconNew as Icon } from '@d-lighted/design-system';

import CalendarCard from 'components/CalendarCard';
import * as color from 'constants/colors';
import messages from './messages';
import {
  ReserveWrapper,
  ButtonContainer,
  ReserveButton,
} from './components/styled';

const Reserve = () => {
  const resourceTitle = 'A会議室';

  return (
    <>
      <ReserveWrapper>
        <CalendarCard>
          <Col xs display="flex" alignItems="center">
            <Heading
              fontSize="20px"
              color={color.blackTitle}
              fontWeight="600"
              lineHeight="24px"
            >
              {resourceTitle}
            </Heading>
            <Icon
              className="material-icons"
              ml="10px"
              color={color.brandBlue}
              fontSize="16px"
            >
              info
            </Icon>
          </Col>
        </CalendarCard>
        <ButtonContainer>
          <ReserveButton>
            <FormattedMessage {...messages.reserve} />
          </ReserveButton>
        </ButtonContainer>
      </ReserveWrapper>
    </>
  );
};

export default Reserve;
