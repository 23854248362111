import HomeScreen from './index';

const route = [
  {
    component: HomeScreen,
    path: '/',
    exact: true,
    type: 'private',
  },
];

export default route;
