import { defineMessages } from 'react-intl';

export default defineMessages({
  optionButton: {
    id: 'containers.productinfo.option.button',
    defaultMessage: 'お問い合わせ',
  },
  inactiveButton: {
    id: 'containers.productinfo.inactivebutton',
    defaultMessage: 'ご利用中',
  },
  inquiryButton: {
    id: 'containers.productinfo.inquiry',
    defaultMessage: 'お問い合わせ',
  },
  option: {
    id: 'containers.productinfo.option',
    defaultMessage: 'オプション',
  },
  comingSoon: {
    id: 'containers.productinfo.comingSoon',
    defaultMessage: '近日リリス予定',
  },
});
