import React from 'react';
import { PropTypes } from 'prop-types';

import PopoverView from './components/PopoverView';
import PopoverViewDimensionProvider from './components/PopoverViewDimensionProvider';
import { Overlay } from './components/styled';

function Popover({
  open,
  positionTargetKey,
  positionTargetValue,
  children,
  onClickOverlay,
}) {
  if (!open) return null;

  return (
    <div>
      <PopoverViewDimensionProvider
        positionTargetKey={positionTargetKey}
        positionTargetValue={positionTargetValue}
        render={tracker => {
          return (
            <PopoverView onChangeElement={tracker.changeContentHeight}>
              {children}
            </PopoverView>
          );
        }}
      />
      <Overlay onClick={() => onClickOverlay && onClickOverlay()} />
    </div>
  );
}

Popover.defaultProps = {
  onClickOverlay: null,
};

Popover.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  positionTargetKey: PropTypes.string.isRequired,
  positionTargetValue: PropTypes.string.isRequired,
  onClickOverlay: PropTypes.func,
};

export default Popover;
