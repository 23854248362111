import React from 'react';
import { PropTypes } from 'prop-types';
import Text from 'components/Texts/Text14';
import { LinkItem, ClickableItem } from './styled';

function Item({ onClick, link, text }) {
  let component = (
    <ClickableItem onClick={onClick}>
      <Text>{text}</Text>
    </ClickableItem>
  );
  if (link) {
    component = (
      <LinkItem to={link}>
        <Text>{text}</Text>
      </LinkItem>
    );
  }

  return component;
}

Item.defaultProps = {
  onClick: null,
  link: null,
};

Item.propTypes = {
  onClick: PropTypes.func,
  link: PropTypes.string,
  text: PropTypes.node.isRequired,
};

export default Item;
