import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 40px 0 60px;
  text-align: center;
`;

export const ChildrenWrapper = styled.div`
  margin: 0 auto;
  padding-top: 32px;
`;
