import { defineMessages } from 'react-intl';

export default defineMessages({
  empty: {
    id: 'resourceList.card.roomEmpty',
    defaultMessage: 'Vacant',
  },
  inUse: {
    id: 'resourceList.card.roomInUse',
    defaultMessage: 'In Use',
  },
  capacity: {
    id: 'resourceList.card.capacity',
    defaultMessage: 'Capacity',
  },
  floor: {
    id: 'resourceList.card.floor',
    defaultMessage: 'Floor',
  },
  people: {
    id: 'resourceList.card.people',
    defaultMessage: 'Person(s)',
  },
  entranceConfirmationTitle: {
    id: 'resourceList.confirmation.enter.title',
    defaultMessage: 'Enter the room?',
  },
  entranceConfirmationAffirmative: {
    id: 'resourceList.confirmation.enter.affirmative',
    defaultMessage: 'Enter Room',
  },
  confirmationNegative: {
    id: 'resourceList.confirmation.negative',
    defaultMessage: 'Cancel',
  },
  fetchingTitle: {
    id: 'resourceList.confirmation.fetching.title',
    defaultMessage: 'Finding Meeting information',
  },
  fetchingEnterTitle: {
    id: 'resourceList.confirmation.fetching.enter.title',
    defaultMessage: 'Entering Room',
  },
  fetchingExitTitle: {
    id: 'resourceList.confirmation.fetching.exit.title',
    defaultMessage: 'Leaving Room',
  },
  exitTitle: {
    id: 'resourceList.confirmation.exit.title',
    defaultMessage: 'Leave the room?',
  },
  exitAffirmative: {
    id: 'resourceList.confirmation.exit.affirmative',
    defaultMessage: 'Leave Room',
  },
  noneTitle: {
    id: 'resourceList.confirmation.notAllowed.title',
    defaultMessage: 'Cant enter room because next meeting will start soon',
  },
  instaMeetNameLabel: {
    id: 'resourceList.confirmation.instantMeeting.nameInput.label',
    defaultMessage: 'Please enter MeetingName',
  },
  instaMeetInputPlaceholder: {
    id: 'resourceList.confirmation.instantMeeting.nameInput.placeholder',
    defaultMessage: 'Meeting Name',
  },
  instaMeetTimeLabel: {
    id: 'resourceList.confirmation.instantMeeting.timeInput.label',
    defaultMessage: 'Please select UsageTime',
  },
  timeUnit: {
    id: 'resourceList.confirmation.instantMeeting.timeInput.unitMinute',
    defaultMessage: 'Minutes',
  },
  noAppointmentTitle: {
    id: 'resourceList.confirmation.noAppointment.title',
    defaultMessage:
      'There are no available appointments. Please make a reservation if you want to use this meeting room.',
  },
  sortingTitle: {
    id: 'resourceList.sorting.title',
    defaultMessage: 'Sorting Methods',
  },
  searchButton: {
    id: 'resourceList.sorting.button',
    defaultMessage: 'Search',
  },
  showAllRooms: {
    id: 'resourceList.sorting.option.allRooms',
    defaultMessage: 'Show all meeting rooms',
  },
  availableNow: {
    id: 'resourceList.sorting.option.availableNow',
    defaultMessage: 'Only display currently available',
  },
  specifyPeriod: {
    id: 'resourceList.sorting.option.specifyPeriod',
    defaultMessage: 'Specify a period to narrow down',
  },
  meetingTime: {
    id: 'resourceList.sorting.meetingTime.meetingTime',
    defaultMessage: 'Desired meeting time',
  },
  timeSlot: {
    id: 'resourceList.sorting.meetingTime.timeSlot',
    defaultMessage: 'Meeting time slot',
  },
  period: {
    id: 'resourceList.sorting.meetingTime.period',
    defaultMessage: 'Period of time',
  },
  holidays: {
    id: 'resourceList.sorting.meetingTime.holidays',
    defaultMessage: 'Include weekends and holidays',
  },
  thirtyMin: {
    id: 'resourceList.sorting.meetingTime.30min',
    defaultMessage: '30min',
  },
  sixtyMin: {
    id: 'resourceList.sorting.meetingTime.60min',
    defaultMessage: '60min',
  },
  ninetyMin: {
    id: 'resourceList.sorting.meetingTime.90min',
    defaultMessage: '90min',
  },
  other: {
    id: 'resourceList.sorting.meetingTime.other',
    defaultMessage: 'Other',
  },
  placeholder: {
    id: 'resourceList.sorting.meetingTime.minute.placeholder',
    defineMessages: 'Please select a duration for the meeting',
  },
  settingTitle: {
    id: 'resourceList.setting.title',
    defaultMessage: '設定',
  },
  buildingTitle: {
    id: 'buildingSelector.building',
    defaultMessage: 'Building',
  },
  officeTitle: {
    id: 'buildingSelector.office',
    defaultMessage: 'Office',
  },
  meetingroomGroup: {
    id: 'buildingSelector.meetingroomGroup',
    defaultMessage: 'Meeting room group',
  },
  statusTitle: {
    id: 'resourceList.filter.statusofUse',
    defaultMessage: 'Status of Use',
  },
  notSpecified: {
    id: 'bigCalendar.calendarHeader.dropdown.notSpecified',
    defaultMessage: '指定しない',
  },
  scanQRTitle: {
    id: 'resourceList.qr.title',
    defaultMessage: 'Scan QR Code',
  },
  scanQRSubtitle: {
    id: 'resourceList.qr.subtitle',
    defaultMessage: 'Scan the QR Code installed in the meeting room',
  },
  return: {
    id: 'qrDialog.button',
    defaultMessage: 'Return',
  },
  enterSuccess: {
    id: 'qrSuccessDialog.enter.title',
    defaultMessage: 'Entered the room',
  },
  exitSuccess: {
    id: 'qrSuccessDialog.exit.title',
    defaultMessage: 'Left the room',
  },
  error: {
    id: 'qrErrorDialog.title',
    defaultMessage: 'An error has occurred',
  },
  allRooms: {
    id: 'resourceList.searchLabel.text',
    defaultMessage: 'All MeetingRooms',
  },
  searchText: {
    id: 'resourceList.searchText.text',
    defaultMessage: 'Search',
  },
  qrGeneralError: {
    id: 'resourceList.qrErrorDialog.generalError',
    defaultMessage: 'An error has occured with the QR Scanner',
  },
  qrPermissionError: {
    id: 'resourceList.qrErrorDialog.permissionError',
    defaultMessage:
      'Please allow permission to use camera in your browser settings!',
  },
  incorrectQr: {
    id: 'resourceList.qrErrorDialog.incorrectQr',
    defaultMessage: 'Illegal QR code',
  },
  reserve: {
    id: 'reserve.button',
    defaultMessage: 'Reserve',
  },
  emptySearchResult: {
    id: 'resourceList.emptySearchResults.msg',
    defaultMessage:
      'There are no time slots available for the searched parameters.',
  },
  noResourcesNoBuilding: {
    id: 'resourceList.results.noResourcesNoBuilding',
    defaultMessage:
      'Please select building from the gear symbol in the upper left corner.',
  },
  noResourcesInSelectedBuilding: {
    id: 'resourceList.results.noResourcesInSelectedBuilding',
    defaultMessage:
      'There are no meeting rooms available in this building. Please select building from the gear symbol in the upper left corner.',
  },
  noResourcesNoOffice: {
    id: 'resourceList.results.noResourcesNoOffice',
    defaultMessage:
      'Please select building from the gear symbol in the upper left corner.',
  },
  noResourcesInSelectedOffice: {
    id: 'resourceList.results.noResourcesInSelectedOffice',
    defaultMessage:
      'There are no meeting rooms available in this building. Please select building from the gear symbol in the upper left corner.',
  },
  noResourcesNoMeetingroomGroup: {
    id: 'resourceList.results.noResourcesNoMeetingroomGroup',
    defaultMessage:
      'Please select building from the gear symbol in the upper left corner.',
  },
  noResourcesInSelectedMeetingroomGroup: {
    id: 'resourceList.results.noResourcesInSelectedMeetingroomGroup',
    defaultMessage:
      'There are no meeting rooms available in this building. Please select building from the gear symbol in the upper left corner.',
  },
  logoUploadTitle: {
    id: 'logoUploadModal.title',
    defaultMessage: 'Logo setting for signage mode display',
  },
  logoUploadSubtitle: {
    id: 'logoUploadModal.subtitle',
    defaultMessage: 'edit the image size to a 1:4 ratio before uploading',
  },
  logoUploadError: {
    id: 'logoUploadModal.error',
    defaultMessage: 'Error: Unable to upload',
  },
  logoUploadUploading: {
    id: 'logoUploadModal.uploading',
    defaultMessage: 'Uploading...',
  },
  logoUploadDragAndDrop: {
    id: 'logoUploadModal.dragAndDrop',
    defaultMessage: 'Drop image here',
  },
  logoUploadAspectRatio: {
    id: 'logoUploadModal.logoSpec',
    defaultMessage:
      'Aspect Ratio: 1:4 {br} File Type: jpg, jpeg, png {br} File size limit: 2MB',
  },
  logoUploadDragOption: {
    id: 'logoUploadModal.dragOption',
    defaultMessage: 'Drag an image here or {br} Click to Upload your file',
  },
  uploadSuccessSetting: {
    id: 'logoUploadModal.success.title',
    defaultMessage: 'Logo setting for full screen display',
  },
  uploadSuccessCompletedTop: {
    id: 'logoUploadModal.success.completedTop',
    defaultMessage: 'Image upload completed',
  },
  uploadSuccessCompletedBottom: {
    id: 'logoUploadModal.success.completedBottom',
    defaultMessage: 'Please switch to full screen and check',
  },
  uploadSuccessCloseButton: {
    id: 'logoUploadModal.success.closeButton',
    defaultMessage: 'Close',
  },
});
