import React, { useState, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Row } from '@d-lighted/design-system';
import EmptyThumbnail from 'images/web_fallback-image.svg';
import InfoIconSVG from 'images/ic_info.svg';

import {
  CustomResourceHeader,
  ResourceHeaderText,
  ResourceCapacityText,
  EyeIconWrapper,
  ResourceThumbnail,
  ResourceWrapper,
  ResourcesSubInfo,
  ResourcesInfo,
  DetailsContainer,
  RoomDetailsTitle,
  RoomDetailsImage,
  RoomDetailsInfo,
  CloseText,
  InfoIcon,
  ScrollWrapper,
} from './styled';

const ResourceHeader = ({
  resource,
  isRoomBusy,
  editMode,
  handleResourceVisibleChange,
  fullScreenState,
  additionalInformation,
  thumbnail,
}) => {
  const { resourceTitle, capacity, resourceId, visible } = resource;
  const header = useRef(null);

  const [clickedDetails, setClickedDetails] = useState({
    clickedResource: null,
    clickedDetailsVisible: false,
    clickedDetailsPosition: { top: 0, left: 0 },
  });

  const {
    clickedResource,
    clickedDetailsVisible,
    clickedDetailsPosition,
  } = clickedDetails;

  const showDetails = event => {
    if (event.currentTarget) {
      const {
        scrollY,
        scrollX,
        visualViewport: { width: viewportWidth },
      } = window;
      const {
        top,
        left,
        width,
        height,
      } = event.currentTarget.getBoundingClientRect();

      const leftCalculation = left + scrollX + width;

      const detailsPosition = {
        top: top + scrollY + height - 165,
        left:
          leftCalculation + 400 >= viewportWidth
            ? viewportWidth - 450
            : leftCalculation,
      };

      setClickedDetails({
        ...clickedDetails,
        clickedResource: resource,
        clickedDetailsVisible: true,
        clickedDetailsPosition: detailsPosition,
      });
    }
  };

  const hideDetails = () => {
    setClickedDetails({ ...clickedDetails, clickedDetailsVisible: false });
  };

  const handleClickOutside = event => {
    const targetElement = event?.target;
    const headerElement = header.current;

    if (headerElement != null && !headerElement.contains(targetElement))
      hideDetails();
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editModeComponent = () => {
    return (
      <>
        <Row>
          <ResourceHeaderText
            editMode={editMode}
            fullScreenState={fullScreenState}
          >
            {resourceTitle}
          </ResourceHeaderText>
          {capacity > 0 && (
            <ResourceCapacityText
              editMode={editMode}
              fullScreenState={fullScreenState}
            >
              ({capacity}人)
            </ResourceCapacityText>
          )}
        </Row>
        <EyeIconWrapper visible={visible} edit={editMode}>
          <i
            className={visible ? 'fa fa-eye' : 'fa fa-eye-slash'}
            aria-hidden="true"
          />
        </EyeIconWrapper>
      </>
    );
  };

  const normalModeComponent = () => {
    return additionalInformation ? (
      <ResourceWrapper>
        {thumbnail && (
          <ResourceThumbnail
            src={resource?.thumbnail?.url || EmptyThumbnail}
            alt="room"
          />
        )}
        <ResourcesInfo>
          <ResourceHeaderText
            fullScreenState={fullScreenState}
            additionalInformation={additionalInformation}
          >
            {resourceTitle}
          </ResourceHeaderText>
          <ResourcesSubInfo>
            <ResourceCapacityText
              fullScreenState={fullScreenState}
              additionalInformation={additionalInformation}
            >
              {capacity > 0 && `(${capacity}人)`}
            </ResourceCapacityText>
            {!fullScreenState && <InfoIcon src={InfoIconSVG} alt="info" />}
          </ResourcesSubInfo>
        </ResourcesInfo>
      </ResourceWrapper>
    ) : (
      <>
        <ResourceHeaderText
          fullScreenState={fullScreenState}
          additionalInformation={additionalInformation}
        >
          {resourceTitle}
        </ResourceHeaderText>
        {capacity > 0 && (
          <ResourceCapacityText
            fullScreenState={fullScreenState}
            additionalInformation={additionalInformation}
          >
            ({capacity}人)
          </ResourceCapacityText>
        )}
      </>
    );
  };

  return (
    <div ref={header}>
      <CustomResourceHeader
        busyResource={isRoomBusy(resourceId)}
        visible={visible}
        editMode={editMode}
        onClick={e => {
          if (editMode) handleResourceVisibleChange(resourceId);
          if (additionalInformation) showDetails(e);
        }}
      >
        {editMode ? editModeComponent() : normalModeComponent()}
      </CustomResourceHeader>
      <DetailsContainer
        isVisible={clickedDetailsVisible}
        top={`${clickedDetailsPosition.top}px`}
        left={`${clickedDetailsPosition.left}px`}
      >
        <RoomDetailsTitle>{clickedResource?.resourceTitle}</RoomDetailsTitle>
        {thumbnail && (
          <RoomDetailsImage
            src={clickedResource?.thumbnail?.url || EmptyThumbnail}
          />
        )}
        <ScrollWrapper>
          {(clickedResource?.additionalInformation || []).map(infoItem => {
            return (
              <RoomDetailsInfo key={infoItem.uid}>
                {`${infoItem.labelName}: ${infoItem.inputValue}`}
              </RoomDetailsInfo>
            );
          })}
        </ScrollWrapper>
        <CloseText onClick={() => hideDetails()}> 閉じる</CloseText>
      </DetailsContainer>
    </div>
  );
};

ResourceHeader.defaultProps = {
  editMode: false,
  fullScreenState: false,
  additionalInformation: false,
  thumbnail: false,
};

ResourceHeader.propTypes = {
  resource: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isRoomBusy: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  fullScreenState: PropTypes.bool,
  handleResourceVisibleChange: PropTypes.func.isRequired,
  additionalInformation: PropTypes.bool,
  thumbnail: PropTypes.bool,
};

export default ResourceHeader;
