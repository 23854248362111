import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATUS = {
  locale: 'ja',
};

const changeLocale = createAction('change_locale');

const localeReducer = createReducer(INITIAL_STATUS, builder => {
  builder.addCase(changeLocale, (state, action) => {
    state.locale = action.payload;
  });
});

export default localeReducer;
