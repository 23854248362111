import axios from 'axios';
import {
  isReactNativeWebView,
  isRNWebViewUpdateCredentials,
  signOut,
} from 'utils/webViewUtils';

function saveAuthHeader(headers) {
  if (headers['access-token']) {
    const authHeaders = {
      'access-token': headers['access-token'],
      client: headers.client,
      expiry: headers.expiry,
      uid: headers.uid,
    };
    localStorage.setItem('auth_headers', JSON.stringify(authHeaders));
    if (headers['refresh-token'])
      localStorage.setItem('refresh_token', headers['refresh-token']);

    isRNWebViewUpdateCredentials();
    return true;
  }
  return false;
}

export function setupAxios() {
  axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  axios.interceptors.request.use(request => {
    const authHeaders = JSON.parse(localStorage.getItem('auth_headers'));
    if (authHeaders != null) {
      request.headers['access-token'] = authHeaders['access-token'];
      request.headers.client = authHeaders.client;
      request.headers.expiry = authHeaders.expiry;
      request.headers.uid = authHeaders.uid;
    }

    return request;
  });

  axios.interceptors.response.use(
    response => {
      saveAuthHeader(response.headers);
      return response;
    },
    async error => {
      const originalRequest = error.config;
      const refreshToken = localStorage.getItem('refresh_token');

      if (
        error.response?.status === 401 &&
        refreshToken &&
        !originalRequest.retry &&
        !originalRequest.noIntercept
      ) {
        originalRequest.retry = true;
        const authHeaders = JSON.parse(localStorage.getItem('auth_headers'));
        const options = {
          method: 'get',
          url: '/auth/refresh_token',
          headers: {
            uid: authHeaders.uid,
            client: authHeaders.client,
            'refresh-token': refreshToken,
          },
          noIntercept: true,
        };

        return axios(options).then(response => {
          saveAuthHeader(response.headers);
          if (response.status > 299) {
            if (isReactNativeWebView()) {
              signOut();
            } else {
              window.location.pathname = '/sign_in';
              window.localStorage.clear();
            }
          }
          return axios(response);
        });
      }
      return error.response;
    },
  );
}

export default axios;
