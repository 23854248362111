import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const ProductSurtitle = styled.p`
  color: #68878d;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: -6px;
  text-align: left;
`;

const ProductTitle = styled.p`
  color: #484e62;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0.14px;
  line-height: 1.6;
  text-align: left;
`;

const TopContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const TopTextcontainer = styled.div`
  display: block;
`;

const ProductIcon = styled.img`
  height: 53.9px;
  margin-right: 20.6px;
  width: 53.9px;
`;

function ProductTopSection({ productIcon, productSurtitle, productTitle }) {
  return (
    <TopContainer>
      <ProductIcon src={productIcon} />
      <TopTextcontainer>
        <ProductSurtitle>
          <FormattedMessage {...productSurtitle} />
        </ProductSurtitle>
        <ProductTitle>
          <FormattedMessage {...productTitle} />
        </ProductTitle>
      </TopTextcontainer>
    </TopContainer>
  );
}

ProductTopSection.defaultProps = {
  productIcon: undefined,
  productSurtitle: undefined,
  productTitle: undefined,
};

ProductTopSection.propTypes = {
  productIcon: PropTypes.string,
  productSurtitle: PropTypes.oneOfType([PropTypes.object]),
  productTitle: PropTypes.oneOfType([PropTypes.object]),
};

export default ProductTopSection;
