import React from 'react';
import styled from 'styled-components';
import { getCurrentUser } from 'utils/userUtils';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Product from './components/Product';
import TopProduct from './components/TopProduct';
import messages from './messages';

const Header = styled.div`
  color: #484e62;
  font-size: 26px;
  height: 37px;
  padding-top: 20px;
  text-align: center;
`;

const Arrow = styled.span`
  border-radius: 1px;
  border-top: 2px solid #00bbb5;
  display: block;
  height: 30px;
  width: 20px;
  margin-top: 6px;
  padding-top: 10px;
  position: relative;
  top: 20px;

  &:before {
    border-right: 2px solid #00bbb5;
    border-top: 2px solid #00bbb5;
    border-radius: 1px;
    content: '';
    display: block;
    top: -8px;
    left: 0px;
    width: 12px;
    height: 12px;
    position: absolute;
    transform: rotate(-135deg);
  }
`;

const BackButton = styled(Link)`
  color: #00bbb5;
  float: left;
  font-size: 14px;
  margin-left: 38.5px;
  text-decoration: none;
`;

const MainContainer = styled.section`
  background-color: #f7f9f9;
  display: flex;
  flex-direction: column;
  padding: 44px 13.42% 142px 13.42%;
`;

const ProductListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  &:after {
    content: '';
    padding: 32px;
    width: 442px;
  }
`;

const ProductListTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 16px;
  text-align: center;
`;

const getProductToSuggest = currentUser => {
  const inactiveProduct = currentUser.company.products.find(
    product => !product.active,
  );
  return inactiveProduct ? inactiveProduct.productType : undefined;
};

function InfoProduct() {
  const currentUser = getCurrentUser();
  const productToSuggest = getProductToSuggest(currentUser);

  return (
    <div>
      <Header>
        <BackButton to="/">
          <Arrow />
          <FormattedMessage {...messages.BackButton} />
        </BackButton>
        <FormattedMessage {...messages.ProductInfoTitle} />
      </Header>
      <MainContainer>
        {productToSuggest !== undefined && (
          <TopProduct product={productToSuggest} />
        )}
        <ProductListTitle>
          <FormattedMessage {...messages.ProductListTitle} />
        </ProductListTitle>
        <ProductListContainer>
          <Product
            product="reception"
            isActive={currentUser.company.products[0].active}
          />
          <Product
            product="meetingroom"
            isActive={currentUser.company.products[1].active}
          />
          <Product
            product="scheduling"
            isActive={currentUser.company.products[2].active}
          />
        </ProductListContainer>
      </MainContainer>
    </div>
  );
}

export default InfoProduct;
