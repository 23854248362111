import { PropTypes } from 'prop-types';
import Text from 'components/Texts/Text';

const Text14 = Text.withComponent('p');

Text14.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

Text14.defaultProps = {
  fontSize: 14,
  color: 'gray.body',
};

export default Text14;
