import React from 'react';
import { PropTypes } from 'prop-types';
import { MainButton, Icon } from './styled';

function Button({ onClick, children }) {
  return (
    <MainButton id="dropdownBtn" className="dropdown-button" onClick={onClick}>
      {children}
      <Icon name="angle-down" style={{ color: 'white' }} />
    </MainButton>
  );
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Button;
