export const primaryGreen = '#00bbb5';
export const primaryLightGreen = '#00b1ce';
export const primaryDarkGreen = '#4dafcb';
export const deepGreen = '#008581';
export const paleGreen = '#d3f1ea';
export const gradientGreen = '#00E6DE';
export const errorRed = '#e84c3d';
export const lightRed = 'rgba(232, 76, 61, 0.1)';
export const lighterRed = '#FFEAEF';
export const darkRed = '#F23F52';
export const linkBlue = '#0000ee';
export const darkBlue = '#0e1530';
export const brandBlue = '#15B9CB';
export const deepBlue = '#10275A';
export const blueShade = '#00AED4';
export const lightGreenShadow = '#2ce1dc';
export const grayBorder = '#e5e5e5';
export const grayHeading = '#484e62';
export const grayThick = '#2d3341';
export const grayThin0 = '#6B7280';
export const grayThin = '#9bb0b5';
export const grayThin2 = '#67878d';
export const grayThin3 = '#73768c';
export const hoverGray = '#f7f9f9';
export const priceGray = '#737373';
export const lightGray = '#f6f8fa';
export const backgroundGray = 'rgb(55, 58, 71)';
export const grayLabel = '#6b7280';
export const grayShade = '#e7e9ea';
export const grayShade2 = '#F6F6F6';
export const grayShade3 = '#D2D4D8';
export const grayInput = '#E9EAF1';
export const grayShade4 = '#66657C';
export const textBlack = '#484f63';
export const blackShade = '#25262a';
export const blackTitle = '#1F2937';
export const blackShade2 = '#112d48';
export const black = '#000000';
export const white = '#ffffff';
export const doneEventGray = '#c5c9db';
export const busyEvent = '#8b0000';
export const busyBGGray = '#eeeff4';
export const incomingEvent = '#00b1ce';
export const tagActiveBorder = '#e9eff4';
export const tagInactiveBorder = '#d3d9e5';
export const darkCyan = '#314143';
export const currentTimeIndicator = '#0b2fdb';
export const todayIndicator = '#cceff5';
export const transparentGray = 'rgba(159, 165, 195, 0.2)';
export const transparentBlue = 'rgba(21, 185, 203, 0.24)';
export const transparentRed = 'rgba(242, 63, 82, 0.24)';
export const darkText = '#374151';
export const inUse = '#B3051F';
export const available = '#009D98';
export const darkGrayText = '#264153';
