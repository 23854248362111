import styled from 'styled-components';
import {
  space,
  width,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  fontSize,
  color,
  border,
  borderTop,
  borderLeft,
  borderColor,
  borderRadius,
  position,
  textAlign,
  background,
} from 'styled-system';
import { white, blueShade } from 'constants/colors';

import { Label } from 'containers/SignIn/components/styled';
import { Input } from '@d-lighted/design-system';

export const Box = styled.div`
  ${background}
  ${position}
  ${space}
  ${width}
  ${border}
  ${borderTop}
  ${borderLeft}
  ${borderColor}
  ${minWidth}
  ${maxWidth}
  ${height}
  ${minHeight}
  ${maxHeight}
  ${fontSize}
  ${color}
  ${borderRadius}
  ${textAlign}
`;

export const SamlWrapper = styled.div`
  min-height: 100vh;
  background: #373a47;
`;

export const Container = styled.div`
  width: 430px;
  margin: 81px auto 0 auto;
  padding: 20px 60px 30px;
  background-color: ${white};
  border-radius: 6px;
  box-shadow: 0 10px 26px 0 rgba(37, 38, 42, 0.1);
  opacity: 1;
  @media (max-width: 768px) {
    width: calc(100% - 120px);
  }
`;

export const CheckedCircle = styled.div`
  position: relative;
  margin: 0 auto 25px;
  width: 50px;
  height: 50px;
  border: solid 4px ${blueShade};
  border-radius: 50%;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 14px;
    left: 12px;
    width: 22px;
    height: 12px;
    border-left: 3px solid ${blueShade};
    border-bottom: 3px solid ${blueShade};
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
`;

export const FieldWrapper = styled.div`
  position: relative;
`;

export const InTextLabel = styled(Label)`
  position: absolute;
  top: 12px;
  left: 15px;
  font-size: 16px;
`;

export const StyledText = styled.div`
  a {
    color: ${blueShade};
    text-decoration: none;
  }
`;

export const StyledInput = styled(Input)`
  width: ${p => (p.width ? p.width : '100%')};
  ${space}
`;
