import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSettingResourceApp } from 'containers/Home/actions';
import humps from 'lodash-humps';

export function usePutSignageModeLogo() {
  const [fileUploadScreen, setFileUploadScreen] = useState(null);
  const [uploadErrorScreen, setUploadErrorScreen] = useState(null);
  const [uploadSuccessScreen, setUploadSuccessScreen] = useState(null);
  const dispatch = useDispatch();

  const putSignageModeLogo = async payload => {
    try {
      setFileUploadScreen(false);
      const { data } = await axios.put('/company/signage_mode_logo', {
        signage_mode_logo: payload,
      });
      setUploadSuccessScreen(true);
      const camelizedData = humps(data?.setting_resource_app);
      dispatch(setSettingResourceApp(camelizedData));
    } catch (error) {
      // eslint-disable-next-line no-console
      setUploadErrorScreen(true);
      setFileUploadScreen(true);
      setUploadSuccessScreen(false);
    }
  };
  return {
    fileUploadScreen,
    setFileUploadScreen,
    uploadErrorScreen,
    setUploadErrorScreen,
    uploadSuccessScreen,
    setUploadSuccessScreen,
    putSignageModeLogo,
  };
}
