import React from 'react';
import { FormattedMessage } from 'react-intl';

import Header from 'components/Header';
import ReserveContainer from 'containers/Reserve';
import messages from './messages';

function ReserveScreen(props) {
  return (
    <>
      <Header title={<FormattedMessage {...messages.title} />} />
      <ReserveContainer {...props} />
    </>
  );
}

export default ReserveScreen;
